import React, { useState } from 'react';
import { Link, Navigate,useParams } from 'react-router-dom';
import {makePostRequest} from '../../../services/httpHelper'
import ErrorHandler from '../../../services/ErrorHandler';
import { LoadingState,ErrorState } from '../../../components/ui/UiState';
import Content from '../../../components/Content'


const AddLibFund = ()=>{
   
   const [fund,setFund] = useState()
   const [description,setDescription] = useState();
   const [color,setColor] = useState(null)

   const [isSubmitting,setIsSubmitting] = useState(false)
   const [serverError,setServerError] = useState(null)
   const [validationError,setValidationError] = useState(null)
   const [isSuccess,setIsSuccess] = useState(false)


   const user = JSON.parse(localStorage.getItem('user'))
   const handleChange =(e)=>{
    let name = e.target.name;
    switch(name){
        case 'fund':
            setFund(e.target.value);
            break;
      
        case 'description':
            setDescription(e.target.value);
            break;

            case 'color':
                setColor(e.target.value);
                break;
            default:
                alert('Invalid Input');
                break;
    }
   }



    const handleSubmit = async(e)=>{
        e.preventDefault();
        setServerError(null)
        setValidationError(null)
        setIsSuccess(false);
       
        if(fund === undefined){
            setValidationError('Enter Fund Name')
            return;
        }
        if(description === undefined){
            setValidationError('Enter Fund Description')
            return;
        }

        if(description === null){
            setValidationError('Enter Color')
            return;
        }

        const data ={
         
            fund:fund,
            description:description,
            created_by:user.id,
            color:color
        }
        setIsSubmitting(true)
        setIsSuccess(false)
        const post = await makePostRequest('library-fund',data)
        if (post && post.errorfund) {
            setIsSubmitting(false);
            const errorMessage = ErrorHandler(res)
            setServerErrorMessage(errorMessage)

        } else {
              
            setFund(null);
            setIsSubmitting(false);
            setIsSuccess(true);


        }

    }
    return (
        <div className="container">
             <div className="card">
        <form onSubmit={handleSubmit} className="form-group">
            <h3 className="text-center" >Create Library Fund</h3>
            {isSubmitting && <LoadingState/>}
            {isSuccess && <Content type="success" message="Fund Added!"/>}
            {serverError !== null && <Content type="error" message={serverError}/>}


            {serverError !== null && !isSubmitting ? <ErrorState/>  : null}
         {validationError !== null && !isSubmitting ? <p className='text text-danger'>{validationError}</p> : null}
        
        <div className="row jumbotron">
        <div className="col-sm-6 form-group">
                <span >Fund</span>
                <input fund="text" className="form-control" name="fund"  placeholder="Enter  Fund" onChange={handleChange} required/>

            </div>
            <div className="col-sm-6 form-group">
                <span >Description </span>
                <input fund="text" className="form-control" name="description" placeholder="Enter Account Description" onChange={handleChange}/>

            </div>

            <div className="col-sm-6 form-group">
                <span >Color </span>
                <input fund="text" className="form-control" name="color" placeholder="Color" onChange={handleChange}/>

            </div>
         

            
                            

            <div className="col-sm-12 form-group mb-0">
               <button className="btn btn-primary float-right">Add Fund</button>
            </div>
            
        </div>
        </form>
        </div>
    </div>
    )

}
export default AddLibFund;