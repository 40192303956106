import React, { useState } from 'react';
import { Link, Navigate,useParams } from 'react-router-dom';
import {makePostRequest} from '../../services/httpHelper'
import ErrorHandler from '../../services/ErrorHandler'
import { CircularProgress } from '@mui/material';


const skill_categories =[
    'hard skills',
    'soft skills',
    'technical skills',
    'music skills',
    'management skills',
    'sales skills',
    'design skills',
    'accounting and bookkeeping skills',
    'nursing and health care skills',
    'teaching skills',
    'marketing skills',
    'software development skills',
    'business analytic skills'
]

const CreateSkill = ({pageTitle=null})=>{
   
   const [skill,setSkill] = useState()
   const [categories,setCategories] = useState(skill_categories)
   const [category,setCategory] = useState()
   const [isSubmitting,setIsSubmitting] = useState(false)
   const [serverError,setServerError] = useState(null)
   const [validationError,setValidationError] = useState(null)
   const [isSuccess,setIsSuccess] = useState(false)


   const handleChange =(e)=>{
    let name = e.target.name;
    switch(name){
        case 'skill':
            setSkill(e.target.value);
            break;
       
        case 'category':
            setCategory(e.target.value);
            break;
            default:
                alert('Invalid Input');
                break;
    }
   }



    const handleSubmit = async(e)=>{
        e.preventDefault();
        setServerError(null)
        setValidationError(null)
        
       try{
        if(skill === undefined){
            setValidationError('Enter Skill ')
            return;
        }
        if(category === undefined){
            setValidationError('Enter category ')
            return;
        }        

        const user = JSON.parse(localStorage.getItem('user'))

        const data ={
            skill:skill,
            category:category,
            user_id:user.id,           
           
        }
        setIsSubmitting(true)
        const post = await makePostRequest('skill',data)
        if (post && post.errorType) {
            setIsSubmitting(false);
            const errorMessage = ErrorHandler(post)
            setServerError(errorMessage)

        } else {
              
            setIsSubmitting(false);
            setIsSuccess(true);


        }
       }catch(error){
        setIsSubmitting(false);
        console.log(error)
       }

    }
    return (
        <div className="container">           
             <div className="">
        <form onSubmit={handleSubmit} className="form-group">
            {pageTitle===null && <h3 className="text-center" >Add Skill</h3>}
            {isSubmitting && <p className='text text-success'>adding..</p>}
            {isSuccess && <p className='text text-success'>{skill} successfully added</p>}
            {serverError !== null && !isSubmitting ? <p className='text text-danger'>{serverError}</p> : null}
         {validationError !== null && !isSubmitting ? <p className='text text-danger'>{validationError}</p> : null}
        
        <div className="row jumbotron">

        <div className="col-sm-6 form-group">
                <span>Skill Category</span>
                <select className="form-control browser-default custom-select" name="category" value={category} onChange={handleChange}>
                <option value="">Select Category</option>
                {
                    categories.map((cat)=>{
                        return <option key={cat} value={cat}>{cat.toUpperCase()}</option>

                    })
                }
               
                

            </select>

            </div>
        
            <div className="col-sm-6 form-group">
                <span >Skill</span>
                <input type="text" className="form-control" name="skill" value={skill} placeholder="Enter Skill" onChange={handleChange}/>

            </div>

                                       

            <div className="col-sm-12 form-group mb-0">
               <button className="btn btn-primary float-right">{isSubmitting ? <CircularProgress/> : "Add"}</button>
            </div>
            
        </div>
        </form>
        </div>
    </div>
    )

}
export default CreateSkill;