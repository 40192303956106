import {
    Routes,
    Route,
    Link,
    Navigate,
    Outlet,
  } from 'react-router-dom';
  import jwt_decode from "jwt-decode";


  
  const ProtectedRoute = ({isAllowed,redirectPath = '/login',children }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if(!user) {
      return <Navigate to={redirectPath} replace />;
    } 
    const sessionExpired = tokenIsExpired(user.token);   

    if (isAllowed==false || sessionExpired) {
      return <Navigate to={redirectPath} replace />;
    }else{
      return children ? children : <Outlet />;

    }
  
  
  };

  const tokenIsExpired =(token)=>{
    let expired = false;
    const now  = Date.now();
    var decoded = jwt_decode(token);  
    if(decoded.exp*1000 < now){
      expired === true
    }
    return expired;

  }

 export default ProtectedRoute;
  