import React, { useState, useEffect} from 'react';
import { Link, Navigate,useParams } from 'react-router-dom';
import {makeGetRequest,makePostRequest} from '../../../services/httpHelper'
import ErrorHandler from '../../../services/ErrorHandler';
import { CircularProgress } from '@mui/material';
import { toTitleCase,removeFromArray } from '../../../utils/myFunctions';
import Content from '../../../components/Content'
import {ModalService,ModalRoot,ModalTemplate} from '../../../components/Modal'

let selected =[];
const ImportableFunds = ()=>{
    const [funds, setFunds] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false);
    const [isImported, setIsImported] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isImportingAll, setIsImportingAll] = useState(false);
    const [serverError, setServerError] = useState(null);   
    const [validationError,setValidationError] = useState(null)


    const user = JSON.parse(localStorage.getItem('user'));
    const church = JSON.parse(localStorage.getItem('church')); 
    const church_id = church.church.id;


    const handleCheckboxChange=(e)=>{
        
        if(e.target.checked===true){
          
            //add fund when checked
            selected.push(e.target.value)
        }else{
            //remove fund on uncheck
           
            removeFromArray(selected,e.target.value) 
        }
        console.log(selected)       

    }

    const importFunds = async(e)=>{
        e.preventDefault();
        setServerError(null)
        setValidationError(null)
        setIsSubmitting(true)
        
        if(!selected.length){
            setIsSubmitting(false)
            setValidationError('No Funds Selected')
            return;
        }
              

        const data ={
            funds:selected,
            church_id:user.church.id,
            user_id:user.id,           
           
        }
        setIsSubmitting(true)
        const post = await makePostRequest('import-funds-from-library',data)
        if (post && post.errorType) {
            setIsSubmitting(false);
            const errorMessage = ErrorHandler(post)
            setServerError(errorMessage)

        } else {
              
            setServerError(null);
            setIsSubmitting(false);
            setIsImported(true);


        }

    }

    const importAllFunds = async(e)=>{
        e.preventDefault();
        setServerError(null)
        setValidationError(null)
        setIsImportingAll(true)
        
        if(!selected.length){
            setIsImportingAll(false)
            setValidationError('No Funds Selected')
            return;
        }
              

        const data ={
            funds:selected,
            church_id:user.church.id,
            user_id:user.id,           
           
        }
        setIsImportingAll(true)
        const req = await makeGetRequest('import-all-funds-from-library')
        if (req && req.errorType) {
            setIsImportingAll(false);
            const errorMessage = ErrorHandler(req)
            setServerError(errorMessage)

        } else {
              
            setServerError(null);
            setIsImportingAll(false);
            setIsImported(true);


        }

    }

    async function getFunds() {
        setIsLoading(true)
        // You can await here
        const res = await makeGetRequest('library-funds');
        if (ResizeObserver && ResizeObserver.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setServerError(errorMessage)

        } else {
            setFunds(res.data)                  
            setIsLoading(false);
            setIsSuccess(true);
            console.log('funds',funds)
        }       

    }

    
    useEffect(()=>{
        getFunds();

    },[])

    

    return(
        <div className='container'>
            {validationError && <Content type="error" message={validationError}/>}
            {serverError !== null && <p>Something wrong happened: <span className="text text-danger">{serverError}</span></p>}
             {isLoading && <CircularProgress color="primary"/>}
             {isImported && <Navigate to="/church-funds"/>}
               {
                            isSuccess ?
                                <div className="card">
                                    
                                            <h3 className="text-center"> {toTitleCase(church.church_name)} Funds</h3>
                                        <div className="row">
                                            <div className="col-md-4">
                                            <button type="button" className="btn btn-info" onClick={importFunds}>{isSubmitting ? <CircularProgress/>: "Import"} </button>

                                                </div>

                                                <div className="col-md-4">
                                            <button type="button" className="btn btn-info" onClick={importAllFunds}>{isImportingAll ? <CircularProgress/>: "Import All"} </button>

                                                </div>
                                            </div>
                                            {
                                                funds.length>0 ?
                                                <table className="table table-responsive table-striped">
                                                <thead>
                                                    <tr style={{fontSize:"15px"}}>
                                                        
                                                        <th>Fund</th>
                                                        <th>Description</th>
                                                        <th>Select To Import</th>
                                                        
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        
                                                       funds.map((fund)=>{
                                                                                                                        
                                                        return <tr key={fund.id} style={{fontSize:"15px"}}>
                                                            <td>{toTitleCase(fund.fund)}</td>
                                                            <td>{toTitleCase(fund.description)}</td>
                                                            <td><input type='checkbox' name="fund" value={fund.fund} onChange={handleCheckboxChange}/> </td>


                                                        </tr>

                                                    })
                                                    }

                                                    </tbody>
                                                   
                                                
                                            </table>
                                            :
                                            <div>

                                            <p>No Funds to Import <span><Link to="/create-fund" className="btn btn-info">Add New Fund</Link></span></p>
                                                    </div>
                                            }
                                           
                                            

                                      

                                </div>
                                :
                                <div>
                                    {serverError && <p className="text text-danger">{serverError}</p>}



                                </div>
                        }

        </div>
    )
}
export default ImportableFunds;