import React,{useState,useEffect} from 'react';
import { FaArrowAltCircleRight, FaUser } from 'react-icons/fa';

const DashboardCard =({title,subtitle,value, icon:Icon})=>{
    return(
        <div className='dashboard-card'>
            <div className='row'>
                    <div className='col-md-6'>
                    <p className="dashb-card-title-text"> {title} </p>
                    <p className='dashb-subtitle'>{subtitle}</p>
                    <p className='dashb-value'>{value}</p>
                      
                    </div>
                    <div className='col-md-3'>
                      
                      </div>
                      <div className='col-md-3'>
                     {Icon && <Icon className='dashb-icon'/>} 
                        <FaArrowAltCircleRight className='dashb-arrow'/>
                      
                      </div>
                  </div>

        </div>
    )

}
export default DashboardCard;