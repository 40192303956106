import React, { useState, useEffect} from 'react';
import { Link, Navigate,useParams } from 'react-router-dom';
import {makePostRequest,makeGetRequest, makePutRequest} from '../../../services/httpHelper'
import ErrorHandler from '../../../services/ErrorHandler';
import { SuccessAlert } from '../../../components/ui/UiState';
import { CircularProgress } from '@mui/material';
import { toTitleCase } from '../../../utils/myFunctions';
import HelpIcon from '@mui/icons-material/Help';
import ReactTooltip from 'react-tooltip';


const EditAccount = ({pageTitle=null,account})=>{
    const asset_subtype=['current asset','fixed asset'];
    const liability_subtype=['current liability','long term liability'];
    const [accounts,setAccounts] = useState()
    const [filteredAccounts,setFilteredAccounts] = useState(null)
   const [account_type_id,setAccountType] = useState(account.account_type_id)
   const [account_sub_type,setAccountSubType] = useState(account.account_sub_type)
   const [account_name,setAccountName] = useState(account.account_name)
   const [account_number,setAccountNumber] = useState()
   const [description,setDescription] = useState()
   const [account_activity,setAccountActivity] = useState(account.account_activity)
   const [account_group_id,setAccountGroupId] = useState(account.account_group_id)
   const [fund_id,setFundId] = useState()
   const [parent_id,setParentId] = useState(account.parent_id)
   const [disabled,setDisabled] = useState(0)
   const [isSubmitting,setIsSubmitting] = useState(false)
   const [serverError,setServerError] = useState(null)
   const [validationError,setValidationError] = useState(null)
   const [isSuccess,setIsSuccess] = useState(false)
   const [isLoading,setIsLoading] = useState(false)
   const [accountTypes,setAccountTypes] = useState(null)
   const [accountGroups,setAccountGroups] = useState(null)
   const [showSelectFund,setShowSelectFund] = useState(false)
   const [funds,setFunds] = useState(null)
   const [showSubAccounts, setShowSubAccounts] = useState(false) 
   const[activities,setAccountActivities]=useState([])
   const[accountSubTypes,setAccountSubTypes] = useState([])
   const [filteredAccountSubtypes,setFilteredAccountSubtypes] = useState([])



   const handleChange =(e)=>{
    setIsSuccess(false)
    setServerError(null)
    setValidationError(null)
    filterAccounts()
    let name = e.target.name;
    switch(name){
        case 'account_type_id':
            setAccountSubType(null)
            setAccountType(e.target.value);
             //filter accounts only to that of selected account type
        let copy_of_accounts =[...accounts];
        const filtered_accounts = copy_of_accounts.filter((result)=>result.account_type_id===e.target.value);
        //reset accounts
        setFilteredAccounts(filtered_accounts);

        const subtypes = accountSubTypes.filter((type)=>type.account_type_id===e.target.value)
        setFilteredAccountSubtypes(subtypes)           
           
            break;
            case 'account_name':
            setAccountName(e.target.value);
            break;
        case 'account_number':
            setAccountNumber(e.target.value);
            break;
            case 'account_group_id':
                setAccountGroupId(e.target.value);
                break;
                case 'account_activity':
                    setAccountActivity(e.target.value);
                    break;
        case 'description':
            setDescription(e.target.value);
            break;
            case 'parent_id':
                setParentId(e.target.value);
                break;
            case 'disabled':
                setDisabled(1);
                break;
                case 'fund_id':
                 setFundId(e.target.value);
                    break;
                    case 'account_sub_type':
                        setAccountSubType(e.target.value);
                           break;
            default:
                console.log('Invalid Input');
                break;
    }
   }
   //filter accounts based on selected account type (Assets,Liabilities,Equity,Revenue or Expenses)
   const filterAccounts = ()=>{
    if(account_type_id !== null){
         
        //filter accounts only to that of selected account type
        let copy_of_accounts =[...accounts];
        const filtered_accounts = copy_of_accounts.filter((result)=>result.account_type_id===account_type_id);
        //reset accounts
        setFilteredAccounts(filtered_accounts);
    }
   }



    const handleSubmit = async(e)=>{
        e.preventDefault();
        setServerError('')
        setValidationError('')
       
        if(account_type_id === undefined){
            setValidationError('Select Account Type')
            return;
        }
        if(account_activity === undefined){
            setValidationError('Enter Account Activity')
            return;
        }
        if(account_group_id === undefined){
            setValidationError('Select Account Group')
            return;
        }
        if(account_name === undefined){
            setValidationError('Enter  account name')
            return;
        }
        /*if(account_number === undefined){
            setValidationError('Enter  account number')
            return;
        }*/
        const user = JSON.parse(localStorage.getItem('user'))

        const data ={
            account_name:account_name,
            account_type_id:account_type_id,
            account_activity:account_activity,
            account_sub_type:account_sub_type,
            account_group_id:account_group_id,
             parent_id:parent_id,
            fund_id:fund_id,
            updated_by:user.id,
            user_id:user.id

        }
        setIsSubmitting(true)
        const post = await makePutRequest(`sample-account/${account.id}`,data)
        if (post && post.errorType) {
            setIsSubmitting(false);
            const errorMessage = ErrorHandler(post)
            setServerError(errorMessage)

        } else {
            
          
            getAccounts();
            setIsSubmitting(false);           
            setIsSuccess(true);
            !showSubAccounts && setParentId(null);

        }

    }
    const linkToFund = ()=>{
        if(showSelectFund){
            setShowSelectFund(false)
        }else{
            setShowSelectFund(true)
        }
    }
    const showAccounts = (e)=>{
        setParentId(null)
        showSubAccounts ? setShowSubAccounts(false) :setShowSubAccounts(true);
        
    }

    const getAccounts = async()=>{
            setServerError(null)
            setIsLoading(true)
            const res = await makeGetRequest('sample-accounts');
            if (res && res.errorType) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res)
                setServerError(errorMessage)
    
            } else {
                  setAccounts(res.data)
                  setFilteredAccounts(res.data)
                setServerError(null);
                setIsLoading(false);
                
        
            }

        }
    useEffect(()=>{
        const getAccountTypes = async()=>{
            setServerError(null)
            setIsLoading(true)
            const res = await makeGetRequest('church-account-types');
            if (res && res.errorType) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res)
                setServerError(errorMessage)
    
            } else {
                  setAccountTypes(res.data)
                setServerError(null);
                setIsLoading(false);
                
        
            }

        }

        

        const getAccountGroups = async()=>{
            setServerError(null)
            setIsLoading(true)
            const res = await makeGetRequest('sample-account-groups');
            if (res && res.errorType) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res)
                setServerError(errorMessage)
    
            } else {
                  setAccountGroups(res.data)
                setServerError(null);
                setIsLoading(false);
                
        
            }

        }

        const getFunds = async()=>{
            setServerError(null)
            setIsLoading(true)
            const res = await makeGetRequest('library-funds');
            if (res && res.errorType) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res)
                setServerError(errorMessage)
    
            } else {
                  setFunds(res.data)
                setServerError(null);
                setIsLoading(false);
                console.log('funds',funds);
                console.log(res.data)        
            }

        }

        
    async function getAccountSubtypes() {
        
        // You can await here
        const res = await makeGetRequest(`account-sub-types`);
        if (ResizeObserver && ResizeObserver.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setServerError(errorMessage)

        } else {
            setAccountSubTypes(res.data)
            setFilteredAccountSubtypes(res.data)                  
            setIsLoading(false);
        }       

    }

    const getAccountActivities = async()=>{
        setServerError(null)
        setIsLoading(true)
        const res = await makeGetRequest('account-activities');
        if (res && res.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setServerError(errorMessage)

        } else {
              setAccountActivities(res.data)
            setServerError(null);
            setIsLoading(false);
            
            
    
        }

    }
        
        getAccountTypes();
        getAccountSubtypes();
        getAccounts();
        getAccountGroups();
        getFunds();
        getAccountActivities();

    },[])
    return (
        <div className="container">
            <ReactTooltip type='info' multiline="true"/>
             <div>
             <form onSubmit={handleSubmit} className="form-group">
           {pageTitle===null &&  <h3 className="text-center">Edit Account </h3>}
            {isSubmitting && <CircularProgress/>}
            {isSuccess && <SuccessAlert/>}
            {serverError !== null && !isSubmitting ? <p className="text text-warning">{serverError}</p>  : null}
         {validationError !== null && !isSubmitting ? <p className='text text-danger'>{validationError}</p> : null}
         <div className="row jumbotron">
         <div className="col-md-6">
                <div className=" form-group">
               
                <span >Account Type</span>
                {isLoading && <CircularProgress/>}               
                <select id="account_type_id" className="form-control browser-default custom-select" name="account_type_id" onChange={handleChange}>
                <option value={account_type_id}>{toTitleCase(account.sampleAccountType.type)}</option>
               {
            accountTypes === null ? <p>No Accounts Added yet!</p> :

             accountTypes &&  accountTypes.map((type)=>{
                return  <option value={type.id} key={type.id}>{toTitleCase(type.type)}</option>
               })
               }
              

            </select>

            </div>

            </div>

            <div className="col-md-6">
                <div className="form-group">
                    
                {
                         
                          
                          <>
                          <br/>
                          <label>Account Subtype</label><br/>
                          <select id="account_sub_type" name="account_sub_type" className="form-control" onChange={handleChange}>
                                {
                                 filteredAccountSubtypes.map((type)=>{
                                    return  <option value={type.sub_type}>{toTitleCase(type.sub_type)}</option>
                                })
    
                                }
                           
                            </select>
                          </>
                          
    
                        }
                    
                                        
                    
                    
                    
                    
                   
                    </div>
               
                    
                </div>

         </div>
         <div className="row">
                <div className="col-md-6">
                <div className=" form-group">
                <span >Account Name </span>
                <input type="text" className="form-control" name="account_name"  placeholder="Enter Account account_name" onChange={handleChange} value={account_name}/>

            </div>

                </div>
                <div className="col-md-6">
                <div className="form-group">
                <span >Account Group <span className="text text-info" data-tip='Account group referes to collection that match certain accounts <br/> e.g. payables, receivables, bank etc.'><HelpIcon/></span></span>
               {
                accountGroups !== null ?
                <select name="account_group_id" className="form-control" id="account_group" onChange={handleChange}>
                    <option value={account_group_id}>{toTitleCase(account.sampleAccountGroup.account_group)}</option>
                    {
                        accountGroups.map((group)=>{
                           return <option key={group.id} value={group.id}>{toTitleCase(group.account_group)}</option>
                        })
                    }

                </select>
                :
                <p>No Account Groups yet, <Link to="/create-sample-account-group">Create Account Group</Link></p>
               }
            </div>
                    
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                <div className="form-group">
                <span >Account Activity <span className="text text-info" data-tip='Account Activity refers to how you would like the account to appear on the Statement of Cash Flows reports. <br/> The four options are: Cash, Operating, Investing and Financing'><HelpIcon/></span></span>
           
              {
              activities &&  activities.length !== 0 ?
                 <select id="account_activity" name="account_activity" className="form-control" onChange={handleChange}>

                       {
                        activities &&  activities.map((activity)=>{
                               return <option key={activity.id} value={activity.activity}>{toTitleCase(activity.activity)}</option>
                            })
                        }
                         </select>
                        :
                        <p>No Account activities found</p>
                    }

            </div> 

                </div>
                <div className="col-md-6">
                <div className="form-group">
                <span ><input type="checkbox" name="link_to_fund" value="1" onChange={linkToFund} />
                Link Account to A Fund
                <span className="text text-info" data-tip='Associate the account being created to a fund. <br/> For example building material expense account can be linked to building fund'><HelpIcon/></span>
                </span>
              {
              showSelectFund &&
              <div>
                 {
                funds !== null ?
                <select name="fund_id" className="form-control" id="fund_id" onChange={handleChange}>
                    <option value="">Select Fund</option>
                    {
                        funds.map((fund)=>{
                           return <option key={fund.id} value={fund.id}>{toTitleCase(fund.fund)}</option>
                        })
                    }

                </select>
                :
                <p>Create Fund, <Link to="/create-fund">Create Fund</Link></p>
               }

                </div>
              
              }
              
            </div>
                
               
                    
                </div>
            </div>

            <div className="row">
            <div className="col-md-6">
            <div className="form-group">
                <span ><input type="checkbox" name="sub-account" onChange={showAccounts}/><span>Is Sub-Account</span></span>              
            </div>
            {
                showSubAccounts ?
                accounts !== null ?
                <select id="account_activity" name="parent_id" className="form-control" onChange={handleChange}>
                <option value="">Choose Parent Account</option>
                {
                  filteredAccounts.map((account)=>{
                      return <option key={account.id} value={account.id}>{toTitleCase(account.account_name)}</option>
                  })
                }
              
              </select>
                :
                <p className="text text-warning">No Accounts created </p>
               
            :
            null
            }

            </div>

                </div>
                <div className="col-sm-12 form-group ">
                <div className="row">
                    <div className="col-md-6">
                   
                   {pageTitle===null &&  <Link to="/dashboard"> <button className="cancel-btn float-left">Close</button></Link>}

                    </div>
                    <div className="col-md-6">
                    <button className="btn btn-primary float-right">{isSubmitting ? <CircularProgress/> : "Save"}</button>


                    </div>
                </div>
            

            </div>  

            </form>
        
        </div>
        </div>
    
    )

}
export default EditAccount;