import React, { useState,useEffect } from 'react';
import {makeGetRequest,makePostRequest,makePutRequest} from '../../services/httpHelper'
import ErrorHandler from '../../services/ErrorHandler'
import { CircularProgress } from '@material-ui/core';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { FaHistory } from 'react-icons/fa';


const Products = () => {
    const [products,setProducts] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [serverError,setServerError] = useState(null);
    const[isSubmitting,setIsSubmitting]=useState(false)
    const[refreshed,setRefreshed]=useState(false)



    const navigate = useNavigate();
  
    const getProducts = async()=>{
        setIsLoading(true);
        const res = await makeGetRequest('paypal-products');
        if (res && res.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setServerError(errorMessage)

        } else {
            setProducts(res.data.products)        
            setIsLoading(false);
            setServerError(null)
            

        }
       
    }

    const refreshProducts = async(e)=>{
        e.preventDefault();
        setServerError(null)
        setRefreshed(false);    
    
        setIsSubmitting(true)
        const post = await makePostRequest('refresh-products',{})
        if (post && post.errorfund) {
            setIsSubmitting(false);
            const errorMessage = ErrorHandler(res)
            setServerError(errorMessage)

        } else {
              
            setIsSubmitting(false);
           setRefreshed(true);


        }

    }

  
    

    useEffect(()=>{
       
        getProducts();
        },[])

    
  
        return (
        
            
              
                 <div className="container">
                 <br/>
                 <div className='row'>
                    <div className='col-md-8'>
                    <h3>Products</h3>
                    </div> <div className='col-md-4'>
                        <div style={{display:'flex', justifySelf:'spaceBetween'}}>

                        <Link to="/create-product" className="btn btn-primary">Add Product</Link>
                        <Link onClick={(e)=>refreshProducts(e)} className="btn btn-secondary" style={{marginLeft:'15px'}}><FaHistory/> {isSubmitting ? <CircularProgress/>:'Refresh'}</Link>


                        </div>
                    </div>
                 </div>
                 {serverError !== null && <p className='text text-danger'>{serverError}</p>}
                 {isLoading  && <CircularProgress/>}

                
                 {
                    products.length !==0  ?
                    <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th>
                                Product
                            </th>
                            <th>
                                ProductID
                            </th>
                            <th>
                                Description
                            </th>
                            <th>
                               Category
                            </th>
                            <th>
                               Type
                            </th>
                            <th>
                               URL
                            </th>
                            <th>
                               CreateAt
                            </th>
                            <th>
                               UpdatedAt
                            </th>
                            
                            <th>
                                
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                       products.map((product)=>{
                                return <tr key={product.id}>
                                    <td>{product.name}</td>
                                    <td>{product.id}</td>
                                    <td>{product.description}</td>
                                    <td>{product.category}</td>
                                    <td>{product.type}</td>
                                    <td>{product.home_url}</td>
                                    <td>{product.create_time}</td>
                                    <td>{product.update_time}</td>
                                   

                                </tr>
                            })
                        }
                    </tbody>
                 </table>
                 :
                 <p className='text text-info'>No products</p>

                 }
                
                 

           
            </div>
           


        );
   

}
export default Products;