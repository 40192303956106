import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './Subscribe.css';
import { FaCheck } from 'react-icons/fa';
import useSubscribe from './useSubscribe';
import validateSubscriptionForm from './validateSubscriptionForm';
import PaypalLogo from '../../assets/paypal.png';
import CheckoutCards from '../../assets/cards.png'
import { CircularProgress } from '@mui/material';
import { PayPalButton } from "react-paypal-button-v2";
import { PAYPAL_CLIENT_ID } from '../../config/env';




const Subscribe = ()=>{
 
    const [showCoupon, setShowCoupon] = useState(false)
    const [couponOfferEnabled, setCouponOfferenabled] = useState(false) //Will be manually updated to begin allowing coupon offers

    function submitForm() {
        setIsSubmitted(true);
    }
    const {id} = useParams();
    const { handleChange, values, handleSubmit,  isSubmitting, errors, error, isSuccess, features,isLoading,countries,plan } = useSubscribe(submitForm, validateSubscriptionForm,id);

   


   

    const showCouponForm =()=>{
        setShowCoupon(!showCoupon)
    }
   
     

    const paypalSubscribe = (data, actions) => {
        return actions.subscription.create({
        'plan_id': plan.third_party_id,
        });
        };

    const paypalOnError = (err) => {
        console.log("Error")
        }
        const paypalOnApprove = (data, detail) => {
        // call the backend api to store transaction details
        console.log("Paypal approved")
        console.log(data.subscriptionID)
        }

    return(
        <div className='container'>


            <h4 className='subs-header'>Start Free 30 days Trial</h4>
            {error !== null &&  <p className='text text-danger'>{error}</p>}
            <div className='row'>
                <div className='col-md-5'>
                <div className="white-space"></div>
                  {
                    plan !== null ?
                   <div>

<blockquote>{plan.plan} plan worth ${plan.monthly_fee} free for the first 30 days</blockquote>
                    <h4>Included in the Plan</h4>
                    {
                features !== null &&
                features.map((feature)=>{
                  return <p key={feature.id}><span><FaCheck className='text text-success'/></span> {feature.feature}</p>
                })
              }
                   </div>
                    :
                    <p className='text text-warning'>Error: Something went wrong</p>
                  }
                </div>
                <div className='col-md-7'>
                <div className="white-space"></div>
                <div className="wrapper">
                <div className="row">
                    <div className='col-md-12'>
                    <div className="info-bar">
                        
                    <p>
							<i className="fa fa-info"></i> 
							Already have and account? <Link to="/login">Click here to login</Link>
						</p>

                    </div>

                    <p>
						Please fill in the following form to begin your 30 days free trial
					</p>

                    </div>
                    </div>
                  
                    <div className="row">
                    <div className='col-md-12'>
                  
                        {
                            couponOfferEnabled &&    
                            <div className="info-bar">
                            <p>
							<i className="fa fa-info"></i> 
							Have a coupon? <Link onClick={()=>showCouponForm()}>{!showCoupon?"Click here to enter your code":"Hide"}</Link>
						</p>
                        </div>
                        }
                 
                       

                    </div>
                    </div>
                    {
                        showCoupon &&
                        <div className='row'>
                        <div className='col-md-6 coupon'>
                            <form>
                            <input type="text" name="coupon" id="coupon" placeholder="Coupon code"/>
                            <input type="submit" name="submit" value="Apply Coupon"/>
					      </form>
                            

                        </div>
                     
                    </div>
                    }
                   
                    <div className='row'>
                        <div className='col-md-12'>
                        <form onSubmit={handleSubmit}>
					<div className="col-7 col">
						<h3 className="topborder"><span>Billing Details</span></h3>
						<label htmlFor="country">Country</label>
						<select name="country_code" id="country"  onChange={handleChange} value={values.country_code}>
							<option value="">Please select a country</option>
                            {
                                countries !== null ?
                                countries.map((country)=>{
                                    return <option key={country.id} value={country.country_code}>{country.country}</option>

                                })
                                :
                                <p className='text text-danger'>No Countries</p>
                            }
							
						</select>
                        {errors.country_code && <p className=" text text-danger .error-message">{errors.country_code}</p>}
						<div className="width50 padright">
							<label htmlFor="first_name" >First Name</label>
							<input type="text" name="first_name" id="first_name" placeholder='First Name' onChange={handleChange} value={values.first_name}/>
                            {errors.first_name && <p className=" text text-danger .error-message">{errors.first_name}</p>}
						</div>
						<div className="width50">
							<label htmlFor="last_name" >Last Name</label>
							<input type="text" name="last_name" id="last_name"  placeholder='Last Name' value={values.last_name} onChange={handleChange}/>
                            {errors.last_name && <p className=" text text-danger .error-message">{errors.last_name}</p>}
						</div>
                        <div className="width50 padright">
							<label htmlFor="email">Email Address</label>
							<input type="text" name="email" id="email" placeholder='Email' value={values.email} onChange={handleChange}/>
                            {errors.email && <p className=" text text-danger .error-message">{errors.email}</p>}
						</div>
						<div className="width50">
							<label htmlFor="tel">Mobile Number</label>
							<input type="text" name="phone" id="tel" placeholder='Mobile Number' value={values.phone} onChange={handleChange}/>
                            {errors.phone && <p className=" text text-danger .error-message">{errors.phone}</p>}
						</div>
						<label htmlFor="company">Church Name</label>
						<input type="text" name="church_name" id="company" placeholder='Church Name' value={values.church_name} onChange={handleChange}/>
                        {errors.church_name && <p className=" text text-danger .error-message">{errors.church_name}</p>}
						<label htmlFor="address">Address</label>
						<input type="text" name="address_line_1" id="address" placeholder='Address 1' value={values.address_line_1} onChange={handleChange}/>
                        {errors.address_line_1 && <p className=" text text-danger .error-message">{errors.address_line_1}</p>}
						<input type="text" name="address_line_2" id="address2" placeholder="Address 2" value={values.address_line_2} onChange={handleChange}/>
                        {errors.address_line_2 && <p className=" text text-danger .error-message">{errors.address_line_2}</p>}
						
                        <div className="width50">
                        <label htmlFor="city">Town / City</label>
							<input type="text" name="city" id="city" placeholder="Town/City" value={values.city} onChange={handleChange} />
                            {errors.city && <p className=" text text-danger .error-message">{errors.city}</p>}
						</div>
						<div className="width50">
							<label htmlFor="postcode">Postcode</label>
							<input type="text" name="postal_code" id="postcode" placeholder="Postal Code / Zip" value={values.postal_code} onChange={handleChange}/>
                            {errors.postal_code && <p className=" text text-danger .error-message">{errors.postal_code}</p>}
						</div>

                        <div className="width50">
                        <span htmlFor="password">Password</span>
							<input type="password" name="password" id="password" placeholder="Password" value={values.password} onChange={handleChange}/>
                            {errors.password && <p className=" text text-danger .error-message">{errors.password}</p>}
						</div>
						
						<div className="width50">
							<label htmlFor="password2">Confirm Password</label>
							<input type="password" name="password2" id="password2" placeholder="Confirm Password" value={values.password2} onChange={handleChange}/>
                            {errors.password2 && <p className=" text text-danger .error-message">{errors.password2}</p>}
						</div>
						
						{
                            /*
                            <input type="checkbox" value="2" name="checkbox"/><p>Create an account?</p>
						
						<label htmlFor="notes" className="notes">Order Notes</label>
						<textarea name="notes" id="notes" placeholder="Notes about your order, e.g. special notes for delivery."></textarea>

                            */
                        }
					</div>
					<div className="col-5 col order">			

					
						<div>
							<input type="radio" value="paypal" name="payment"/>
                                <h4>Checkout securely With Paypal</h4>
							<fieldset className="paymenttypes">
								<legend>
                                    <Link to="/https://paypal.com"><img src={PaypalLogo} alt="PayPal Logo" height={70} width={70}/></Link>

                                </legend>
								<img src={CheckoutCards} alt="Visa, Mastercard, Maestro and Amex Credit Cards" height={50}/>
							</fieldset>
						
						</div>
            

     
					</div>
				</form>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>

    );

}
export default Subscribe;