import React, { useState,useEffect } from 'react';
import {makeGetRequest,makePostRequest,makePutRequest} from '../../services/httpHelper'
import ErrorHandler from '../../services/ErrorHandler'
import { CircularProgress } from '@material-ui/core';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { FaHistory } from 'react-icons/fa';


const SubscriptionPlans = () => {
    const [plans,setPlans] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [serverError,setServerError] = useState(null);
    const[isSubmitting,setIsSubmitting]=useState(false)
    const[refreshed,setRefreshed]=useState(false)



    const navigate = useNavigate();
  
    const getPlans = async()=>{
        setIsLoading(true);
        const res = await makeGetRequest('subscription-plans');
        if (res && res.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setServerError(errorMessage)

        } else {
            setPlans(res.data)        
            setIsLoading(false);
            setServerError(null)
            

        }
       
    }

    const refreshPlans = async(e)=>{
        e.preventDefault();
        setServerError(null)
        setRefreshed(false);    
    
        setIsSubmitting(true)
        const post = await makePostRequest('refresh-plans',{})
        if (post && post.errorfund) {
            setIsSubmitting(false);
            const errorMessage = ErrorHandler(res)
            setServerError(errorMessage)

        } else {
              
            setIsSubmitting(false);
           setRefreshed(true);


        }

    }

  
    

    useEffect(()=>{
       
        getPlans();
        },[])

       /* if(refreshed){
            return <Navigate to="/plans"/>
        }*/
  
        return (
        
            
              
                 <div className="container">
                 <br/>
                 <div className='row'>
                    <div className='col-md-8'>
                    <h3>Subscription Plans</h3>
                    </div> <div className='col-md-4'>
                        <div style={{display:'flex', justifySelf:'spaceBetween'}}>

                        <Link to="/create-subscription-plan" className="btn btn-primary">Add Plan</Link>
                        <Link onClick={(e)=>refreshPlans(e)} className="btn btn-secondary" style={{marginLeft:'15px'}}><FaHistory/> {isSubmitting ? <CircularProgress/>:'Refresh'}</Link>


                        </div>
                    </div>
                 </div>
                 {serverError !== null && <p className='text text-danger'>{serverError}</p>}
                 {isLoading  && <CircularProgress/>}

                
                 {
                    plans.length !==0  ?
                    <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th>
                                Plan
                            </th>
                            <th>
                                Description
                            </th>
                            <th>
                                Monthly Price
                            </th>
                            <th>
                                Annual Price
                            </th>
                            <th>
                                Active
                            </th>
                            <th>
                                
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            plans.map((plan)=>{
                                return <tr key={plan.id}>
                                    <td>{plan.plan}</td>
                                    <td>{plan.description}</td>
                                    <td>{plan.monthly_fee}</td>
                                    <td>{plan.annual_fee}</td>
                                    <td>{plan.disabled ? 'No':'Yes'}</td>
                                    <td><Link to={`/plan/${plan.id}`} className='link-anchor'>Features</Link></td>

                                </tr>
                            })
                        }
                    </tbody>
                 </table>
                 :
                 <p className='text text-info'>No plans</p>

                 }
                
                 

           
            </div>
           


        );
   

}
export default SubscriptionPlans;