import React, { useState } from 'react';
import './AdminNavbar.css';
import { Link, useNavigate } from 'react-router-dom';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import { FaChurch, FaFunnelDollar,  FaQuestionCircle, FaBlog, FaSubscript, FaChartPie, FaProductHunt, FaDraft2Digital, FaSalesforce, FaTag } from 'react-icons/fa';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PersonAddAlt from '@mui/icons-material/PersonAddAlt';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import { FaVideo, FaCalendar } from 'react-icons/fa';
import AccountBalanceWallet from '@mui/icons-material/AccountBalanceWallet';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { TagLeftIcon } from '@chakra-ui/react';
import AccountBalance from '@mui/icons-material/AccountBalance';
import Logo from '../../assets/logo.png'



const AdminNavbar = ()=>{
  const[activeTab,setActiveTab] = useState('dashboard');
  const[firstSubMenu,setFirstSubMenu] = useState(null);


  const navigate = useNavigate();


  const switchTab = (tab)=>{
    setActiveTab(tab)

  }

  const switchFirstSubMenu = (menu)=>{
    setFirstSubMenu(menu)
    setTimeout(()=>{
      setFirstSubMenu(null)  
    },2000)
   

   

  }


  const navigateToPage =(path)=>{
    setFirstSubMenu(null)   

    navigate(path)
    
  
  

  }
  const user = JSON.parse(localStorage.getItem('user'));

  const logout =()=>{
    localStorage.removeItem('lastVisitedUrl');       
    localStorage.removeItem('user');
    localStorage.removeItem('church');
    localStorage.removeItem('roles');
    localStorage.removeItem('users');
    sessionStorage.removeItem('token');  
  
    window.location.href = '/login'
}

    return(


      <div className="menu-wrapper">
      <ul className="nav nav-tabs">
        <li className={activeTab === 'site' ? 'active' : ''}>
          <Link to="/" data-toggle="tab" ><img src={Logo} alt="logo" width="80" height="85" style={{paddingBottom:"40px"}}/></Link>
        </li>
        <li className={activeTab === 'dashboard' ? 'active' : ''}>
          <Link to="/" data-toggle="tab" onClick={() => switchTab('dashboard')}>Dashboard</Link>
        </li>

        <li className={activeTab === 'subscription' ? 'active' : ''}>
        
          <Link to="/subscription" data-toggle="tab" onClick={() => switchTab('subscription')}>Subscriptions</Link>

        </li>    
      
     
        <li className={activeTab === 'people' ? 'active' : ''}>
          <a href="#people" data-toggle="tab" onClick={() => switchTab('people')}>Users</a>
        </li>

        <li className={activeTab === 'finance' ? 'active' : ''}>
          <a href="#finance" data-toggle="tab" onClick={() => switchTab('finance')}>Finance</a>
        </li>

          
      
        <li className={activeTab === 'skills' ? 'active' : ''}>
          <a href="#skills" data-toggle="tab" onClick={() => switchTab('skills')}>Skills</a>
        </li>
      
        <li className={activeTab === 'support' ? 'active' : ''}>
          <a href="#support" data-toggle="tab" onClick={() => switchTab('support')}>Support</a>
        </li>

      
            
       
        <li>{user && <Link onClick={logout}>Logout</Link> }</li>
     

       
      </ul>


      <div className="tab-content">
        {/* Content for the 'site' tab */}
     

       {activeTab === 'subscription' && (
          <div className="tab-pane active" id="subscription">
            
             <div className='subMenuWrapper'>
              
             <ul className="mul">
    
             <li onClick={()=>switchFirstSubMenu('products')}>
           <FaProductHunt/> &nbsp;
            Products
          <i className='fa fa-drowpdown'/>
            {
              firstSubMenu==='products' &&
              <div className="down">
              <ul>
                <li>
                  <FaSalesforce/> &nbsp;
                  <Link to='/products'> Products</Link>
               
                </li>
                <li>
                  <FaDraft2Digital/> &nbsp;
                  <Link to="/create-product"> Create Product</Link></li>
               
              </ul>
              </div>
            }
            </li>       
   <li onClick={()=>navigateToPage('/plans')} ><FaQuestionCircle/> &nbsp; Subscription Plans  </li>
   <li onClick={()=>navigateToPage('/features')} ><FaQuestionCircle/> &nbsp; Features  </li>
   <li onClick={()=>navigateToPage('/pricing')}><FaBlog/> &nbsp;  Pricing</li>
   {/*<li onClick={()=>navigateToPage('/payments')}><FaVideo/> &nbsp;  Payment Methods</li>*/}
   <li onClick={()=>navigateToPage('/subscribers')}><FaSubscript/> &nbsp;  Subscribers </li>
   {/*<li onClick={()=>navigateToPage('/subscription-reports')}><FaChartPie/> &nbsp;  Reports </li>*/}


</ul>
          </div>
          </div>
        )}



        
        {activeTab === 'people' && (
          <div className="tab-pane active" id="people">
           <div className='subMenuWrapper'>
           <ul className="mul">
           <li onClick={()=>switchFirstSubMenu('users')}>
           <PeopleAltIcon/> &nbsp;
            Users
          <i className='fa fa-drowpdown'/>
            {
              firstSubMenu==='users' &&
              <div className="down">
              <ul>
                <li>
                  <PersonAddAltIcon/> &nbsp;
                  <Link to='/users'> Staff</Link>
               
                </li>
                <li>
                  <PeopleAltIcon/> &nbsp;
                  <Link to="/register-user"> Add Staff</Link></li>
               
              </ul>
              </div>
            }
          
         

           </li>
           <li onClick={()=>navigateToPage('/churches')} ><FaChurch/> &nbsp; Churches  </li>
        
           
<li onClick={()=>switchFirstSubMenu('roles')}>
<VerifiedUserIcon/> &nbsp;
  
  Roles
{
              firstSubMenu==='roles' &&
              <div className="down">
              <ul>
                <li onClick={()=>navigateToPage('/user-roles')}> <AllInclusiveIcon/> &nbsp;Roles</li>
                <li onClick={()=>navigateToPage('/create-user-role')}><PersonAddAlt/> &nbsp;Create Role</li>
                
              </ul>
              </div>
            }
</li>








	
            </ul>
            </div>
          </div>
        )}
          {activeTab === 'finance' && (
         <>
         {/*Finance row 1 */}
         <div className='row'>
          <div className='col-md-12'>
          <div className="tab-pane active" id="finance">
              <div className='subMenuWrapper'>
           <ul className="mul">
           <li onClick={()=>switchFirstSubMenu('accounts')}>
           <AccountBalance/> &nbsp;
             Accounts
          <i className='fa fa-drowpdown'/>
            {
              firstSubMenu==='accounts' &&
              <div className="down">
              <ul>
              
                <li onClick={()=>navigateToPage('/chart-of-accounts')}>
                  <SocialDistanceIcon/> &nbsp;
                  Chart of Accounts</li>
                  <li onClick={()=>navigateToPage('/account-types')}>
                  <SettingsSuggestIcon/> &nbsp;
                  Account Type</li>

                  <li onClick={()=>navigateToPage('/account-sub-types')}>
                  <FaTag /> &nbsp;
                  Account Sub-Types</li>

                  <li onClick={()=>navigateToPage('/account-groups')}>
                  <AccountBalanceWallet/> &nbsp;
                  Account Groups</li>
                
                            
                              
                  
               
              </ul>
              </div>
            }
          
         

           </li>


           <li onClick={()=>switchFirstSubMenu('funds')}>
<FaFunnelDollar/> &nbsp;
  
  Funds
{
              firstSubMenu==='funds' &&
              <div className="down">
              <ul>
                <li onClick={()=>navigateToPage('/library-funds')}><FaCalendar/> &nbsp;Library Funds </li>
                
               
              </ul>
              </div>
            }
</li>

<li onClick={()=>switchFirstSubMenu('tags')}>
<TagLeftIcon/> &nbsp;
  
  Tags
{
              firstSubMenu==='tags' &&
              <div className="down">
              <ul>
              
\                <li onClick={()=>navigateToPage('/manage-tag-groups')}><AddModeratorIcon/> &nbsp; Tag Groups</li>
              
              </ul>
              </div>
            }
</li>

{/*
<li onClick={()=>switchFirstSubMenu('reports')}>
<FaChartPie/> &nbsp;
  
  Reports
{
              firstSubMenu==='reports' &&
              <div className="down">
              <ul>
                <li onClick={()=>navigateToPage('/trial-balance')} ><FaBalanceScale/> &nbsp; Trial Balance </li>
                <li onClick={()=>navigateToPage('/cash-flow-statement')}><FaMoneyCheck/> &nbsp;Cash Flow Statement</li>
                <li onClick={()=>navigateToPage('/income-statement')}><FaChartLine/> &nbsp;Income Statement </li>
                <li onClick={()=>navigateToPage('/balance-sheet')}><FaCommentDollar/> &nbsp;Balance Sheet</li>
               
               
              </ul>
              </div>
            }
</li>*/}
           







	
            </ul>
            </div>
          </div>

          </div>
         </div>

        
         </>
        )}

  

{activeTab === 'skills' && (
          <div className="tab-pane active" id="support">
             <div className='subMenuWrapper'>
          <ul className="mul">
                <li onClick={()=>navigateToPage('/skills')} ><FaQuestionCircle/> &nbsp; Skills  </li>
               {/* <li onClick={()=>navigateToPage('/skill-categories')}><FaBlog/> &nbsp;  Skill Categories </li>*/}
            
                </ul>
          </div>
          </div>
        )}

{activeTab === 'support' && (
          <div className="tab-pane active" id="support">
             <div className='subMenuWrapper'>
          <ul className="mul">
                <li onClick={()=>navigateToPage('/faqs')} ><FaQuestionCircle/> &nbsp; FAQs  </li>
               {/* <li onClick={()=>navigateToPage('/watch-get-started-video')}><FaVideo/> &nbsp;  Get Started Video </li>*/}
               
                </ul>

                <ul className="mul">
           <li onClick={()=>switchFirstSubMenu('users')}>
           <FaBlog/> &nbsp;
            Blog
          <i className='fa fa-drowpdown'/>
            {
              firstSubMenu==='users' &&
              <div className="down">
              <ul>
                <li>
                  <PersonAddAltIcon/> &nbsp;
                  <Link to='/blog'> Posts</Link>
               
                </li>
                <li>
                  <PeopleAltIcon/> &nbsp;
                  <Link to="/post-categories"> Categories</Link></li>
               
              </ul>
              </div>
            }
          
         

           </li>
           </ul>
          </div>
          </div>
        )}
       
{activeTab === 'logout' && (
          <div className="tab-pane active" id="logout">
            <p>Logout</p> 
          </div>
        )}


       
      </div>
    </div>
    
        

    )

    
}

export default AdminNavbar;

