import React, { useState, useEffect} from 'react';
import { Link, Navigate,useParams } from 'react-router-dom';
import {makeGetRequest} from '../../../services/httpHelper'
import ErrorHandler from '../../../services/ErrorHandler';
import { CircularProgress } from '@mui/material';
import { toTitleCase } from '../../../utils/myFunctions';
import CustomModal from '../../../components/ui/CustomModal';
import EditFund from './EditFund';



const Funds = ()=>{
    const [funds, setFunds] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false);
    const [serverErrorMessage, setServerErrorMessage] = useState(null);   
    const[fund, setFund] =useState(null)


    const user = JSON.parse(localStorage.getItem('user'));

    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);


    const handleShow = (fund)=>{
        setFund(fund)
        setShowModal(true);
    } 


    async function editFund(id){
        alert(id)
    }

  

    
    useEffect(()=>{
        async function getFunds() {
            
            setIsLoading(true)
            // You can await here
            const res = await makeGetRequest('library-funds');

            if (ResizeObserver && ResizeObserver.errorType) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res)
                setServerErrorMessage(errorMessage)
                alert('fetching funds-error')

    
            } else {

                console.log(res.data)
                setFunds(res.data)                  
                setIsLoading(false);
                setIsSuccess(true);
            }       
    
        }
        getFunds();

    },[])

    const pageTitle = 'Edit Fund';
    const modalBody = <EditFund pageTitle={pageTitle} fund={fund}/>

    return(
        <div className='container'>
                <CustomModal 
            show={showModal}
            showModal={showModal}
            handleClose={handleClose}
            header={pageTitle}
            body={modalBody}
            size="lg" // You can change the size as needed
            footer=''
            />
            {serverErrorMessage !== null && <p>Something wrong happened: <span className="text text-danger">{serverErrorMessage}</span></p>}
             {isLoading && <CircularProgress color="primary"/>}
               {
                            isSuccess ?
                                <div className="card">

<Link to="/add-fund" className='link-anchor' style={{marginLeft:'85%', paddingTop:'25px'}}>Add Fund</Link>
                                    
                                         
                                            
                                            {
                                                funds?.length !== null ?
                                                <table className="table table-responsive table-striped">
                                                <thead>
                                                    <tr style={{fontSize:"15px"}}>
                                                        
                                                        <th>Fund</th>
                                                        <th>Description</th>
                                                        
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        
                                                       funds && funds.map((fund)=>{
                                                                                                                        
                                                        return <tr key={fund.id} style={{fontSize:"15px", color:`${fund.color}`}}>
                                                            <td>{toTitleCase(fund.fund)}</td>
                                                            <td>{toTitleCase(fund.description)}</td>
                                                            <td><Link onClick={()=>handleShow(fund)}>Edit</Link></td>


                                                        </tr>

                                                    })
                                                    }

                                                    </tbody>
                                                   
                                                
                                            </table>
                                            :
                                            <div>

                                            <p>No Funds <span><Link to="/add-fund" className="btn btn-info">CREATE FUND</Link></span></p>
                                            
                                                    </div>
                                            }
                                           
                                            

                                      

                                </div>
                                :
                                <div>
                                    {serverErrorMessage && <p className="text text-danger">{serverErrorMessage}</p>}



                                </div>
                        }

        </div>
    )
}
export default Funds;