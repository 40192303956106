import React, { useState, useEffect,useRef} from 'react';
import {makeGetRequest} from '../../../services/httpHelper'
import ErrorHandler from '../../../services/ErrorHandler';
import LoadingIcon from '../../../components/ui/LoadingIcon';
import { ErrorState } from '../../../components/ui/UiState';
import DataTable from 'react-data-table-component';
import { FaBaby, FaFileCsv, FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { CSVLink, CSVDownload } from "react-csv";
import { DownloadTableExcel,useDownloadExcel } from 'react-export-table-to-excel';
import { IMAGE_BASE_URL } from '../../../constants/paths';


const columns = [
    {
        name: 'Date.',
        selector: row => row.createdAt,
        sortable: true,
    },
 
 
  
    {
        name: 'Action Type',
        selector: row => row.action_type,
        sortable: true,
      
    },
    {
        name: 'Entity Type',
        selector: row => row.entity_type,
    },
    {
        name: 'Changes',
        selector: row => row.changes,
        sortable: true,
    },
    {
        name: 'Entity ID',
        selector: row => row.entity_id,
        sortable: true,
    },

    {
        name: 'User Agent',
        selector: row => row.user_agent,
        sortable: true,
    },
    {
        name: 'IP Address',
        selector: row => row.ip_address,
        sortable: true,
    },
   
 
];


const AuditLog = ()=>{
    const [activities,setActivities] =useState(null)
    const [isLoading,setIsLoading] = useState(true)
    const [error,setError] = useState(null)   
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const[data,setData] = useState([])


    const tableRef = useRef(null);
    const tableRef2 = useRef(null);

  


const church = JSON.parse(localStorage.getItem('church'));

const prepareData =(audits)=>{
    
    const newData = audits.map((transaction)=>({
        createdAt:transaction.createdAt,
        action_type:transaction.action_type,
        entity_type:transaction.entity_type,
        changes:transaction.changes,
        entity_id:transaction.entity_id,
        user_agent:transaction.user_agent,
        ip_address:transaction.ip_address,
      
    }));

    return newData;


}


    const getAudityLog = async()=>{
        setIsLoading(true)
        const res = await makeGetRequest('audit-log')
        if(res && res.errorType){
            setIsLoading(false)
            const errorMessage = ErrorHandler(res)
            setError(errorMessage)
            
        }else{
            setError(null)
            setActivities(res.data);
            const newData = prepareData(res.data)
            setData(newData);
            setIsLoading(false);
        }

    }

    const downloadPdf = async(e) =>{
        e.preventDefault();
        setError(null)
        setIsSubmitting(true)
        setIsSuccess(false)
      

        const res = await makeGetRequest('audit-log-pdf');
        if (res && res.errorType) {
            setError(res.errorMessage)
           setIsSubmitting(false);
            setIsSuccess(false)
            
        } else {
          
          const path = IMAGE_BASE_URL+res.data
            setIsSubmitting(false);
            setIsSuccess(true);
            setError(null);
            window.location.href = `${path}`;

           
            

        }
      

            
        
    }

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current||tableRef2.current,
        filename: 'audit_log',
        sheet: 'Audit Log'
    })

    
    useEffect(()=>{
        getAudityLog();
    },[])

   
    const headers = [
        { label: "Date", key: "createdAt" },
        { label: "Action Type", key: "action_type" },
        { label: "Entity Type", key: "entity_type" },
        { label: "Changes", key: "changes" },
        { label: "Entity ID", key: "entity_id" },
        { label: "User Agent", key: "user_agent" },
        { label: "IP Address", key: "ip_address" },


     
      ];

    

      let csvData =[];
      activities !== null && activities.map((activity)=>{
        csvData.push(activity)
      })

    if(isLoading && error ===null){
        return <LoadingIcon/>
    }
    if(error){
        return <ErrorState/>
    }
    return(
        <div className="container">
             <div style={{marginBottom:'50px', marginLeft:'70px'}}>
            <div className='row'>
                   
                 
                 
                   <div className='col-md-3'>
                   
                   <Button variant="secondary" onClick={downloadPdf}>
                    <FaFilePdf/>   PDF
                    </Button>
                   

                   </div>

                   <div className='col-md-3'>
                  <Button onClick={onDownload} variant='success'><FaFileExcel/> Excel </Button>


                   </div>

                   <div className='col-md-3'>
                            
            <CSVLink data={csvData} headers={headers} className='btn btn-info'><FaFileCsv/>  CSV</CSVLink>;
        
                   
                   </div>
               </div>
            </div>


            <div>
        
                {error !== null && alert(error)}
           
            </div>
        
                             <DataTable
                            ref={tableRef}
                            title="Audit Trail"
                               columns={columns}
                               data={data}
                               pagination
                               paginationPerPage={10} // Number of rows per page
                               paginationRowsPerPageOptions={[10, 20, 30,50,70,100]} // Options for rows per page dropdown
                               paginationComponentOptions={{ // Additional options for pagination component
                                 rowsPerPageText: 'Rows per page:',
                                 rangeSeparatorText: 'of',
                               }}
                              
                             
                           />
                 

        </div>
    )
}

export default AuditLog;
