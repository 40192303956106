import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { isMobile, isEmail } from '../../../utils/formValidator';
import AssignRole from './AssignRole';
import { makeGetRequest } from '../../../services/httpHelper';
import { Link, useParams, Navigate, useNavigate } from 'react-router-dom';



let userData = [];
export default function GetUserWithRoles() {
    const [userId, setUserId] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [responseMessage, setResponseMessage] = useState();
    const [error, setError] = useState(null);
    const [serverErrorMessage, setServerErrorMessage] = useState(null);
    const [user, setUser] = useState(userData)
    const handleChange = (e) => {
        setUserId(e.target.value);


    }

    async function handleSubmit(e) {
        e.preventDefault();
        setIsSubmitting(true)
        if (userId === undefined || userId === null) {
            setIsSubmitting(false)
            setError('Enter  User ID');
            return;
        }
        const isEmailUserId = isEmail(userId);
        const isMobileUserId = isMobile(userId)
        if (!isEmailUserId && !isMobileUserId) {
            setIsSubmitting(false);
            setError('Invalid user ID');
            return;
        }

        // You can await here
        const res = await makeGetRequest('auth/user/user-roles/' + userId);
        if (res && res.errorType) {
            setIsLoading(false);
            setIsSubmitting(false);


            if (res.errorType === 'authentication') {
                setServerErrorMessage(res.errorMessage);

            }

            else if (res.errorType === 'authorization') {
                setServerErrorMessage(res.errorMessage);

            }
            else if (res.errorType === 'badrequest') {
                setServerErrorMessage(res.errorMessage);
            } else {
                setServerErrorMessage(res.errorMessage);
            }
        } else {
            console.log(res.data)
            userData.push(res.data)
            localStorage.setItem('userRoles', user)
            setIsLoading(false);
            setIsSubmitting(false);
            setIsSuccess(true);

        }



    }

    return (


        <div className='container'>

            {isSuccess &&
                <Navigate to="assign-role" />

            }
            <div className="form-container">

                <div className='container'>
                    <h3>Assign User Role</h3>

                    <form onSubmit={(e) => handleSubmit(e)}>
                        {isSubmitting && <CircularProgress color='secondary' />}
                        {error !== null && !isSubmitting ? <p className='text text-danger'>{error}</p> : null}

                        {serverErrorMessage !== null && !isSubmitting ? <p className='text text-danger'>{serverErrorMessage}</p> : null}

                        <div className="input-group mb-3">

                            <input type="text" className="form-control" size="15" id="focusedInput" placeholder="Enter User ID " name="user_id" onChange={(e) => handleChange(e)} /><br />

                        </div>
                        {
                            isSubmitting ?
                                <button type="submit" className="elevator-btn btn-block login-btn" disabled="daisabled"><CircularProgress /></button>
                                :
                                <button type="submit" className="elevator-btn btn-block login-btn">Next</button>


                        }


                    </form>
                </div>


            </div>
        </div>

    );
}