import React, { useState } from 'react';
import useCreateUserRole from './useCreateUserRole'
import validateCreateRole from './validateCreateRole'
import { CircularProgress } from '@mui/material';

const CreateUserRole = ({pageTitle=null}) => {
    const url = 'auth/register-admin';
    const { handleChange, values, handleSubmit, isSubmitting, errors, serverValidationErrors, serverErrorMessage, isSuccess } = useCreateUserRole(submitForm, validateCreateRole);
    const [isSubmitted, setIsSubmitted] = useState(false);
    function submitForm() {
        setIsSubmitted(true);
    }
    return (
        <div className='container'>

            <div className="row justify-content-center">
                <div className='card'>
                {pageTitle===null && <h3 className="card-title text-center" >Create User Role</h3>}
                {isSuccess && <p className="text text-success">Role Created!</p>}
                {serverErrorMessage !== null && !isSubmitting ? <p className='text text-danger'>{serverErrorMessage}</p> : null}

                <div className="card-body py-md-4">
                    {serverValidationErrors && serverValidationErrors.length ?
                        <div>
                            <ul>
                                <li className='text text-danger'>{serverValidationErrors}</li>


                            </ul>
                        </div>
                        : null
                    }
                    <form _lpchecked="1" onSubmit={handleSubmit}>

                        {serverValidationErrors && serverValidationErrors.length ?
                            <div>
                                <ul>
                                    <li className='text text-danger'>{serverValidationErrors}</li>


                                </ul>
                            </div>
                            : null
                        }
                        <div className="form-group">
                            <input type="text" className="form-control" id="name" name="name" placeholder="Role Name" value={values.name} onChange={handleChange} />
                            {errors.name && <p className=" text text-danger error-message">{errors.name}</p>}

                        </div>

                        <div className="form-group">
                            <input type="text" className="form-control" id="description" name="description" placeholder="Description" value={values.description} onChange={handleChange} />
                            {errors.description && <p className=" text text-danger .error-message">{errors.description}</p>}

                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-between">
                            {isSubmitting ?
                                <button type="submit" className="btn" disabled="disabled"><CircularProgress /></button>
                                :
                                <button type="submit" className="btn" >Create</button>

                            }
                        </div>
                    </form>
                </div>

                </div>
               




            </div>




        </div>
    )
}
export default CreateUserRole;