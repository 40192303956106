import React, { useState } from 'react';
import { Link, Navigate,useParams } from 'react-router-dom';
import {makePostRequest} from '../../../services/httpHelper'
import ErrorHandler from '../../../services/ErrorHandler';
import { LoadingState,ErrorState,SuccessAlert } from '../../../components/ui/UiState';
import ToastAlert from '../../../components/ui/ToastAlert'
import { CircularProgress } from '@mui/material';


const CreateAccountType = ()=>{
   
   const [type,setType] = useState()
   const [range,setRange] = useState()
   const [description,setDescription] = useState()
   const [isSubmitting,setIsSubmitting] = useState(false)
   const [error,setError] = useState(null)
   const [validationError,setValidationError] = useState(null)
   const [isSuccess,setIsSuccess] = useState(false)

   const user = JSON.parse(localStorage.getItem('user'));

   const handleChange =(e)=>{
    setError(null)
    setIsSuccess(false)
    let name = e.target.name;
    switch(name){
        case 'type':
            setType(e.target.value);
            break;
        case 'range':
            setRange(e.target.value);
            break;
        case 'description':
            setDescription(e.target.value);
            break;
            default:
                alert('Invalid Input');
                break;
    }
   }



    const handleSubmit = async(e)=>{
        e.preventDefault();
        setError(null)
        setValidationError(null)
       
        if(type === undefined){
            setValidationError('Enter Accounty Type')
            return;
        }
        if(range === undefined){
            setValidationError('Enter Range')
            return;
        }

        const data ={
            range:range,
            type:type,
            description:description,
            user_id:user.id
        }
        setIsSubmitting(true)
       try{
        const post = await makePostRequest('church-account-type',data)
        if (post && post.errorType) {
            setIsSubmitting(false);
            const errorMessage = ErrorHandler(post)
            setError(errorMessage)

        } else {
              
            setType(null);
            setIsSubmitting(false);
            setIsSuccess(true);


        }

       }catch(error){
        console.log(error)
       }

    }
    return (
        <div className="container">
             <div className="card">
        <form onSubmit={handleSubmit} className="form-group">
            <h3 className="text-center">Create Account Type</h3>
            {isSubmitting && <CircularProgress/>}
            {isSuccess && <SuccessAlert/>}
            {isSuccess  &&  <ToastAlert type="success" message='Saved' />}

            {error !== null &&  <ToastAlert type="error" message={error} />}

         {validationError !== null && !isSubmitting ? <ToastAlert type="error" message={validationError} /> : null}
        
        <div className="row jumbotron">
        <div className="col-sm-6 form-group">
                <span >Type</span>
                <input type="text" className="form-control" name="type" placeholder="Enter Account Type" onChange={handleChange}/>

            </div>
            <div className="col-sm-6 form-group">
                <span >Description <span>(Optional)</span></span>
                <input type="text" className="form-control" name="description"  placeholder="Enter Account Description" onChange={handleChange}/>

            </div>
            <div className="col-sm-6 form-group">
                <span >Range</span>
                <input type="text" className="form-control" name="range" placeholder="Enter Range e.g 100-300" onChange={handleChange}/>

            </div>

            
                            
            <div className="row">
            <div className="col-sm-8 form-group">
            <button className="btn btn-primary float-right">Save</button>
            </div>
            <div className="col-sm-4 form-group">
                <br/>
                <Link to="/dashboard" className="link-anchor">Done</Link>
                <br/>
                
            
            </div>
              
          

            </div>
            
            
        </div>
        </form>
        </div>
    </div>
    )

}
export default CreateAccountType;