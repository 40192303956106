import {isEmpty} from '../../utils/formValidator';

export default function validateCreateSubscriptionPlanForm(values){
    let errors = {};
    if(isEmpty(values.plan)){
        errors.plan = 'Enter Plan';
    }
  
    if(isEmpty(values.description)){
        errors.description = 'Enter Description';
    }
    if(isEmpty(values.monthly_fee)){
        errors.monthly_fee = 'Enter monthly fee';
    }
    if(isEmpty(values.product_id)){
        errors.product_id = 'Select product';
    }
     

    
   
   return errors;
   
}