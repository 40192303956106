import React, { useState,useEffect } from 'react';
import './faqs.css';
import {makeGetRequest,makePutRequest,makeDeleteRequest} from '../../services/httpHelper'
import ErrorHandler from '../../services/ErrorHandler'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CircularProgress } from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {Link} from 'react-router-dom'
import EditFaq from './EditFaq';
import { FaEye, FaVolumeMute } from 'react-icons/fa';

const Faqs = () => {
    const [faqs,setFaqs] = useState(null);
    const [isLoading,setIsLoading] = useState(false);
    const [editingFaq,setEditingFaq] = useState(false);
    const [serverError,setServerError] = useState(null);
    const [deleted,setDeleted] = useState(false)
    const [isDeleting,setIsDeleting] = useState(false);
    const [deleteServerError,setDeleteServerError] = useState(null)
    const [selectedFaq,setSlectedFaq] = useState(null)
    const [hidden,setHidden] = useState(false)
    const [unHidden,setUnhidden] = useState(false)




   

    const deleteFaq = async(f)=>{
        setIsDeleting(true)

        const confirmed = window.confirm('Are sure about this? Once the FAQ is deleted cannot be restored!')

        if(confirmed){

            const res = await makeDeleteRequest('delete-faq/'+f);
            if (res && res.errorType) {
                isDeleting(false);
                setDeleteServerError('Something went wrong')
    
            } else {
    
                const filteredFaqs = faqs.filter((faq)=>faq.id !== f)
                setFaqs(filteredFaqs)
                setDeleted(true);
                setDeleteServerError(null)
                setIsDeleting(false)
    
            }

        }
        
       
    }


    const handleHideFaq = async(f)=>{

        const confirmed = window.confirm('This question will  be hidden from customers on main website. Are sure about this?')

        if(confirmed){

            const res = await makePutRequest('hide-faq/'+f);
            if (res && res.errorType) {
                isDeleting(false);
                setDeleteServerError('Something went wrong')
    
            } else {
    
                setHidden(true)
    
            }

        }
        
       
    }

    const handleShowFaq = async(f)=>{

        const confirmed = window.confirm('This question will  be now availeble for customers to see.  Are sure about this?')

        if(confirmed){

            const res = await makePutRequest('un-hide-faq/'+f);
            if (res && res.errorType) {
                isDeleting(false);
                setDeleteServerError('Something went wrong')
    
            } else {
    
                setUnhidden(true)
    
            }

        }
        
       
    }

    const refreshFaqList =(faq_id)=>{
        faqs.filter((faq)=>faq.id !== faq_id)
    }

    const handleEditFaq =(f)=>{
        setSlectedFaq(f)
        setEditingFaq(true)

    }

    

    useEffect(()=>{
        const getFaqs = async()=>{
            setIsLoading(true);
            const res = await makeGetRequest('admin-faqs');
            if (res && res.errorType) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res)
                setServerError(errorMessage)
    
            } else {
                setFaqs(res.data)        
                setIsLoading(false);
                setServerError(null)
    
            }
           
        }
        getFaqs();
        },[])
  
        return (
            <>
            {
                editingFaq ?
                <EditFaq faq={selectedFaq}/>

                :
                <div>

{
                 faqs !== null ?
                 <div className="container">
                    <div style={{display:"flex", justifyContent:'space-between'}}>
                    <Link to="/create-faq" className='btn btn-info' style={{marginLeft:'5%'}}>Create FAQ</Link>

                 
                   <Link to="/manage-faqs" className='btn btn-warning' style={{marginLeft:'10%'}}>Manage FAQ</Link>
                        
                         </div>
                 
                 <br/><br/>
                 <h3>Frequently Asked Questions</h3>
                 <br/>
                 {
                                                     faqs.map((faq) => {
                                                         return (
                                                             <Accordion key={faq.id} className="faq">
                                                                 <AccordionSummary
                                                                     expandIcon={<ExpandMoreIcon />}
                                                                     aria-controls="panel1a-content"
                                                                     id="panel1a-header"
                                                                 >
                                                                     <Typography className="faq-title">{faq.question }</Typography>
                                                                 </AccordionSummary>
                                                                 <AccordionDetails>
                                                                     <Typography>
                                                                         <div className='row'>
                                                                             <div className="col-md-8 ">
                                                                                 {faq.answer}
                                                                             </div>

                                                                             <div className='col-md-4'>

                                                                                <div style={{display:"flex",justifyContent:"space-evenly"}} >
                                                                                   {
                                                                                    faq.show_faq ?
                                                                                    <FaVolumeMute className='text text-warning' style={{cursor:'pointer'}} onClick={()=>handleHideFaq(faq.id)}/>
                                                                                    :
                                                                                    <FaEye className='text text-warning' style={{cursor:'pointer'}} onClick={()=>handleShowFaq(faq.id)}/>
                                                                                   }


                                                                                    {deleteServerError !== null ? <p className="text text-warning">{deleteServerError}</p> : null}
                                                                                {deleted  && refreshFaqList(faq.id)}

                                                                                <EditIcon className='text text-info' style={{cursor:"pointer"}} onClick ={()=>handleEditFaq(faq)}/>

                                                                           
                                                                             <DeleteIcon style={{cursor:"pointer"}} className='text text-danger' onClick={()=>deleteFaq(faq.id)}/>

                                                                             

                                                                                </div>


                                                                             </div>
                                                                            
 
                                                                         </div>
 
 
                                                                     </Typography>
                                                                 </AccordionDetails>
                                                             </Accordion>
                                                         )
                                                     })
                                                 }
                 
 
             </div>
                 :
                 <div>
                 {isLoading && serverError === null && <CircularProgress />}
                 {serverError && <p className="text text-danger">{serverError}</p>}

             </div>
            }
                </div>
            }
            </>
           


        );
   

}
export default Faqs;