import React,{useState,useEffect} from 'react';
import { FaArrowAltCircleRight, FaUser } from 'react-icons/fa';

const DashboardBannerPlain =({title,subtitle,value, icon:Icon})=>{
    return(
        <div className='dashboard-banner-card' style={{backgroundColor:"#eee",marginLeft:"20%"}}>
            <div className='row'>
                    <div className='col-md-9'>
                    <p style={{marginBottom:"0px", textAlign:"center", color:"black",fontWeight:"bold", fontSize:"25px", opacity:"0.7"}}><span style={{fontSize:'20px'}}>$</span>{subtitle}</p>
                    <h3 style={{textAlign:"center", marginTop:"0px", paddingBottom:"10px",color:"orange"}}> {title} </h3>

                      
                    </div>
                    
                      <div className='col-md-3'>
                     {Icon && <Icon className='banner-icon'/>} 
                      
                      </div>
                  </div>

        </div>
    )

}
export default DashboardBannerPlain;