import {isEmpty} from '../../utils/formValidator';

export default function validateCreatePostForm(values){
    let errors = {};
    if(isEmpty(values.category_id.trim())){
        errors.category_id = 'Select post category';
    }
    if(isEmpty(values.title.trim())){
        errors.title = 'Title is required';
    }
  /*  if(isEmpty(values.content.trim())){
        errors.content = 'Enter post content';
    }*/
    /*if(values.content.length<100){
        errors.content = 'Post too short';

    }*/
   /* if(isEmpty(values.featuredImage.trim())){
        errors.featuredImage = 'Upload image';
    }*/
  
    
     

    
   
   return errors;
   
}