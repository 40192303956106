import React, { useState, useEffect} from 'react';
import {makePostRequest,makeGetRequest} from '../../../services/httpHelper'
import ErrorHandler from '../../../services/ErrorHandler';
import { SuccessAlert } from '../../../components/ui/UiState';
import { CircularProgress } from '@mui/material';
import { toTitleCase } from '../../../utils/myFunctions';
import ReactTooltip from 'react-tooltip';
import Content from '../../../components/Content';


const accountSubtyps=[
    {
        category:"asset",
        type:"current",
        label:"Current Asset"
    },

    {
        category:"asset",
        type:"noncurrent",
        label:"Non-Current Asset"
    },

    {
        category:"asset",
        type:"intangible",
        label:"Intangible Asset"
    },

    {
        category:"liability",
        type:"current",
        label:"Current Liability"
    },

    {
        category:"liability",
        type:"noncurrent",
        label:"Non-Current Liability"
    },

    {
        category:"equity",
        type:"unrestricted",
        label:"Unrestricted Net Asset"
    },

    {
        category:"equity",
        type:"restricted",
        label:"Restricted Net Asset"
    },
    {
        category:"income",
        type:"restricted",
        label:"Restricted Income"
    },
    {
        category:"income",
        type:"restricted",
        label:"Restricted Income"
    },
    {
        category:"expense",
        type:"program",
        label:"program expense"
    },
    {
        category:"expense",
        type:"administrative",
        label:"Administrative Expense"
    },
    {
        category:"expense",
        type:"fundraising",
        label:"Fundraising Expense"
    },
    {
        category:"equity",
        type:"restricted",
        label:"Restricted Net Asset"
    },


    
]


const CreateSampleAccountSubtypeForm = ()=>{
  

   const [isSubmitting,setIsSubmitting] = useState(false)
   const [error,setError] = useState(null)
   const [validationError,setValidationError] = useState(null)
   const [isSuccess,setIsSuccess] = useState(false)
   const [isLoading,setIsLoading] = useState(false)
   const [accountTypes,setAccountTypes] = useState([])



   const user = JSON.parse(localStorage.getItem('user'))



   const [values, setValues] = useState({
    sub_type:'',
    vendor_id:'',
    account_type_id:'',
    description:'', 
              

});


   const handleChange = (e) =>{
    setIsSuccess(false)
    setError(null)
    setValues({
        ...values,
       
        [e.target.name]:e.target.value
    })
}
 



    const handleSubmit = async(e)=>{
        e.preventDefault();
        setError('')
        setValidationError('')
       
        if(!values.account_type_id){
            setValidationError('Select Account Type')
            return;
        }
        if(!values.sub_type){
            setValidationError('Enter Account sub type')
            return;
        }
        


        const data ={
            sub_type:values.sub_type,
            account_type_id:values.account_type_id,
            description:values.description,            
            user_id:user.id,
        }
        setIsSubmitting(true)
        const post = await makePostRequest('sample-account-sub-type',data)
        if (post && post.errorType) {
            setIsSubmitting(false);
            const errorMessage = ErrorHandler(post)
            setError(errorMessage)

        } else {
          
            setIsSubmitting(false);           
            setIsSuccess(true);

        }

    }
   

    
    useEffect(()=>{
        const getAccountTypes = async()=>{
            setError(null)
            setIsLoading(true)
            const res = await makeGetRequest('church-account-types');
            if (res && res.errorType) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res)
                setError(errorMessage)
    
            } else {
                  setAccountTypes(res.data)
                setError(null);
                setIsLoading(false);

                
        
            }

        }
            
          
        getAccountTypes();
       

    },[])

 
        return (
            <div className="container">
                <ReactTooltip type='info' multiline="true"/>
                 <div>
                 <form onSubmit={handleSubmit} className="form-group">
                <h3 className="text-center">Create System Account Subtype</h3>
                {isSubmitting && <CircularProgress/>}
                {isSuccess && <SuccessAlert/>}
                {error  && <Content type="error" message={"Failed "+ error}/>}
                {isSuccess &&<Content type="success" message="SAVED- "/>}
                {error !== null && !isSubmitting ? <p className="text text-warning">{error}</p>  : null}
             {validationError !== null &&  <p className='text text-danger'>{validationError}</p>}
             <div className="row jumbotron">
             <div className="col-md-6">
                    <div className=" form-group">
                    <span >Account Type</span>
                    {isLoading && <CircularProgress/>}               
                    <select id="account_type_id" className="form-control browser-default custom-select" name="account_type_id" onChange={handleChange} value={values.account_type_id} required>
                    <option value="">Select Account Type</option>
                   {
                   accountTypes.length === 0 ? <p>No Accounts types!</p> :
    
                   accountTypes.map((type)=>{
                    return  <option value={type.id} key={type.id}>{toTitleCase(type.type)}</option>
                   })
                   }
                  
    
                </select>
    
                </div>
    
                </div>
    
               
    
             </div>
             <div className="row">
                    <div className="col-md-6">
                    <div className=" form-group">
                    <span>Sub-Type Name </span>
                    <input type="text" className="form-control" name="sub_type"  placeholder="Enter Account sub type" onChange={handleChange} value={values.sub_type} required/>
    
                </div>
    
                    </div>

                    <div className="col-md-6">
                    <div className=" form-group">
                    <span>Description (optional)</span>
                    <input type="text" className="form-control" name="description"  placeholder="description" onChange={handleChange} value={values.description}/>
    
                </div>
    
                    </div>
                
                </div>
                               
                   <button className='btn btn-primary' onClick={handleSubmit}>{isSubmitting ? <CircularProgress/>:'Save'}</button>
    
                </form>
            
            </div>
            </div>
        
        )

    }
   


export default CreateSampleAccountSubtypeForm;