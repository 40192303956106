import React,{useState,useEffect} from 'react';
import { FaArrowAltCircleRight, FaUser } from 'react-icons/fa';

const DashboardBannerCard =({title,subtitle,value, icon:Icon})=>{
    return(
        <div className='dashboard-banner-card'>
            <div className='row'>
                    <div className='col-md-9'>
                    <h3> {title} </h3>
                    <p>{subtitle}</p>
                      
                    </div>
                    
                      <div className='col-md-3'>
                     {Icon && <Icon className='banner-icon'/>} 
                      
                      </div>
                  </div>

        </div>
    )

}
export default DashboardBannerCard;