import React, { useState, useEffect} from 'react';
import { Link, Navigate,useNavigate,useParams } from 'react-router-dom';
import './CreateComment.css';

const CreatePostComment =()=>{

    return(
        <div className="wrap">
  <h2>Please leave a comment:</h2>
  <div className="commentform">
    <div className="field small">
      <input id="name" type="text" placeholder="Your name"/>
      <label for="name">Your name</label>
    </div>
    <div className="field small">
      <input id="mail" type="text" placeholder="Your mail"/>
      <label for="mail">Mail</label>
    </div>
    <div className="field small">
      <input id="website" type="text" placeholder="Your website"/>
      <label for="website">Your website</label>
    </div>
    <div className="field large">
      <textarea id="comment" placeholder="Your comment"></textarea>
      <label for="comment">Your comment</label>
    </div>
  </div>
</div>
    )
}

export default CreatePostComment;