import {isMobile,isEmpty,isEmail,isNumber} from '../../../utils/formValidator';

export default function validateLoginForm(values){
    let errors = {};
    if(isEmpty(values.user_name.trim())){
        errors.user_name = 'Enter your User Name';
    }
   /* else if(!isEmail(values.user_name)){
        errors.user_name = 'Invalid User Name';
        }*/
    if(isEmpty(values.password.trim())){
        errors.password = 'Enter password';
    }
     

    
   
   return errors;
   
}