import React, { useState, useEffect } from 'react';
import { Link,  } from 'react-router-dom';
import validateCreateBlogPostCategoryForm from './validateCreateBlogPostCategoryForm';
import useCreateBlogPostCategory from './useCreateBlogPostCategory';
import { CircularProgress } from '@material-ui/core';

const CreateBlogPostCategory = () => {
    const { handleChange, values, handleSubmit,  isSubmitting, errors, serverErrorMessage, isSuccess } = useCreateBlogPostCategory(submitForm, validateCreateBlogPostCategoryForm);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [serverError,setServerError] = useState(null)

    function submitForm() {
        console.log('Is submitted being called', isSubmitted)
        setIsSubmitted(true);
        console.log('Is submitted  called', isSubmitted)
    }       
  
    return (
        <div className="container">
            {
            !isLoading ?
            <div className="container">
                <div className="form-box">
                   
                    <div className="body-form">
                        <h3>Create Category</h3>
                       
                        <form onSubmit={handleSubmit}>
                            {isSubmitting && <p className='text text-primary'>processing..</p>}
                            {serverErrorMessage !== null && !isSubmitting ? <p className='text text-danger'>{serverErrorMessage}</p> : null}
                            {isSuccess && <p className="text text-success">Success</p>}
                            
                           
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Category</span>
                                </div>
                                <input type="text" className="form-control has-error" id="focusedInput" placeholder="Enter Category" name="category" value={values.category} onChange={handleChange} /><br />
                                {errors.category && <p className=" text text-danger .error-message">{errors.category}</p>}

                            </div>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Description</span>
                                </div>
                                <input type="text" className="form-control has-error" id="focusedInput" placeholder="Description" name="description" value={values.description} onChange={handleChange} /><br />
                                {errors.description && <p className=" text text-danger .error-message">{errors.description}</p>}
                            </div>
                            
                            {
                                isSubmitting ?
                                <button type="submit" className="elevator-btn btn-block login-btn" disabled="disabled"><CircularProgress/></button>
                                :
                                <button type="submit" className="btn btn-success">Save</button>

                            }

                            
                        </form>

                    </div>
                </div>
            </div>
            :
            <div>
                {isLoading && <p className='text text-success'><CircularProgress color='secondary'/></p>}
                {serverError !== null && !isLoading ? <p className='text text-danger'>{serverError}</p> : null}

            </div>
}


        </div>

    );
}




export default CreateBlogPostCategory;