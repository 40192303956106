import React, { useState, useEffect} from 'react';
import { Link, Navigate,useParams } from 'react-router-dom';
import {makeGetRequest} from '../../../services/httpHelper'
import ErrorHandler from '../../../services/ErrorHandler';
import { LoadingState,ErrorState,BackgrounLoadingState } from '../../../components/ui/UiState';
import { CircularProgress } from '@mui/material';
import { toTitleCase } from '../../../utils/myFunctions';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CurrencyFormat from 'react-currency-format';

const ChartOfAccounts = ()=>{
    const [accounts, setAccounts] = useState(null);
    const [accountGroups, setAccountGroups] = useState(null);
    const [filteredAccounts, setFilteredAccounts] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false);
    const [serverErrorMessage, setServerErrorMessage] = useState(null);   


    const user = JSON.parse(localStorage.getItem('user'));
    const church = JSON.parse(localStorage.getItem('church'));
      

    async function getAccounts() {
        setIsLoading(true)
        // You can await here
        const res = await makeGetRequest('church-accounts/'+church.id);
        if (ResizeObserver && ResizeObserver.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setServerErrorMessage(errorMessage)

        } else {
            setAccounts(res.data)                  
            setIsLoading(false);
            setIsSuccess(true);
            
        }       

    }

    const filterByGroups= (e)=>{
        const v = e.target.value;
        
        setFilteredAccounts([]) 
        
        if(!v.length){
            setFilteredAccounts([]) 
            return;
        }
        
       const results = accounts.filter(account=>account.account_group_id === v)
       setFilteredAccounts(results);
       console.log('result',results)

    }
    const filterByTypes= (e)=>{
        const v = e.target.value;
        setFilteredAccounts([]) 
        if(!v.length){
            setFilteredAccounts([]) 
            return;
        }
       
       const results = accounts.filter(type=>type.type.type === v)
       setFilteredAccounts(results);
        
    }
    const getAccountGroups = async()=>{
        setServerErrorMessage(null)
        setIsLoading(true)
        const res = await makeGetRequest('church-account-groups/'+church.id);
        if (res && res.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setServerErrorMessage(errorMessage)

        } else {
              setAccountGroups(res.data)
            setServerErrorMessage(null);
            setIsLoading(false);
           
            
    
        }

    }
    useEffect(()=>{
        getAccounts();
        getAccountGroups();

    },[])

    /*if(isLoading){
        return <CircularProgress/>
    }
    if(serverErrorMessage !== null){
        return <ErrorState eror={serverErrorMessage}/>
    }*/

    return(
        <div className='container-fluid'>
            {serverErrorMessage !== null && <p>Something wrong happened: <span className="text text-danger">{serverErrorMessage}</span></p>}
             {isLoading && <CircularProgress color="primary"/>}
               {
                            isSuccess ?
                                <div className="card">
                                    
                                            <h3 className="text-center"> Chart of Accounts</h3>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    {
                                                        accountGroups !==null &&
                                                        <select name="groups" onChange={filterByGroups}>
                                                            <option value="">Filter By Account Group</option>
                                                            {
                                                                accountGroups.map((group)=>{
                                                                    return <option key={group.id} value={group.id}>{toTitleCase(group.account_group)}</option>
                                                                })
                                                            }
                                                        </select>
                                                    }
                                                    </div>
                                                    <div className="col-md-4">
                                                    
                                                        <select name="groups" onChange={filterByTypes}>
                                                            <option value="">Filter By Account Types</option>
                                                            <option value="asset">Assets</option>
                                                            <option value="equity">Equity</option>
                                                            <option value="expense">Expenses</option>
                                                            <option value="liability">Liabilities</option>
                                                          
                                                        </select>
                                                    
                                                    </div>
                                                </div>
                                            {
                                                accounts !==null ?
                                                <table className="table table-responsive table-striped">
                                                <thead>
                                                    <tr style={{fontSize:"15px"}}>
                                                        
                                                       <th>Account Group</th>
                                                        <th>Account Name</th>
                                                        <th>Account Number</th>
                                                        <th>Account Type</th>                                                        
                                                        <th>Balance</th>                                                        
                                                        <th>&nbsp;</th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        filteredAccounts && filteredAccounts.length ?
                                                        filteredAccounts.map((account)=>{
                                                                                                                        
                                                            return <tr key={account.id} style={{fontSize:"15px"}}>
                                                                <td>{account.group!==null && toTitleCase(account.group.account_group)}</td>
                                                                <td>{toTitleCase(account.account_name)}</td>
                                                                <td>{account.account_number}</td>
                                                                <td>{toTitleCase(account.type.type)}</td>                                                        
                                                                <td>{account.balance !== null && <CurrencyFormat value={account.balance} displayType={'text'} thousandSeparator={true} prefix={church.currency_symbol} />}</td>                                                                
                                                                <td><AccountBoxIcon/></td>
                                                                <td><DeleteForeverIcon/></td>


                                                            </tr>

                                                        })

                                                       :
                                                     accounts &&  accounts.map((account)=>{
                                                                                                                        
                                                        return <tr key={account.id} style={{fontSize:"15px"}}>
                                                        <td>{account.group !== null && toTitleCase(account.group.account_group)}</td>
                                                            <td>{toTitleCase(account.account_name)}</td>
                                                            <td>{account.account_number}</td>
                                                            <td>{toTitleCase(account.type.type)}</td>                                                    
                                                            <td>{account.balance !== null && <CurrencyFormat value={account.balance} displayType={'text'} thousandSeparator={true} prefix={church.currency_symbol} />}</td>                                                                
                                                            <td><AccountBoxIcon/></td>
                                                            <td><DeleteForeverIcon/></td>


                                                        </tr>

                                                    })
                                                    }

                                                    </tbody>
                                                   
                                                
                                            </table>
                                            :
                                            <p>No Accounts <span><Link to="/create-account" className="btn btn-info">Create Account</Link></span></p>
                                            }
                                           
                                            

                                      

                                </div>
                                :
                                <div>
                                    {serverErrorMessage && <p className="text text-danger">{serverErrorMessage}</p>}



                                </div>
                        }

        </div>
    )
}
export default ChartOfAccounts;