import React from 'react';
import { Modal } from 'react-bootstrap';

const CustomModal = ({ show, handleClose, header, body, size = 'md', footer }) => {
  return (
    <Modal show={show} onHide={handleClose} size={size} centered>
      <Modal.Header closeButton>
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      {/*<Modal.Footer>{footer}</Modal.Footer>*/}
    </Modal>
  );
};

export default CustomModal;