import React, { useState,useEffect } from 'react';
import { Button ,Modal,Container} from 'react-bootstrap';
import { makeDeleteRequest, makeGetRequest } from '../../services/httpHelper';
import ErrorHandler from '../../services/ErrorHandler';
import { CircularProgress } from '@mui/material';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { IMAGE_BASE_URL } from '../../constants/paths';
import CreatePolicyForm from './CreatePolicyForm';
import EditPolicyForm from './EditPolicyForm';
import { Link } from 'react-router-dom';

const PolicyPage = () => {
  const [policies, setPolicies] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLoading,setIsLoading] = useState(false);
  const [serverError,setServerError] = useState(null);
  const [error,setError] = useState(null);
  const [showPrivacyPolicy,setShowPrivacyPolicy] = useState(false);
   const[isSubmitting, setIsSubmitting]=useState(false)

   const showPolicy =()=>{

   }

  // Function to open create sermon modal
  const handleOpenCreateModal = () => {
    setShowCreateModal(true);
  };

  // Function to close create sermon modal
  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
  };

  // Function to open edit sermon modal
  const handleOpenEditModal = (data) => {
    setSelectedItem(data);
    setShowEditModal(true);
  };

  // Function to close edit sermon modal
  const handleCloseEditModal = () => {
    setSelectedItem(null);
    setShowEditModal(false);
  };

  // Function to handle delete sermon
  const handleDelete = async(testimonyId) => {

    const res = await makeDeleteRequest(`testimony/${testimonyId}`);
    if(res && res.errorType){
      setIsSubmitting(false)
      setError(res.errorMessage);
  
    }else{
      const updatepolicies = policies.filter(value => value.id !== testimonyId);
      setPolicies(updatepolicies);
      
      setIsSubmitting(false);
    }
   
 
  };



  const church = JSON.parse(localStorage.getItem('church'))

  const getPolicies = async()=>{
    setIsLoading(true);
    const res = await makeGetRequest(`policies`);

    if (res && res.errorType) {
        setIsLoading(false);
        const errorMessage = ErrorHandler(res)
        setError(errorMessage)

    } else {
      const results = await res.data;
        setPolicies(results)   
        setIsLoading(false);
        setError(null)


    }
   
}
  

  useEffect(()=>{
    getPolicies();

  },[])

  return (
    <Container className='container-fluid'>
      <Button className="mb-3 ml-2 add-btn" variant="primary" onClick={handleOpenCreateModal}  style={{marginLeft:'70%'}}>
        Create Policy
      </Button>
   
     {/* sermon List */}
      {policies.length === 0 ? (
        <p>No policies found. <a href="#" onClick={handleOpenCreateModal}>CREATE  POLICY</a></p>
      ) : (
        <div style={{marginTop:'0px'}}>
          <h2 className='text-center'>COMPANY POLICIES</h2>
          {isSubmitting && <p className=''>Deleting...</p>}
          {serverError !== null && <p className=''>{serverError}.</p>}
          {isLoading && <CircularProgress/>}

          <div>
        
    <div className='row'>
      {
        policies.map((policy)=>{
          return <div key={policy.id}>
            <p ><Link onClick={()=>handleOpenEditModal(policy)}>{policy.name}</Link></p>
          </div>
         
        })
      }
    </div>

          </div>
          
        </div>
      )}

      <Modal show={showCreateModal} onHide={handleCloseCreateModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Create Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
     
          <CreatePolicyForm/>
        </Modal.Body>
      </Modal>

      {/* Edit sermon Modal */}
      <Modal show={showEditModal} onHide={handleCloseEditModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Edit Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditPolicyForm policy={selectedItem}/>
          {/* Pass selectedItem and handleEditpoliciesubmit as props */}
        </Modal.Body>
      </Modal>



      {
        showPolicy==='privacy' &&
        <div className='container'>
            <h1>PRIVACY POLICY</h1>

        </div>
      }

{
        showPolicy==='terms' &&
        <div className='container'>
            <h1>TERMS AND CONDITIONS</h1>
            
        </div>
      }
    </Container>
  );
};

export default PolicyPage;
