import React, { useState,useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { FaCheck, FaWindowClose } from 'react-icons/fa';
import ErrorHandler from '../../services/ErrorHandler';
import { makeGetRequest } from '../../services/httpHelper';
import { useNavigate } from 'react-router-dom';

const SubscriptionFeatureCard = ({data,showButton=true, showExcludedFeatures=false}) => {
  const [email, setEmail] = useState('');
  const [plan, setPlan] = useState('free');
  const [isLoading,setIsLoading] = useState(false);
  const [serverError,setServerError] = useState(null);
  const [features,setFeatures]=useState(null);
  const [allFeatures,setAllFeatures]=useState(null);

  const navigate =useNavigate();


  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePlanChange = (e) => {
    setPlan(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle subscription logic here, e.g., sending data to your server
    console.log(`Subscribed with email: ${email} and plan: ${plan}`);
  };

  const getPlan = async()=>{
    setIsLoading(true);
    const res = await makeGetRequest('subscription-plan/'+data.plan.id);
    if (res && res.errorType) {
        setIsLoading(false);
        const errorMessage = ErrorHandler(res)
        setServerError(errorMessage)
  
    } else {
        const result = await res.data;
        let features =[];
        let currentPlan = null;
        result.forEach((result)=>{
            features.push(result);        
            
  
        })
        setFeatures(features)        
        setIsLoading(false);
        setServerError(null)
    
  
        
  
    }
   
  }
  const getFeatures = async()=>{
    try{
        setIsLoading(true)
        const res = await makeGetRequest('features')
        if(res && res.errorType){
            setIsLoading(false)
            const errorMessage = ErrorHandler(res)
            setServerError(errorMessage)
            
        }else{
            setServerError(null)
            setAllFeatures(res.data);
            setIsLoading(false);
            console.log(allFeatures)

        }
  
    }catch(error){
        console.log(error)
        setIsLoading(false)
    }
   
  
  }

  const isAssignedFeature =(id)=>{
    const planFeatures = data.features;
    if(planFeatures.includes(id)){
      return true;
    }else{
      return false;
    }
  }
  
    useEffect(()=>{
    if(data.features !== null){
      getPlan();
      //getFeatures();
    }
       
      
    
  
    },[])

  return (
    <Container fluid className="subscription-page">
      <Row>
        <Col md={{ span: 12,  }}>
          <Card className="subscription-card">
            <Card.Header>
            <Card.Title className="text-center">{data.plan.plan}</Card.Title>
            </Card.Header>
            <Card.Body className='mx-auto'>
              <p style={{fontSize:"35px",fontWeight:"bold"}}>${data.plan.monthly_fee} <span style={{fontSize:"16px", fontWeight:"none"}}>Per Month</span></p>
              <p>{data.plan.description}</p>
              <h4 style={{fontFamily:"times new roman", fontWeight:"bold"}}>Features</h4>
              {
                data.features !== null &&
                data.features.map((feature)=>{
                  return <p key={feature.id}><span><FaCheck className='text text-success'/></span> {feature.feature}</p>
                })
              }

{
           features !== null &&
                features.map((feature)=>{
                  return <p key={feature.id}><span><FaCheck className='text text-success'/></span> {feature.feature}</p>
                })
              }
             {
              showExcludedFeatures &&   <p><span><FaWindowClose className='text text-danger'/></span> SMS Notification</p>
             }

{
              showExcludedFeatures &&   <p><span><FaWindowClose className='text text-danger'/></span> Setup Fee</p>
             }
            
             
            </Card.Body>
            <Card.Footer>
              {
                showButton &&
                <Button variant="primary" type="submit" onClick={()=>navigate(`/manage-plan-options/${data.plan.id}`)}>
                Manage Plan
              </Button>
              }
         
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SubscriptionFeatureCard;
