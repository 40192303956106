import React, { useState,useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { makeGetRequest, makePostRequest, makePutRequest } from '../../services/httpHelper';
import ErrorHandler from '../../services/ErrorHandler';
import './ReorderFaqs.css';
import { CircularProgress } from '@mui/material';
import { Navigate } from 'react-router-dom';

const ReorderFaqs = () => {
  const [updatedFaqs, setUpdatedFaqs] = useState([]);
  const [serverError,setServerError] = useState(null);
  const [isLoading,setIsLoading] = useState(false);
  const [isSubmitting,setIsubmitting] = useState(false);
  const [submitted,setSubmitted] = useState(false);

  
  const dt = new Date();
  const now = dt.getTime();



  const handleDragEnd = (result) => {
    if (!result.destination) return; // Item dropped outside the list
    const reorderedFaqs = Array.from(updatedFaqs);
    const [removed] = reorderedFaqs.splice(result.source.index, 1); // Remove dragged item
    reorderedFaqs.splice(result.destination.index, 0, removed); // Insert dragged item at new position
    setUpdatedFaqs(reorderedFaqs);
  };


  const handleReorder =async()=>{
    setIsubmitting(true)
    const res = await makePutRequest('re-order-faqs',updatedFaqs);
    if (res && res.errorType) {
        setServerError('Something went wrong')
        setIsubmitting(false)

    } else {

  
        setServerError(null)
        setSubmitted(true)


}
}

  

  useEffect(()=>{
    const getFaqs = async()=>{
        setIsLoading(true);
        const res = await makeGetRequest('admin-faqs');
        if (res && res.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setServerError(errorMessage)

        } else {
            setUpdatedFaqs(res.data)        
            setIsLoading(false);
            setServerError(null)

        }
       
    }
    getFaqs();
    },[])
  return (
    <div className='container'>
        <h3 className='text text-center'>Drag to rearrange to order</h3>
        {isSubmitting && <CircularProgress/>}
        {submitted && <Navigate to="/faqs"/>}
        {serverError && <p className='text text-danger'>{serverError}</p>}
        <div className='drag-wrapper'>
        <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={now}>
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              {updatedFaqs.map((faq, index) => (
                  <Draggable key={faq.id} draggableId={`faq-${faq.id}`} index={index}>
                  {(provided) => (
                    <li
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <div className='faq-wrapper'>
                      <div className='faq-q'>{index+1}. {faq.question}</div>
                      <div>{faq.answer}</div>
                      </div>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>

        </div>
     
      <button onClick={handleReorder} className='reorder-btn btn btn-primary'>Reorder Now</button>
    </div>
  );
};

export default ReorderFaqs;
