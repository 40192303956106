import React, { useState, useEffect } from 'react';
import { Link,  } from 'react-router-dom';
import validateCreateFaqForm from './validateCreateFaqForm';
import useCreateFaq from './useCreateFaq';
import { CircularProgress } from '@material-ui/core';
import ErrorHandler from '../../services/ErrorHandler';
import { makeGetRequest } from '../../services/httpHelper'


const CreateFaq = () => {
    const { handleChange, values, handleSubmit,  isSubmitting, errors, serverErrorMessage, isSuccess } = useCreateFaq(submitForm, validateCreateFaqForm);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [fagCategories, setFaqCategories] = useState(null);    
    const [isLoading,setIsLoading] = useState(false);
    const [serverError,setServerError] = useState(null)


    function submitForm() {
        setIsSubmitted(true);
    }
   

    useEffect(()=>{
    const geFaqCategories = async()=>{
        setIsLoading(true);
        const res = await makeGetRequest('faq-categories');
        if (res && res.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setServerError(errorMessage)

        } else {
            console.log(res.data)
            setFaqCategories(res.data)

            setIsLoading(false);

        }
       
    }
    geFaqCategories();
    },[])
   
  
    return (
        <div className="container">
            {
            !isLoading ?
            <div className="container">
                <div className="form-box">
                  
                    <div className="body-form">
                        <h3 className='text text-center'>Create FAQ</h3>
                        {isSuccess
                            && <p>Redirect to Dashaborad</p>}
                        {isSubmitted}
                        <form onSubmit={handleSubmit}>
                            {isSubmitting && <p className='text text-warning'>Processing..</p>}
                            {serverErrorMessage !== null && !isSubmitting ? <p className='text text-danger'>{serverErrorMessage}</p> : null}
                            {isSuccess && <p className="text text-success">Success</p>}
                            
                        
                            <div className="input-group mb-3">
                           
                           
                            <input type="text" className="form-control has-error"  placeholder="Enter Question" name="question" value={values.question} onChange={handleChange} /><br />
                            {errors.question && <p className=" text text-danger .error-message">{errors.question}</p>}
                        </div>
                            <div className="input-group mb-3">
                            
                                <textarea name="answer" cols="30" rows="4" placeholder="Enter Answer" value={values.answer} onChange={handleChange}>

                                </textarea>
                                {errors.answer && <p className=" text text-danger .error-message">{errors.answer}</p>}
                            </div>
                            
                            {
                                isSubmitting ?
                                <button type="submit" className="elevator-btn btn-block login-btn" disabled="disabled"><CircularProgress/></button>
                                :
                                <button type="submit" className="btn btn-success">Save</button>

                            }

                           
                        </form>

                    </div>
                </div>
            </div>
            :
            <div>
                {isLoading && <p className='text text-success'><CircularProgress color='secondary'/></p>}
                {serverError !== null && !isLoading ? <p className='text text-danger'>{serverError}</p> : null}

            </div>
}


        </div>

    );
}




export default CreateFaq;