import React, { useState } from 'react';
import useVerifyChurchUserMobileNmber from './useVerifyChurchUserMobileNumber';
import validateVerifyMobileNmber from '../../signup/validateVerifyMobileNumber';
import ThankYou from '../../../../utils/ThankYou';
import { CircularProgress } from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom'

const VerifyChurchUserMobileNumber = () => {
    const {state} = useLocation();
    const { handleChange, values, handleSubmit, errors,isSubmitting,serverError,isSuccess,serverErrorMessage } = useVerifyChurchUserMobileNmber(submitForm, validateVerifyMobileNmber,state);
    const [isSubmitted, setIsSubmitted] = useState(false);
    function submitForm() {
        setIsSubmitted(true);
    }

    const navigate = useNavigate();
    
   
    function obsecurePhoneNumber(phone){
        const str1 = phone.substr(0,7);
        const str2 = phone.substr(-3,3);
        const obsecuredPhone = str1+"*****"+str2;
        return obsecuredPhone;


    }

    function userRegistered(){
        const userId = localStorage.getItem('newUserId');
        console.log('New userID', userId)
        navigate(`/church-user-profile/${userId}`);
    }
    
    return (
        <div className="container">
            {isSuccess ? userRegistered() 
                : <div className="container height-100 d-flex justify-content-center align-items-center">
                    <div className="position-relative">
                        <form onSubmit={handleSubmit}>
                            <div className="card p-2 text-center">
                            {isSubmitting  ? <p className='text text-success'>Processing..</p>:null}
        {serverErrorMessage !== null && !isSubmitting ? <p className='text text-danger'>{serverErrorMessage}</p> : null}
                                <h6>Please enter verification code<br /> send to the user to verify the mobile number</h6>
                                <div> <span>A code has been sent to</span> <small>{obsecurePhoneNumber(state)}</small> </div>
                                {serverError ?<p className="text text-danger">{serverError}</p>:null}
                                <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2">
                                    <input className="m-2 text-center form-control rounded" type="text" id="input-1" maxLength="1" name="first" value={values.first} onChange={handleChange} />
                                    <input className="m-2 text-center form-control rounded" type="text" id="input-2" maxLength="1" name="second" value={values.second} onChange={handleChange} />
                                    <input className="m-2 text-center form-control rounded" type="text" id="input-3" maxLength="1" name="third" value={values.third} onChange={handleChange} />
                                    <input className="m-2 text-center form-control rounded" type="text" id="input-4" maxLength="1" name="fourth" value={values.fourth} onChange={handleChange} />
                                    <input className="m-2 text-center form-control rounded" type="text" id="input-5" maxLength="1" name="fifth" value={values.fifth} onChange={handleChange} />
                                    <input className="m-2 text-center form-control rounded" type="text" id="input-6" maxLength="1" name="sixth" value={values.sixth} onChange={handleChange} />
                                </div>
                                {errors.otp && <p className=" text text-danger">{errors.otp}</p>}

                                <div className="mt-4">
                                    <button className="btn btn-sm" type="submit">{isSubmitting ? <CircularProgress color="inherit"/> : "Verify"}</button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            }

        </div>
    );
}





export default VerifyChurchUserMobileNumber;