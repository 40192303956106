import React, { useState,useEffect } from 'react';
import {makeGetRequest,makePutRequest} from '../../services/httpHelper'
import ErrorHandler from '../../services/ErrorHandler'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CircularProgress } from '@material-ui/core';


const Faqs = () => {
    const [faqs,setFaqs] = useState(null);
    const [isLoading,setIsLoading] = useState(false);
    const [serverError,setServerError] = useState(null);
    const [rated,setRated] = useState(null)
    const [ratingServerError,setRatingServerError] = useState(null)
    const [isPostiveRating,setIsPostiveRating] = useState(false);
    const [isNegativeRating,setIsNegativeRating] = useState(false);




    const handlePostiveRating = async(e)=>{
        setIsPostiveRating(true)
        const id = e.target.value;
        const data = {
            id:id,
            rating:'yes'
        }
        const res = await makePutRequest('rate-faq-answer',data);
        if (res && res.errorType) {
            setIsPostiveRating(false);
            setRatingServerError({error:'Sorry! something went wrong, try again later',id:id})

        } else {
            setRated(id);
            setRatingServerError(null)
            setIsPostiveRating(false)

        }
    }

    const handleNegativeRating = async(e)=>{
        setIsNegativeRating(true)
        const id = e.target.value;
        const data = {
            id:id,
            rating:'no'
        }
        const res = await makePutRequest('rate-faq-answer',data);
        if (res && res.errorType) {
            isNegativeRating(false);
            setRatingServerError({error:'Sorry! something went wrong, try again later',id:id})

        } else {
            setRated(id);
            setRatingServerError(null)
            setIsNegativeRating(false)

        }
    }

    const getFaqs = async()=>{
        setIsLoading(true);
        const res = await makeGetRequest('faqs');
        if (res && res.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setServerError(errorMessage)

        } else {
            setFaqs(res.data)        
            setIsLoading(false);
            setServerError(null)

        }
       
    }

    

    useEffect(()=>{
       
        getFaqs();
        },[])
  
        return (
            <>
            {
                 faqs !== null ?
                 <div className="container">
                 <br/><br/>
                 <h3>Frequently Asked Questions</h3>
                 <br/>
                 {
                                                     faqs.map((faq) => {
                                                         return (
                                                             <Accordion key={faq.id} className="faq">
                                                                 <AccordionSummary
                                                                     expandIcon={<ExpandMoreIcon />}
                                                                     aria-controls="panel1a-content"
                                                                     id="panel1a-header"
                                                                 >
                                                                     <Typography className="faq-title">{faq.question}</Typography>
                                                                 </AccordionSummary>
                                                                 <AccordionDetails>
                                                                     <Typography>
                                                                         <div className='row'>
                                                                             <div className="col-md-8 ">
                                                                                 {faq.answer}
                                                                             </div>
                                                                             <div className="col-md-2">
                                                                                {ratingServerError !== null && ratingServerError.id===faq.id ? <p className="text text-warning">{ratingServerError.error}</p> : null}
                                                                                {rated !== null && rated===faq.id ? <p className="text text-success">Success</p> : null}

                                                                             <button type="button" className="btn btn-success btn-sm" value={faq.id}  onClick={handlePostiveRating}>{isPostiveRating ? <CircularProgress color="inherit"/> :"Helpful"}</button>                                                                     
 
                                                                             </div>
                                                                             <div className="col-md-2">
                                                                             <button type="button" className="btn btn-danger btn-sm" value={faq.id}  onClick={handleNegativeRating}>{isNegativeRating ? <CircularProgress color="inherit"/> :"Not Helpful"} </button>                                                                     
 
                                                                             </div>
                                                                             
 
                                                                         </div>
 
 
                                                                     </Typography>
                                                                 </AccordionDetails>
                                                             </Accordion>
                                                         )
                                                     })
                                                 }
                 
 
             </div>
                 :
                 <div>
                 {isLoading && serverError === null && <CircularProgress />}
                 {serverError && <p className="text text-danger">{serverError}</p>}

             </div>
            }
            </>
           


        );
   

}
export default Faqs;