import React from 'react';
import {Link} from 'react-router-dom'
import './UnauthorizedAccess.css';

const UnauthorizedAccess = ()=>{
    return(
        <div className="page-container">
             <h2 className="message">Stop right there!</h2>
             <div className="row">
              <div className="col-md-6" style={{marginLeft:'50%'}}>
              <div className="message2">
        Sorry, but you don't have permission to access this page
      
<Link to="/" className="btn btn-info text text-center">Go Back To Dashboard</Link>
          </div>

              </div>

              <div className="col-md-16">
              <div className="container">
          <div className="neon">403</div>
          <div className="door-frame">
            <div className="door">
              <div className="rectangle">
            </div>
              <div className="handle">
                </div>
              <div className="window">
                <div className="eye">
                </div>
                <div className="eye eye2">
                </div>
                <div className="leaf">
                </div> 
              </div>
            </div>  
          </div>
        </div>

</div>
              </div>
       
        
            
        </div>
       
      
    )

}
export default UnauthorizedAccess;