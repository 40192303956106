import {useState,useEffect} from 'react';
import {makeGetRequest, makePostRequest} from '../../services/httpHelper';


const useCreateSubscriptionPlan = (callback,validateCreateSubscriptionPlanForm,selectedFeatures) =>{
    const [values, setValues] = useState({
        plan:'',
        description:'',
        monthly_fee:'',
        annual_fee:'',
        product_id:'PROD-8RN70728YH730900D'
       
        

    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState();
    const [isSuccess, setIsSuccess] = useState(false);
    const [features, setFeatures] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [products,setProducts] = useState(null);



    const handleChange = (e) =>{
      
        setValues({
            ...values,
           
            [e.target.name]:e.target.value
        })
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        setIsSubmitting(true)
        setIsSuccess(false)
        setErrors(validateCreateSubscriptionPlanForm(values));

        const user = JSON.parse(localStorage.getItem('user'))
        const data ={
            plan:values.plan,
            description:values.description,
            monthly_fee:values.monthly_fee,
            annual_fee:values.annual_fee,
            product_id:values.product_id,
            features:selectedFeatures,
            created_by:user.first_name+' '+user.last_name,
            user_id:user.id

        }

        console.log('Plan',data);
        const res = await makePostRequest('subscription-plan',data);
        if(res && res.errorType){
            setIsSubmitting(false);
          if(res.errorType === 'validation'){
            const validationErrors = res.validationErrors.errors;
            let backend_errors = [];  
            validationErrors.map((error,index)=>{
              backend_errors.push(error.msg)
              return initial_backend_errors.push(error.msg)           
    
            })
            const newServerErrors = error.concat(backend_errors);
            setError(newServerErrors);
  
          }
          else if(res.errorType === 'authentication'){
            setError(res.errorMessage);
           
          }
         
       else if(res.errorType==='authorization'){
        setError(res.errorMessage);        
          
        }
        else if(res.errorType === 'badrequest'){
          setError(res.errorMessage);
        }else{
          setError(res.errorMessage);
        }
          }else{     

          

            setIsSubmitting(false);
            setIsSuccess(true);
            setIsSubmitted(true);
          }
            
        
    }

    const getFeatures = async()=>{
        try{
            setIsLoading(true)
            const res = await makeGetRequest('features')
            if(res && res.errorType){
                setIsLoading(false)
                const errorMessage = ErrorHandler(res)
                setError(errorMessage)
                
            }else{
                setError(null)
                setFeatures(res.data);
                setIsLoading(false);
            }

        }catch(error){
            console.log(error)
            setIsLoading(false)
        }
       

    }

    const getProducts = async()=>{
      try{
          setIsLoading(true)
          const res = await makeGetRequest('local-products')
          if(res && res.errorType){
              setIsLoading(false)
              const errorMessage = ErrorHandler(res)
              setError(errorMessage)
              
          }else{
              setError(null)
              setProducts(res.data);
              setIsLoading(false);
          }

      }catch(error){
          console.log(error)
          setIsLoading(false)
      }
     

  }
    useEffect(()=>{
        getFeatures();
        getProducts();
        if(Object.keys(errors).length===0 && isSubmitted){
           callback();
        }
    },[errors]);
    return {handleChange,values,handleSubmit,errors,error,isSubmitting,isSuccess,features,isLoading,products};
}
export default useCreateSubscriptionPlan;