import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing"
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import AuthService from './services/AuthService';
import jwt_decode from "jwt-decode";
import {BrowserRouter as Router, Routes, Route,Link} from 'react-router-dom';

Sentry.init({
  dsn: "https://565f10e597a5460199c973d317ccb1bb@o4503947002249216.ingest.sentry.io/4503947009196032",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


axios.interceptors.request.use(
  request=>{
    console.log('Reaquest interceptor',request.url)
    if(!request.url.includes('login')){
      const token = sessionStorage.getItem('token'); 
      if(token){
        const decodedToken = jwt_decode(token);
        if(decodedToken.exp * 1000 < Date.now()){
          
          const logged_out = logoutUser();
        } else{
          request.headers['Authorization']= `Bearer ${token}`;
        }
      }else{
        const logged_out = logoutUser();
      }
      

    }
    return request;
  }
);
const logoutUser= async()=>{
  return await  AuthService.logout(); 
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode> 
    <Router>
    <App />  
      </Router>   
    
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
