import React, { useState,useEffect } from 'react';
import  '../contact/Contact.css';
import { makeGetRequest} from '../../services/httpHelper';
import  ErrorHandler  from '../../services/ErrorHandler';
import { Container, Row, Col, Card, Spinner } from 'react-bootstrap';
import { formatDate, toTitleCase } from '../../utils/myFunctions';
import { useParams } from 'react-router-dom';
import { IMAGE_BASE_URL } from '../../constants/paths';

    


const AdminChurchProfile = () => {
  const [churchData, setChurchData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const church = JSON.parse(localStorage.getItem('church'));

  const{id} = useParams();

  useEffect(() => {
    

    const fetchChurchData = async()=>{
        setLoading(true);
        const res = await makeGetRequest('church/'+id);
        if (res && res.errorType) {
            setLoading(false);
            const errorMessage = ErrorHandler(res)
            setError(errorMessage)

        } else {
            setChurchData(res.data);           
            setLoading(false);
            console.log('church profile',churchData)

        }
       
    }
    fetchChurchData();
  }, []);

  if (loading) {
    return (
      <Container className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return <div className="text-center">Error: {error}</div>;
  }
  return (
    <Container className="organization-profile">
      <Card>
        <Card.Body>
          <Row>
            <Col xs={12} className="text-center">
              <img src={IMAGE_BASE_URL+churchData.logo} alt="Church Logo" />
              <h3>{churchData.church_name}</h3>
              <p>{churchData.description}</p>
              <p><strong>Church Number:</strong> {churchData.church_number}</p>
              <p><strong>Language:</strong> {churchData.default_language}</p>
              <p><strong>Branches:</strong> {churchData.branches.length !==0 ? churchData.branches.length :0}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={4}>
              <p><strong>Address:</strong> {churchData.address}</p>
              <p><strong>Postal Code:</strong> {churchData.postal_code}</p>
              <p><strong>Location:</strong>{churchData.country} ({churchData.location})</p>
              <p><strong>Phone:</strong> {churchData.phone}</p>
              <p><strong>Email:</strong> {churchData.email}</p>
              <p><strong>Website:</strong> <a href={churchData.website}>{churchData.website}</a></p>

            </Col>

            <Col xs={4} md={4}>
             
            </Col>

            <Col xs={4} md={4}>
              <p><strong>Fiscal Year:</strong> {toTitleCase(churchData.fiscal_year)}</p>
              <p><strong>Subscription Type:</strong>{toTitleCase(churchData.subscription_type)} ({churchData.subscription_status})</p>
              <p><strong>Subscription Exp Date:</strong> {formatDate(churchData.next_payment_date,churchData.default_language)}</p>
              <p><strong>Currency:</strong> {toTitleCase(churchData.currency)}({churchData.currency_code})</p>
            </Col>
          </Row>
         
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AdminChurchProfile;
