import {isMobile,isEmpty,isEmail,isNumber} from '../../../utils/formValidator';

export default function validateAddUserForm(values){
    let errors = {};
    if(isEmpty(values.first_name.trim())){
        errors.first_name = 'Enter First Name';
    }
    if(isEmpty(values.last_name.trim())){
        errors.last_name = 'Enter Last Name';
    }
    if(isEmpty(values.country.trim())){
        errors.country = 'Select country';
    }

    if(isEmpty(values.role)){
        errors.role = 'Assign role';
    }
  
    if(isEmpty(values.mobile_number.trim())){
        errors.mobile_number = 'Enter mobile Number';
    }else if(!isMobile(values.mobile_number)){
        errors.mobile_number = 'This not a valid mobile number';
    }

    if(isEmpty(values.email.trim())){
        errors.email = 'Enter email';
    }
    else if(!isEmail(values.email.trim())){
        errors.email = 'Invalid Email';
    }
    
   
   return errors;
   
}