import React, { useState, useEffect} from 'react';
import { Link, Navigate,useParams } from 'react-router-dom';
import {makeGetRequest} from '../../../services/httpHelper'
import ErrorHandler from '../../../services/ErrorHandler';
import { CircularProgress } from '@mui/material';
import { toTitleCase } from '../../../utils/myFunctions';
import ToastAlert from '../../../components/ui/ToastAlert'


const AccountTypes = ()=>{
    const [accountTypes, setAccountTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false);
    const [serverErrorMessage, setServerErrorMessage] = useState(null);   
    const user = JSON.parse(localStorage.getItem('user'));

    async function getaccountTypes() {
        
        setIsLoading(true)
        // You can await here
        const res = await makeGetRequest('church-account-types');
        if (ResizeObserver && ResizeObserver.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setServerErrorMessage(errorMessage)

        } else {
            setAccountTypes(res.data)                  
            setIsLoading(false);
            setIsSuccess(true);
        }       

    }

    
    useEffect(()=>{
        getaccountTypes();

    },[])

    

    return(
        <div className='container'>
            {serverErrorMessage !== null &&  <ToastAlert type="error" message={serverErrorMessage} />}
             {isLoading && <CircularProgress color="primary"/>}
               {
                            isSuccess ?
                                <div className="card">
                                    <Link to="/create-account-group" className='link-anchor' style={{marginLeft:'85%', paddingTop:'25px'}}>Add New</Link>
                                    
                                            <h3 className="text-center"> Account Types</h3>
                                            
                                            {
                                                accountTypes.length>0 ?
                                                <table className="table table-responsive table-striped">
                                                <thead>
                                                    <tr style={{fontSize:"15px"}}>
                                                        
                                                        <th>Type</th>
                                                        <th>Description</th>
                                                        <th>Range</th>
                                                        
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        
                                                       accountTypes.map((account)=>{
                                                                                                                        
                                                        return <tr key={account.id} style={{fontSize:"15px"}}>
                                                            <td>{account.type !== null && toTitleCase(account.type)}</td>
                                                            <td>{account.description}</td>
                                                            <td>{account.range}</td>                                              


                                                        </tr>

                                                    })
                                                    }

                                                    </tbody>
                                                   
                                                
                                            </table>
                                            :
                                            <p className='text-center'>No Account Types <span><Link to="/create-account-type" className="link-anchor"><br/>Create Account Type</Link></span></p>
                                            }
                                           
                                            

                                      

                                </div>
                                :
                                <div>
                                    {serverErrorMessage && <p className="text text-danger">{serverErrorMessage}</p>}



                                </div>
                        }

        </div>
    )
}
export default AccountTypes;