import React, { useState, useEffect} from 'react';
import { Link,useNavigate } from 'react-router-dom';
import {makePostRequest,makeGetRequest} from '../../../services/httpHelper'
import ErrorHandler from '../../../services/ErrorHandler';
import { SuccessAlert } from '../../../components/ui/UiState';
import { CircularProgress } from '@mui/material';
import { toTitleCase } from '../../../utils/myFunctions';
import HelpIcon from '@mui/icons-material/Help';
import ReactTooltip from 'react-tooltip';





const CreateSampleChartOfAccount = ()=>{

    const [accounts,setAccounts] = useState([])
    const [filteredAccounts,setFilteredAccounts] = useState(null)
   const [account_type_id,setAccountType] = useState(null)
   const [account_sub_type,setAccountSubType] = useState(null)
   const [account_name,setAccountName] = useState()
   const [account_number,setAccountNumber] = useState()
   const [description,setDescription] = useState()
   const [account_activity,setAccountActivity] = useState()
   const [account_group_id,setAccountGroupId] = useState()
   const [fund_id,setFundId] = useState()
   const [parent_id,setParentId] = useState()
   const [disabled,setDisabled] = useState(0)
   const [isSubmitting,setIsSubmitting] = useState(false)
   const [error,setError] = useState(null)
   const [validationError,setValidationError] = useState(null)
   const [isSuccess,setIsSuccess] = useState(false)
   const [isLoading,setIsLoading] = useState(false)
   const [accountTypes,setAccountTypes] = useState([])
   const [accountGroups,setAccountGroups] = useState([])
   const [accountSubtypes,setAccountSubtypes] = useState([])
   const [showSelectFund,setShowSelectFund] = useState(false)
   const [funds,setFunds] = useState([])
   const [showSubAccounts, setShowSubAccounts] = useState(false)
   const [showAccountSubType,setShowAccountSubType] = useState(false)
    const [filteredAccountSubtypes,setFilteredAccountSubtypes] = useState([])
   const [selectedSubAccount, setSelectedSubAccount] = useState('none')
   const [openingBalance, setOpeningBalance] = useState(0)
   const [showOpeningBalInput, setShowOpeningBalInput] = useState(false)
   const [accountActivities,setAccountActivities] = useState([])





   const user = JSON.parse(localStorage.getItem('user'))
   const church = JSON.parse(localStorage.getItem('church'));
   const navigate = useNavigate();


   const handleChange =(e)=>{
    setIsSuccess(false)
    setError(null)
    setValidationError(null)
    filterAccounts()
    let name = e.target.name;
    switch(name){
        case 'account_type_id':

            setAccountType(e.target.value);
            const subtypes = accountSubtypes.filter((type)=>type.account_type_id===e.target.value)
            setFilteredAccountSubtypes(subtypes)
            
             //filter accounts only to that of selected account type
        let copy_of_accounts =[...accounts];
        const filtered_accounts = copy_of_accounts.filter((result)=>result.account_type_id===e.target.value);
        //get account type value to decide whether to show opening balance input or not
        const selectedAccountType = accountTypes.filter((t)=>t.id===e.target.value)

      if(selectedAccountType){
        if(selectedAccountType[0].type==="liability" ||selectedAccountType[0].type==="asset"){
            setShowOpeningBalInput(true)
        }else{
            setShowOpeningBalInput(false)
        }
      }
        //reset accounts
        setFilteredAccounts(filtered_accounts);
           
           
            break;
            case 'account_name':
            setAccountName(e.target.value);
            break;
        case 'account_number':
            setAccountNumber(e.target.value);
            break;
            case 'account_group_id':
                setAccountGroupId(e.target.value);
                break;
                case 'account_activity':
                    setAccountActivity(e.target.value);
                    break;
        case 'description':
            setDescription(e.target.value);
            break;
            case 'parent_id':
                setParentId(e.target.value);
                break;
                case 'opening_balance':
                    setOpeningBalance(e.target.value);
                    break;
            case 'disabled':
                setDisabled(1);
                break;
                case 'fund_id':
                 setFundId(e.target.value);
                    break;
                    case 'account_sub_type':
                        setAccountSubType(e.target.value);
                        
                           break;
            default:
                console.log('Invalid Input');
                break;
    }
   }
   //filter accounts based on selected account type (Assets,Liabilities,Equity,Revenue or Expenses)
   const filterAccounts = ()=>{
    if(account_type_id !== null){
         
        //filter accounts only to that of selected account type
        let copy_of_accounts =[...accounts];
        const filtered_accounts = copy_of_accounts.filter((result)=>result.account_type_id===account_type_id);
        //reset accounts
        setFilteredAccounts(filtered_accounts);
    }
   }



    const handleSubmit = async(e)=>{
        e.preventDefault();
        setError('')
        setValidationError('')
       
        if(account_type_id === undefined){
            setValidationError('Select Account Type')
            return;
        }
        if(account_activity === undefined){
            setValidationError('Enter Account Activity')
            return;
        }
        if(account_group_id === undefined){
            setValidationError('Select Account Group')
            return;
        }
        if(account_name === undefined){
            setValidationError('Enter  account name')
            return;
        }
        if(account_type_id  && account_sub_type === null){
            setValidationError('Select account sub-type');
            return;
        }
        
             


        const data ={
            account_name:account_name,
            account_number:account_number,
            account_type_id:account_type_id,
            description:description,
            account_activity:account_activity,
            account_sub_type:account_sub_type,
            account_group_id:account_group_id,
             parent_id:parent_id,
            disabled:disabled,
            fund_id:fund_id,
            user_id:user.id,
            balance:openingBalance
        }
        setIsSubmitting(true)
        const post = await makePostRequest('sample-account',data)
        if (post && post.errorType) {
            setIsSubmitting(false);
            const errorMessage = ErrorHandler(post)
            setError(errorMessage)

        } else {
          
            getAccounts();
            setIsSubmitting(false);           
            setIsSuccess(true);
            !showSubAccounts && setParentId(null);

        }

    }
    const linkToFund = ()=>{
        if(showSelectFund){
            setShowSelectFund(false)
        }else{
            setShowSelectFund(true)
        }
    }
    const showAccounts = (e)=>{
        setParentId(null)
        showSubAccounts ? setShowSubAccounts(false) :setShowSubAccounts(true);
        
    }
    const getAccounts = async()=>{
        setError(null)
        setIsLoading(true)
        const res = await makeGetRequest('sample-accounts');
        if (res && res.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setError(errorMessage)

        } else {
              setAccounts(res.data)
              setFilteredAccounts(res.data)
            setError(null);
            setIsLoading(false);
            
    
        }

    }

    
    useEffect(()=>{
        const getAccountTypes = async()=>{
            setError(null)
            setIsLoading(true)
            const res = await makeGetRequest('church-account-types');
            if (res && res.errorType) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res)
                setError(errorMessage)
    
            } else {
                  setAccountTypes(res.data)
                setError(null);
                setIsLoading(false);

                
        
            }

        }

        const getAccountSubTypes = async()=>{
            setError(null)
            setIsLoading(true)
            const res = await makeGetRequest(`account-sub-types`);
            if (res && res.errorType) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res)
                setError(errorMessage)
    
            } else {
                  setAccountSubtypes(res.data)
                setError(null);
                setIsLoading(false);

                
        
            }

        }
            

        const getAccountGroups = async()=>{
            setError(null)
            setIsLoading(true)
            const res = await makeGetRequest('sample-account-groups');
            if (res && res.errorType) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res)
                setError(errorMessage)
    
            } else {
                  setAccountGroups(res.data)
                setError(null);
                setIsLoading(false);
                if(res.data.length===0){
                    navigate('/create-account-group')
                }
                
        
            }

        }

        const getAccountActivities = async()=>{
            setError(null)
            setIsLoading(true)
            const res = await makeGetRequest('account-activities');
            if (res && res.errorType) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res)
                setError(errorMessage)
    
            } else {
                console.log(res.data)
                  setAccountActivities(res.data)
                setError(null);
                setIsLoading(false);
                
                
        
            }

        }

        const getFunds = async()=>{
            setError(null)
            setIsLoading(true)
            const res = await makeGetRequest('library-funds');
            if (res && res.errorType) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res)
                setError(errorMessage)
    
            } else {
                  setFunds(res.data)
                setError(null);
                setIsLoading(false);
                if(res.data.length===0){
                    navigate('/add-fund')
                }
                   
            }

        }
        
        getAccountTypes();
        getAccountSubTypes();
        getAccounts();
        getAccountGroups();
        getFunds();
        getAccountActivities();

    },[])

 
        return (
            <div className="container">
                <ReactTooltip type='info' multiline="true"/>
                 <div>
                 <form onSubmit={handleSubmit} className="form-group">
                <h3 className="text-center">Create Account </h3>
                {isSubmitting && <CircularProgress/>}
                {isSuccess && <SuccessAlert/>}
                {error !== null && !isSubmitting ? <p className="text text-warning">{error}</p>  : null}
             {validationError !== null && !isSubmitting ? <p className='text text-danger'>{validationError}</p> : null}
             <div className="row jumbotron">
             <div className="col-md-6">
                    <div className=" form-group">
                    <span >Account Type</span>
                    {isLoading && <CircularProgress/>}               
                    <select id="account_type_id" className="form-control browser-default custom-select" name="account_type_id" onChange={handleChange}>
                    <option value="">Select Account Type</option>
                   {
                   accountTypes.length === 0 ? <p>No Accounts Added yet!</p> :
    
                   accountTypes.map((type)=>{
                    return  <option value={type.id} key={type.id}>{toTitleCase(type.type)}</option>
                   })
                   }
                  
    
                </select>
    
                </div>
    
                </div>
    
                <div className="col-md-6">
                    <div className="form-group">
                        {
                            account_type_id &&
                          
                          <>
                          <br/>
                          <label>Account Subtype</label><br/>
                          <select id="account_sub_type" name="account_sub_type" className="form-control" onChange={handleChange}>
                                <option value="">Choose..</option>
                                {
                                 filteredAccountSubtypes.map((type)=>{
                                    return  <option value={type.sub_type}>{toTitleCase(type.sub_type)}</option>
                                })
    
                                }
                           
                            </select>
                          </>
                          
    
                        }
                        </div>
                   
                        
                    </div>
    
             </div>
             <div className="row">
                    <div className="col-md-6">
                    <div className=" form-group">
                    <span>Account Name </span>
                    <input type="text" className="form-control" name="account_name"  placeholder="Enter Account account_name" onChange={handleChange}/>
    
                </div>
    
                    </div>
                    <div className="col-md-6">
                    <div className="form-group">
                    <span>Account Group <span className="text text-info" data-tip='Account group referes to collection that match certain accounts <br/> e.g. payables, receivables, bank etc.'><HelpIcon/></span></span>
                   {
                 accountGroups.length !== 0 ?
                    <select name="account_group_id" className="form-control" id="account_group" onChange={handleChange}>
                        <option value="">Select Account Group</option>
                        {
                          accountGroups.map((group)=>{
                               return <option key={group.id} value={group.id}>{toTitleCase(group.account_group)}</option>
                            })
                        }
    
                    </select>
                    :
                    <p>No Account Groups yet, <Link to="/create-sample-account-group">Create Account Group</Link></p>
                   }
                </div>
                        
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                    <div className="form-group">
                    <span>Account Activity <span className="text text-info" data-tip='Account Activity refers to how you would like the account to appear on the Statement of Cash Flows reports. <br/> The four options are: Cash, Operating, Investing and Financing'><HelpIcon/></span></span>

                    {
              accountActivities &&   accountActivities.length !== 0 ?
                 <select id="account_activity" name="account_activity" className="form-control" onChange={handleChange}>

                    <option value="">Choose Account Activity</option>

                        {
                         accountActivities && accountActivities.map((activity)=>{
                               return <option key={activity.id} value={activity.activity}>{toTitleCase(activity.activity)}</option>
                            })
                        }
                         </select>
                        :
                        <p>No Account activities found</p>
                    }
                   
                 
                 
             
    
                </div> 
    
                    </div>
                    <div className="col-md-6">
                    <div className="form-group">
                    <span ><input type="checkbox" name="link_to_fund" value="1" onChange={linkToFund} />
                    Link Account to A Fund
                    <span className="text text-info" data-tip='Associate the account being created to a fund. <br/> For example building material expense account can be linked to building fund'><HelpIcon/></span>
                    </span>
                  {
                  showSelectFund &&
                  <div>
                     {
                    funds.length !==0 ?
                    <select name="fund_id" className="form-control" id="fund_id" onChange={handleChange}>
                        <option value="">Select Fund</option>
                        {
                            funds.map((fund)=>{
                               return <option key={fund.id} value={fund.id}>{toTitleCase(fund.fund)}</option>
                            })
                        }
    
                    </select>
                    :
                    <p>Create Fund, <Link to="/create-fund">Create Fund</Link></p>
                   }
    
                    </div>
                  
                }
                  
                </div>
                <br/><br/>
                {
                    showOpeningBalInput &&
                    <div className="col-md-6">
                    <div className=" form-group">
                    <span>Starting Balance </span>
                    <input type="number" className="form-control" name="opening_balance"  placeholder="Opening Balance" onChange={handleChange}/>
    
                </div>
    
                    </div>
                }

             
                    
                   
                        
                    </div>

                    

                   
                </div>
    
                <div className="row">
               {
                accounts && 
    
                <div className="col-md-6">
                <div className="form-group">
                {
                   accounts && <span ><input type="checkbox" name="sub-account" onChange={showAccounts}/><span>Is Sub-Account</span></span> 
                }                 
                </div>
                {
                    showSubAccounts ?
                    accounts.length===0 ?
                    <select id="account_activity" name="parent_id" className="form-control" onChange={handleChange}>
                    <option value="">Choose Parent Account</option>
                    {
                      filteredAccounts.map((account)=>{
                          return <option key={account.id} value={account.id}>{toTitleCase(account.account_name)}</option>
                      })
                    }
                  
                  </select>
                    :
                    <p className="text text-warning">No Accounts created </p>
                   
                :
                null
                }
    
                </div>
               }
    
                    </div>
                    <div className="col-sm-12 form-group ">
                    <div className="row">
                        <div className="col-md-6">
                       
                        <Link to="/dashboard"> <button className="cancel-btn float-left">Close</button></Link>
    
                        </div>
                        <div className="col-md-6">
                        <button className="btn btn-primary float-right">{isSubmitting ? <CircularProgress/> : "Save"}</button>
    
    
                        </div>
                    </div>
                
    
                </div>  
    
                </form>
            
            </div>
            </div>
        
        )

    }
   


export default CreateSampleChartOfAccount;