
/**
 * Returns age based on date of birth
 */
export const getAge = (dob)=>{
    dob = new Date(dob);  
   //calculate month difference from current date in time  
   var month_diff = Date.now() - dob.getTime();  
     
   //convert the calculated difference in date format  
   var age_dt = new Date(month_diff);   
     
   //extract year from date      
   var year = age_dt.getUTCFullYear();  
     
   //now calculate the age of the user  
   var age = Math.abs(year - 1970);  
     

   return age;


}
/**
 * Capitalize each word in a string
 * for example come today will be Come Today
 */
export const toTitleCase = (str)=>{
    //split the string into arrays of words
    const words = str.split(' ');
    for(let i=0; i<words.length;i++){
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    const capitalized = words.join(" ");
    return capitalized;

}

/** 
 * Removes a value from array from any position
 * @params array, value to be removed
 * Returns modified array i.e. with value removed
 * **/
 export const removeFromArray =(arr,value)=>{      
    
    for( let i = 0; i < arr.length; i++){ 
    
        if ( arr[i] ===value) { 
    
            arr.splice(i, 1); 
        }
        return arr;
    
    }

}

export const formatDate = (dateString, language) => {
    const date = new Date(dateString);
  
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
      timeZoneName: 'short',
      weekday: 'long'
    };
  
    const formattedDate = new Intl.DateTimeFormat(language, options).format(date);
  
    return formattedDate;
  };
 
