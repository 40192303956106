import React,{useState} from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { 
    Spinner, Progress, Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription } from "@chakra-ui/react"

export const LoadingState =()=>{
    return <Spinner/>
}
export const BackgrounLoadingState =()=>{
    return <Progress/>
}
export const ErrorState = (error) => {
    return (
      <Alert status="error">
        <AlertIcon style={{color:"red",fontSize:20}}/>
        <AlertTitle mr={2}>An error occured!</AlertTitle>
        <AlertDescription>{error}.</AlertDescription>
      </Alert>
    );
  };

  export const SuccessAlert =()=>{
    return(
        <div className="container">
            <p className="text text-success"><span> <CheckCircleIcon/> </span>SUCCESS</p>
        </div>
    )
}
