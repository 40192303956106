import React, {useEffect, useState} from 'react'
import {Link, Navigate} from 'react-router-dom'
import { maxWidth } from '@mui/system';
import { toTitleCase } from '../../../utils/myFunctions';
import Content from '../../../components/Content'
import { CircularProgress } from '@mui/material';
import {makePostRequest,makeGetRequest} from '../../../services/httpHelper'
import ErrorHandler from '../../../services/ErrorHandler'
import { SuccessAlert } from '../../../components/ui/UiState';


const CreateTag =({pageTitle=null})=>{

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [serverError, setServerError] = useState(null);
    const [validationError,setValidationError] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const[tagGroups,setTagGroups] = useState(null);
    const[tag,setTag]= useState()
    const[tag_group_id,setTagGroupId]= useState()

    const user = JSON.parse(localStorage.getItem('user'));
    const church = JSON.parse(localStorage.getItem('church'));
 
 
    const handleChange =(e)=>{
     let name = e.target.name;
     switch(name){
         case 'tag':
             setTag(e.target.value);
             break;
         case 'tag_group_id':
             setTagGroupId(e.target.value);
             break;
         default:
             console.log('Invalid input');
             break;
            
     }
    }

    const handleSubmit = async(e)=>{
        e.preventDefault();
        setServerError(null)
        setValidationError(null)
              
        if(tag === undefined){
            setValidationError('Enter  tag or multiple tags separating with a comma')
            return;
        }
        if(tag_group_id === undefined){
            setValidationError('Select Tag Group')
            return;
        }
        const data ={
            tags:tag,
            tag_group_id:tag_group_id,
            church_id:church.id,
            user_id:user.id

        }
        setIsSubmitting(true)
        const post = await makePostRequest('tags',data)
        if (post && post.errorType) {
            setIsSubmitting(false);
            const errorMessage = ErrorHandler(post)
            setServerError(errorMessage)

        } else {
              
            
            setIsSubmitting(false);
            setIsSuccess(true);
            setTag(null)
             }

    }


    const getTagGroups = async()=>{
        setIsLoading(true)
       
        const res = await makeGetRequest('tag-groups');
        if (res.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setServerError(errorMessage)
    
        } else {
            setTagGroups(res.data)                                  
            setIsLoading(false);
            console.log(tagGroups)
        }
    }
    useEffect(()=>{
        getTagGroups();
    },[])

   

    return(
      <div className="container-fluid">
      <div className="row justify-content-center">
      <div className="col-md-12">
       <div className="">
       {pageTitle===null && <h3 className="card-title text-center">Create Tags</h3>
}
         <p className="text text-info">
          
         Tags allow  to further categorize your transactions in order to see more detailed
         information on  Reports. Its analogous to classes in quickbooks. Use them to track income
         and expenses by department, location, project, event etc.
         </p>
          <div className="card-body py-md-4">
           <form _lpchecked="1" onSubmit={handleSubmit} className="form-group">
              {serverError !== null  && <p className="text text-danger">{serverError}</p>}
              {validationError !== null && <p className="text text-danger">{validationError}</p>}
           {isSubmitting && <p className='text text-success'>Processing..</p>}
                                
        {isSuccess && <SuccessAlert/>}
                                <div className="form-group">
                    <span>Tag Group </span>
                    {
                      tagGroups !== null ?
                      <select className="form-control custom-select browser-default" name="tag_group_id" onChange={handleChange}>
                        <option value="">Choose...</option>
                        {
                           tagGroups.map((group)=>{
                            return <option key={group.id} value={group.id}>{toTitleCase(group.group_name)}</option>
                          })
                        }
                        </select>
                        :
                        <p>No tag groups <Link to="/create-tag-group">Create Tag Group</Link></p>

                    }
                    
                    
                   
                </div>
                 
             <div className="form-group">
             <span>Tags </span>
             <p className="text text-info">Enter tags for the selected tag group separating each by a comma</p>
                 <input type="text" className="form-control" id="tag" name="tag" placeholder="tag" onChange={handleChange} />
      
            </div>

          
                          
              
       <div className="d-flex flex-row align-items-center justify-content-between">
          <Link to="/">Done</Link>
                <button className="btn btn-primary">Save </button>
              </div>
           </form>
         </div>
      </div>
      </div>
      </div>
      </div>
    )
}
export default CreateTag;