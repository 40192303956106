import React, { useState, useEffect,useRef } from 'react';
import { Link,  } from 'react-router-dom';
import validateCreatePostForm from './validateCreatePostForm';
import useCreateBlogPost from './useCreateBlogPost';
import { CircularProgress } from '@material-ui/core';
import { toTitleCase } from '../../utils/myFunctions';
import './CreatePost.css'
import { Editor } from '@tinymce/tinymce-react';




const CreateBlogPost = () => {

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
    const { handleChange, values, handleSubmit,  isSubmitting, errors, error, isSuccess ,  categories,isLoading,handleFileChange} = useCreateBlogPost(submitForm, validateCreatePostForm,editorRef);
    const [isSubmitted, setIsSubmitted] = useState(false);

    function submitForm() {
        console.log('Is submitted being called', isSubmitted)
        setIsSubmitted(true);
        console.log('Is submitted  called', isSubmitted)
    }  
 
    
  
  
    return (
        <div className="container">
          

<div className="container">
  <div className="row">
    <div className="col-md-12">
    <h2>Create Post</h2>
      <form role="form" onSubmit={handleSubmit}>
      {isSubmitting && <p className='text text-primary'>processing..</p>}
        {isSuccess && <p className="text text-success">Success</p>}
        {isLoading && <p className='text text-success'><CircularProgress color='secondary'/></p>}
        {error !== null &&  <p className='text text-danger'>{error}</p> }
       

        <div className="form-group">
          <input type="text" className="form-control" name="title" placeholder="Title" value={values.title} onChange={handleChange}/>
          
         {errors.title && <p className=" text text-danger .error-message">{errors.title}</p>}
        </div>

        <div className="form-group">
        <select name='category_id' value={values.category_id} onChange={handleChange}>
                                <option>Category</option>
                                {
                                    categories !== null ?
                                    categories.map((cat)=>{
                                        return <option key={cat.id} value={cat.id}>{toTitleCase(cat.category)}</option>
                                    })
                                    :
                                    <p className='text text-warning'>No Categories</p>
                                }

                              </select>
                              <br/>
                                {errors.category_id && <p className=" text text-danger .error-message">{errors.category_id}</p>}
        </div>

        <div className="form-group">
        <div className="input-group-prepend">
          <span className="">Featured Image</span>
                                </div>
          <div className="input-group">
            
            <span className="input-group-btn">
            <input type="file" name="featuredImage" className="form-control has-error" id="focusedInput" placeholder="Enter Upload Image"  value={values.featuredImage} onChange={handleFileChange} />
             </span>
             <br />
             {errors.featuredImage && <p className=" text text-danger .error-message">{errors.featuredImage}</p>}
           </div>
        </div>


        <div className="input-group mb-3">

<Editor
apiKey='pvit8ug8f2cukxz30ygx2u1hk7iqnv3lzprusslpunu1oemb'
onInit={(evt, editor) => editorRef.current = editor}
initialValue=""
init={{
height: 500,
menubar: true,
plugins: [
'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
],
toolbar: 'undo redo | blocks | ' + 
'bold italic forecolor | alignleft aligncenter ' +
'alignright alignjustify | bullist numlist outdent indent | ' +
'removeformat | table link image  |  help',
content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
}}
onChange={log}
/>



  
   <br />
    {errors.content && <p className=" text text-danger .error-message">{errors.content}</p>}
</div>

<div className="input-group mb-3">
  <div className="input-group-prepend">
    <span className="input-group-text">Tags (optional)</span>
    </div>
  <input type="text" className="form-control has-error" id="focusedInput" placeholder="Enter tags separating each tag with a comma" name="tags" value={values.tags} onChange={handleChange} /><br />
                               

                            </div>
       
        <div className="form-group">
           <input type="submit" name="Submit" value={isSubmitting ? 'Publishing...':'PUBLISH'} className="btn btn-primary form-control" />
        </div>
      </form>
    </div>
  </div>
</div>
          


        </div>

    );

    
}




export default CreateBlogPost;