import React, { useState, useEffect,useRef } from 'react';
import './Inbox.css'
import { Link } from 'react-router-dom';
import { makeGetRequest } from '../../services/httpHelper';
import ErrorHandler from '../../services/ErrorHandler';



var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
var sampleEmails =[
    {
        "id":1,
        "read":false,
        "tag":"deleted",
        "subject":"Some little bit longer email subject",
        "from":"Wafutech",
        "time":"2023-11-22 12:22:45",
        "message":"Woops, that last pull request messed up the csproj. Mauris. Morbi non sapien molestie orci tincidunt adipiscing. Mauris molestie pharetra"
    
    },
    {
        "id":2,
        "read":true,
        "tag":"draft",
        "subject":"Draft Email",
        "from":"Wafutech",
        "time":"2023-11-22 12:22:45",
        "message":"Woops, that last pull request messed up the csproj. Mauris. Morbi non sapien molestie orci tincidunt adipiscing. Mauris molestie pharetra"
    
    },
    {
        "id":3,
        "read":true,
        "tag":"send",
        "subject":"Send Email",
        "from":"Wafutech",
        "time":"2023-11-22 12:22:45",
        "message":"Woops, that last pull request messed up the csproj. Mauris. Morbi non sapien molestie orci tincidunt adipiscing. Mauris molestie pharetra"
    
    },
    {
        "id":4,
        "read":false,
        "tag":"inbox",
        "subject":"Received Email",
        "from":"Wafutech",
        "time":"2023-11-22 12:22:45",
        "message":"Woops, that last pull request messed up the csproj. Mauris. Morbi non sapien molestie orci tincidunt adipiscing. Mauris molestie pharetra"
    
    },
    {
        "id":5,
        "read":true,
        "tag":"inbox",
        "subject":"Deleted Email",
        "from":"Wafutech",
        "time":"2023-11-22 12:22:45",
        "message":"Woops, that last pull request messed up the csproj. Mauris. Morbi non sapien molestie orci tincidunt adipiscing. Mauris molestie pharetra"
    
    },
    {
        "id":7,
        "read":false,
        "tag":"inbox",
        "subject":"Inbox Email",
        "from":"Wafutech",
        "time":"2023-11-22 12:22:45",
        "message":"Woops, that last pull request messed up the csproj. Mauris. Morbi non sapien molestie orci tincidunt adipiscing. Mauris molestie pharetra"
    
    },
    {
        "id":7,
        "read":true,
        "tag":"inbox",
        "subject":"Inbox Email",
        "from":"Wafutech",
        "time":"2023-11-22 12:22:45",
        "message":"Woops, that last pull request messed up the csproj. Mauris. Morbi non sapien molestie orci tincidunt adipiscing. Mauris molestie pharetra"
    
    },
    {
        "id":8,
        "read":true,
        "tag":"draft",
        "subject":"Draft Email",
        "from":"Wafutech",
        "time":"2023-11-22 12:22:45",
        "message":"Woops, that last pull request messed up the csproj. Mauris. Morbi non sapien molestie orci tincidunt adipiscing. Mauris molestie pharetra"
    
    },
    {
        "id":9,
        "read":false,
        "tag":"send",
        "subject":"Send Email",
        "from":"Wafutech",
        "time":"2023-11-22 12:22:45",
        "message":"Woops, that last pull request messed up the csproj. Mauris. Morbi non sapien molestie orci tincidunt adipiscing. Mauris molestie pharetra"
    
    },
    {
        "id":10,
        "read":true,
        "tag":"inbox",
        "subject":"Inbox Email",
        "from":"Wafutech",
        "time":"2023-11-22 12:22:45",
        "message":"Woops, that last pull request messed up the csproj. Mauris. Morbi non sapien molestie orci tincidunt adipiscing. Mauris molestie pharetra"
    
    }
   
]

const Inbox = ()=>{
    const [emails,setEmails] = useState([]);
	const [messages,setMessages] = useState([]);
    const [notifications,setNotifications] = useState([]);
    const [selectedEmailId,setSelectedEmailId] = useState('');
	const[currentSection,setCurrentSection] =useState('inbox');
	const[currentEmail,setCurrentEmail] =useState('');
	const[isLoading,setIsLoading]=useState(false);

const church = JSON.parse(localStorage.getItem('church'));
const user = JSON.parse(localStorage.getItem('user'));


   const openEmail =(id) =>{
		const index = emails.findIndex(x => x.id === id);
		emails[index].read = 'true';
        setSelectedEmailId(id)
		setCurrentEmail(emails[index])
	
	}

    const deleteMessage = (id) =>{
		// Mark the message as 'deleted'
		const index = emails.findIndex(x => x.id === id);
		emails[index].tag = 'deleted';
		
		// Select the next message in the list
		setSelectedEmailId('')
		for (const email of emails) {
			if (email.tag === currentSection) {
				setSelectedEmailId(email.id)
				break;
			}
		}
		
	
	}

   const setSidebarSection = (section) => {
		if (section !== currentSection) {
			setSelectedEmailId('')
		}
		setCurrentSection(section)
		
	

			}


			const getUserEmails = async()=>{
				try{
					setIsLoading(true)
					const res = await makeGetRequest(`church-user-messages/${church.id/user.id}`)
					if(res && res.errorType){
						setIsLoading(false)
						const errorMessage = ErrorHandler(res)
						setServerError(errorMessage)
						
					}else{
						setServerError(null)
						const allMessages = res.data;
						const emailMessages = allMessages.filter((message)=>message.message_type==='email');
						const sms = allMessages.filter((message)=>message.message_type==='sms');
						const notifs = allMessages.filter((message)=>message.message_type==='notification');

						setEmails(emailMessages);
						setMessages(sms)
						setNotifications(notifs)
						setIsLoading(false);
					}
		
				}catch(error){
					console.log(error)
					setIsLoading(false)
				}
			   
		
			}
			useEffect(()=>{
				getUserEmails();
			},[])
    return(
	
	<div>

		<div className='row'>
			<div className='col-md-2 col-lg-2'>

			</div>
			<div className='col-md-10 col-lg-10'>
				<p>Navigation bar at the top</p>

			</div>


		</div>
		<div className='row'>
			<div className='col-md-3'>
			<InBoxSideBar
			emails={emails}
			setSidebarSection={(section) => {setSidebarSection(section); }}/>

			</div>

			<div className='col-md-9'>
				<div style={{margin:"20px",paddingLeft:"25px"}}>
				<EmailList
				emails={emails.filter(x => x.tag === currentSection)}
				onEmailSelected={(id) => { openEmail(id); }}
				selectedEmailId={selectedEmailId}
				currentSection={currentSection} />

				</div>
			

				<div className='row'>
					<div className='col-md-12'>
						<div style={{margin:"20px",paddingLeft:"25px"}}>
						<EmailDetails
				email={currentEmail}
				onDelete={(id) => { this.deleteMessage(id); }} style={{margin:"20px",paddingLeft:"25px"}}/>
							
						</div>
					

					</div>
				</div>

			</div>
		</div>

		{/*<div className='row'>
			<div className='col-md-2 col-lg-2'>
			<InBoxSideBar
			emails={emails}
			setSidebarSection={(section) => {setSidebarSection(section); }}/>

			</div>
			<div className='col-md-4 col-lg-10'>
			<div className="inbox-container">
			<EmailList
				emails={emails.filter(x => x.tag === currentSection)}
				onEmailSelected={(id) => { openEmail(id); }}
				selectedEmailId={selectedEmailId}
				currentSection={currentSection} />
			<EmailDetails
				email={currentEmail}
				onDelete={(id) => { this.deleteMessage(id); }} />
		</div>

			</div>


		</div>*/}

		<footer className='inbox-footer'>Footer</footer>
	
	
	</div>);
}



/* Sidebar */
const InBoxSideBar = ({ emails, setSidebarSection }) => {
	var unreadCount = emails.reduce(
		function(previous, msg) {
			if (msg.read !== "true" ) {
				return previous + 1;
			}
			else {
				return previous;
			}
		}.bind(this), 0);

	var deletedCount = emails.reduce(
		function(previous, msg) {
			if (msg.tag === "deleted") {
				return previous + 1;
			}
			else {
				return previous;
			}
		}.bind(this), 0);

	return (
		<div id="sidebar">
			<div className="sidebar__compose">
				<Link to="/send-sms" className="inbox-btn compose link-anchor">
					Send SMS<span className="fa fa-pencil"></span>
				</Link>
				<Link to="/send-email" className="inbox-btn compose link-anchor">
					Send Email<span className="fa fa-pencil"></span>
				</Link>
				<Link to="/send-notification" className="inbox-btn compose link-anchor">
					Send Notification<span className="fa fa-pencil"></span>
				</Link>
			</div>
			<ul className="sidebar__inboxes">
				<li onClick={() => { setSidebarSection('inbox'); }}><a>
					<span className="fa fa-inbox"></span> Inbox
					<span className="item-count">{unreadCount}</span></a></li>
				<li onClick={() => { setSidebarSection('sent'); }}><a>
					<span className="fa fa-paper-plane"></span> Sent
					<span className="item-count">0</span></a></li>
				<li onClick={() => { setSidebarSection('drafts'); }}><a>
					<span className="fa fa-pencil-square-o"></span> Drafts
					<span className="item-count">0</span>
					</a></li>
				<li onClick={() => { setSidebarSection('deleted'); }}><a>
					<span className="fa fa-trash-o"></span> Trash
					<span className="item-count">{deletedCount}</span>
					</a></li>
			</ul>
		</div>
	);
};

/* Email classes */
const EmailListItem = ({ email, onEmailClicked, selected }) => {
	let classes = "email-item";
	if (selected) {
		classes += " selected"
	}
		
	return (
		<div onClick={() => { onEmailClicked(email.id); }} className={classes}>
			<div className="email-item__unread-dot" data-read={email.read}></div>
			<div className="email-item__subject ">{email.sender.first_name} {email.sender.last_name}  {email.subject} {getPrettyDate(email.createdAt)}</div>
			{/*<div className="email-item__details">
				<span className="email-item__from ">{email.from}</span>
				<span className="email-item__time ">{getPrettyDate(email.time)}</span>
			</div>*/}
		</div>
	);
}

const EmailDetails = ({ email, onDelete }) => {
	if (!email) {
		return (
			<div className="email-content empty"></div>
		);
	}
	
	const date = `${getPrettyDate(email.createdAt)} · ${getPrettyTime(email.createdAt)}`;
	
	const getDeleteButton = () => {
		if (email.tag !== 'deleted') {
			return <span onClick={() => { onDelete(email.id); }} className="delete-btn fa fa-trash-o"></span>;
		}
		return undefined;
	}

	return (
		<div className="email-content">
			<div className="email-content__header">
				<h3 className="email-content__subject">{email.subject}</h3>
				{getDeleteButton()}
				<div className="email-content__time">{date}</div>
				<div className="email-content__from">{email.sender.first_name} {email.sender.last_name}</div>
			</div>
			<div className="email-content__message">{email.message}</div>
		</div>
	);
};

/* EmailList contains a list of Email components */
const EmailList = ({ emails, onEmailSelected, selectedEmailId }) => {
	if (emails.length === 0) {
		return (
			<div className="email-list empty">
				Nothing to see here, great job!
			</div>
		);
	}
	
	return (
		<div className="email-list">
			{
				emails.map(email => {
					return (
						<EmailListItem
							onEmailClicked={(id) => { onEmailSelected(id); }}
							email={email}
							selected={selectedEmailId === email.id} />
					);
				})
			}
		</div>
	);
};




// Helper methods
const getPrettyDate = (date) => {
	date = date.split(' ')[0];
	const newDate = date.split('-');
	const month = months[0];
	return `${month} ${newDate[2]}, ${newDate[0]}`;
}

// Remove the seconds from the time
const getPrettyTime = (date) => {
	const time = date.split(' ')[1].split(':');
	return `${time[0]}:${time[1]}`;
}

export default Inbox;
