import {makePostRequest} from './httpHelper';
import {
    useNavigate,
    withRouter 
  } from 'react-router-dom';

const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const AuthVerify = (props) => {
    props.history.listen(() => {
      const user = JSON.parse(localStorage.getItem("user"));
  
      if (user) {
        const decodedJwt = parseJwt(user.token);
  
        if (decodedJwt.exp * 1000 < Date.now()) {
          props.logOut();
          const navigate = useNavigate();
          return navigate('/login')
        }
      }
    });
  
    return <div></div>;
  };



    const logout =async()=>{
       console.log('logging out...')
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('roles');
        sessionStorage.removeItem('church');       
        return true;
     
        
        }

        /*const getUserProfile =async()=>{
            const res = await makeGetRequest('auth/user');
            if(res.response && res.response.data)
            
            return res;
            
            }*/
    
            const getCurrentUser = ()=>{
                                
                return localStorage.getItem('token');
                
                }
                const isLoggedIn = ()=>{
                                
                     if(sessionStorage.getItem('token')){
                        return true;
                     }else{
                        return false;
                     }
                    
                    }

                 

                const AuthService = {
                   
                    logout,
                    getCurrentUser,
                    isLoggedIn,
                   
                  };
                  export default AuthService;
