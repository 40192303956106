import React, { useState, useEffect} from 'react';
import { Link, Navigate,useParams } from 'react-router-dom';
import {makeGetRequest} from '../../../services/httpHelper'
import ErrorHandler from '../../../services/ErrorHandler';
import { CircularProgress } from '@mui/material';
import { toTitleCase } from '../../../utils/myFunctions';


const PrintableChartOfAccounts = ()=>{
    const [accounts, setAccounts] = useState(null);
    const [incomeAccounts, setIncomeAccounts] = useState(null);
    const [expenseAccounts, setExpnesAccounts] = useState(null);
    const [equityAccounts, setEquityAccounts] = useState(null);
    const [assets, setAssets] = useState(null);
    const [currentAssets, setCurrentAssets] = useState(null);
    const [faAssets, setFaAssets] = useState(null);
    const [liabilities, setLiabilities] = useState(null);
    const [currentLiabilities, setCurrentLiabilities] = useState(null);
    const [ltLiabilities, setLtLiabilities] = useState(null);
    const [accountGroups, setAccountGroups] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false);
    const [serverErrorMessage, setServerErrorMessage] = useState(null);   


    const user = JSON.parse(localStorage.getItem('user'));
    const church = JSON.parse(localStorage.getItem('church'));
      

    async function getAccounts() {
        setIsLoading(true)
        // You can await here
        const res = await makeGetRequest('church-accounts/'+church.id);
        if (ResizeObserver && ResizeObserver.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setServerErrorMessage(errorMessage)

        } else {
            setAccounts(res.data);
           
            setAssets(filterByTypes('assets'))
            setLiabilities(filterByTypes('liabilities'))
            setIncomeAccounts(filterByTypes('revenue'))    
            setExpnesAccounts(filterByTypes('expenses'))
            setEquityAccounts(filterByTypes('equity')) 
                          
            setIsLoading(false);
            setIsSuccess(true);
            
        }       

    }

    const filterByGroups= (group)=>{      
        
       const results = accounts.filter(account=>account.group.account_group === group)
      return results;

    }
    const filterByTypes= (account_type)=>{
          console.log('accounts',accounts)   
       const results = accounts.filter(type=>type.type.type === account_type);
       return results;
        
    }
   
    useEffect(()=>{
        getAccounts();

    },[])

   

    return(
        <div className='container-fluid'>
            {serverErrorMessage !== null && <p>Something wrong happened: <span className="text text-danger">{serverErrorMessage}</span></p>}
             {isLoading && <CircularProgress color="primary"/>}
               {
                            isSuccess ?
                                <div className="card">
                                    
                                            <h3 className="text-center"> Chart of Accounts</h3>
                                           
                                            {
                                                accounts !==null ?
                                               <div className='container'>
                                                <h4>Assets</h4>
                                                {
                                                    assets !== null ?
                                                    assets.map((asset)=>{
                                                        return <p style={{textIndent:"4%"}} key={asset.id}>{asset.account.parent_id === null && toTitleCase(asset.account_name)}</p>
                                                    })
                                                    :
                                                    <p className="text text-warning">No Asset Accounts</p>
                                                }
                                                <h4>Liabilities</h4>
                                                {
                                                    liabilities !== null ?
                                                    liabilities.map((liability)=>{
                                                        return <p style={{textIndent:"4%"}} key={liability.id}>{liability.account.parent_id === null &&  toTitleCase(liability.account_name)}</p>
                                                    })
                                                    :
                                                    <p className="text text-warning">No Liability Accounts</p>
                                                }
                                                <h4>Equity</h4>
                                                {
                                                    equityAccounts !== null ?
                                                    equityAccounts.map((equity)=>{
                                                        return <p style={{textIndent:"4%"}} key={equity.id}>{equity.account.parent_id === null &&  toTitleCase(equity.account_name)}</p>
                                                    })
                                                    :
                                                    <p className="text text-warning">No Equity Accounts</p>
                                                }
                                                <h4>Income</h4>
                                                {
                                                    incomeAccounts !== null ?
                                                    incomeAccounts.map((income)=>{
                                                        return <p style={{textIndent:"4%"}} key={income.id}>{income.account.parent_id === null &&  toTitleCase(income.account_name)}</p>
                                                    })
                                                    :
                                                    <p className="text text-warning">No Income Accounts</p>
                                                }
                                                <h4>Expenses</h4>
                                                {
                                                    expenseAccounts !== null ?
                                                    expenseAccounts.map((exp)=>{
                                                        return <p style={{textIndent:"4%"}} key={exp.id}>{exp.account.parent_id === null &&  toTitleCase(exp.account_name)}</p>
                                                    })
                                                    :
                                                    <p className="text text-warning">No Expense Accounts</p>
                                                }
                                                </div>
                                            :
                                            <p>No Accounts <span><Link to="/create-account" className="btn btn-info">Create Account</Link></span></p>
                                            }
                                                    

                                      

                                </div>
                                :
                                <div>
                                    {serverErrorMessage && <p className="text text-danger">{serverErrorMessage}</p>}



                                </div>
                        }

        </div>
    )
}
export default PrintableChartOfAccounts;