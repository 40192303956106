import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import SubscriptionFeatureCard from '../../components/ui/SubscriptionFeatureCard';
import { Faqs } from '../../pages';
import {makeGetRequest,makePutRequest} from '../../services/httpHelper'
import ErrorHandler from '../../services/ErrorHandler'

const Subscription = () => {
  const [email, setEmail] = useState('');
  const [plan, setPlan] = useState('free');
  const [plans,setPlans] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [serverError,setServerError] = useState(null);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePlanChange = (e) => {
    setPlan(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle subscription logic here, e.g., sending data to your server
    console.log(`Subscribed with email: ${email} and plan: ${plan}`);
  };

  const getPlans = async()=>{
    setIsLoading(true);
    const res = await makeGetRequest('subscription-plans');
    if (res && res.errorType) {
        setIsLoading(false);
        const errorMessage = ErrorHandler(res)
        setServerError(errorMessage)

    } else {
      const results = await res.data;
      
        setPlans(results)        
        setIsLoading(false);
        setServerError(null)
        

    }
   
}



  useEffect(()=>{
    getPlans();

  },[])

  return (
    <Container fluid className="subscription-page">
      <blockquote>
 
   
      </blockquote>
      <div className='row'>
        {
           plans.length !== 0 ?
          plans.map((plan)=>{
            return <div className='col-md-4' key={plan.id}>
            <SubscriptionFeatureCard data={{plan:plan,features:[]}}/>
    
            </div>
          })
          :
          <p className='text text-info'>No Subcription plans found</p>
        }
        
      
      
      </div>
      <br/>
      <Faqs/>
   
    </Container>
  );
};

export default Subscription;
