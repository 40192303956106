import React, { useState,useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { makeDeleteRequest, makeGetRequest, makePostRequest } from '../../../services/httpHelper';
import getAge from '../../../utils/GetAge';
import { Button } from 'react-bootstrap';
import {FaLock, FaLockOpen, FaTrash, FaUserAlt } from 'react-icons/fa';
import GroupsIcon from '@mui/icons-material/Groups';
import { AddChurchUserForm } from './church';
import CustomModal from '../../../components/ui/CustomModal';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';



const Users =()=>{
    const [users,setUsers] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isSuccess,setIsSuccess] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const[data,setData] = useState([])
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const navigate = useNavigate()

    const columns = [
        {
            name: 'User Name',
            selector: row => row.user_name,
            sortable: true,
        },
     
     
      
        {
            name: 'First Name',
            selector: row => row.first_name,
            sortable: true,
          
        },
        {
            name: 'Last Name',
            selector: row => row.last_name,
        },

        {
            name: 'Country',
            selector: row => row.country,
        },
        {
            name: 'Blocked',
            selector: row => row.blocked,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => (
              <div style={{display:"flex", justifyContent:"space-between"}}>
                   {
                                        row.blocked==='Yes' ?
                                    
                                        <span><FaLockOpen style={{cursor:"pointer"}} onClick={()=>unBlockUser(row.id)}/> Unblock</span>
                                        :

                                       
                                        <span><FaLock style={{cursor:"pointer"}} onClick={()=>blockUser(row.id)}/> Block</span>

                                       }
               
              
                                     <FaTrash onClick={() => removeUser(row.id)} className='text text-danger' style={{cursor:'pointer', marginLeft:"25px"}} />
              </div>
            ),
          },
    
       
       
     
    ];

    const prepareData =(allUsers)=>{
    
        const newData = allUsers.map((user)=>({
            id:user.id,
            user_name:user.email,
            first_name:user.first_name,
            last_name:user.last_name,
            country:user.country,
            blocked:user.blocked ? 'Yes':'No',
          
          
        }));
    
        return newData;
    
    
    }
  

    const blockUser =async(id)=>{
        const res = await makePostRequest('block-user/'+id);
        if (res && res.errorType) {
            setServerErrorMessage('Something went wrong')

        } else {

        
            setServerErrorMessage(null)
            fetchUsers()



    }
}


const unBlockUser =async(id)=>{
    const res = await makePostRequest('un-block-user/'+id);
    if (res && res.errorType) {
        setServerErrorMessage('Something went wrong')

    } else {

  
        setServerErrorMessage(null)
        fetchUsers()


}
}


    const removeUser =async(id)=>{

        const confirmed = window.confirm('Are sure you want to permanently remove the user?')
        if(confirmed){
            const res = await makeDeleteRequest('user/'+id);
            if (res && res.errorType) {
                setServerErrorMessage('Something went wrong')
    
            } else {
    
                const filteredUsers = users.filter((user)=>user.id !== id)
                setUsers(filteredUsers)
                setServerErrorMessage(null)
    
    
        }

        }
       
}

const showProfile = (row)=>{
   navigate(`/user-profile/${row.id}`)
}

async function fetchUsers() {
    setIsLoading(true)
    // You can await here
    const res = await makeGetRequest('users');
    if (res && res.errorType) {
        setIsLoading(false);               

         if (res.errorType === 'authorization') {
            setServerErrorMessage(res.errorMessage);

        }
        else if (res.errorType === 'badrequest') {
            setServerErrorMessage(res.errorMessage);
        } else {
            setServerErrorMessage(res.errorMessage);
        }
    } else {
        const result = await res.data;
        setUsers(result);
        const newdata = prepareData(result)
        setData(newdata)				
        setIsLoading(false);
        setIsSuccess(true);
        console.log(users)


    }

}

    useEffect(() => {
     
       
        
		fetchUsers();      
       


    }, []);
     const pageTitle ='New User'
    const modalBody = <AddChurchUserForm pageTitle={pageTitle}/>
    return(
        <>
        {
            isSuccess ?
            <div className="container">
              <div className='row'>
                    <div className='col-md-3'>
                   
                    </div>
                    <div className='col-md-6'>
                    <h3> <GroupsIcon/> Users</h3>
                    
                    </div>
                    <div className='col-md-3'>
                    <Button variant="primary" onClick={handleShow}>
        <FaUserAlt/> Add New User
      </Button>

                    </div>
                </div>
                <br/>

                          <DataTable
                            title="Users"
                               columns={columns}
                               data={data}
                               pagination
                               paginationPerPage={10} // Number of rows per page
                               paginationRowsPerPageOptions={[10, 20, 30,50,70,100]} // Options for rows per page dropdown
                               paginationComponentOptions={{ // Additional options for pagination component
                                 rowsPerPageText: 'Rows per page:',
                                 rangeSeparatorText: 'of',
                               }}
                               onRowClicked={(e)=>showProfile(e)}
                               className="hoverCursor" 
                             
                           />
           
        </div>
        :
        <div>
        {isLoading && serverErrorMessage === null && <CircularProgress color="secondary"/>}
        {serverErrorMessage && <p className="text text-danger">{serverErrorMessage}</p>}

    </div>
       
        }
         <CustomModal 
            show={showModal}
            showModal={showModal}
            handleClose={handleClose}
            header={pageTitle}
            body={modalBody}
            size="lg" // You can change the size as needed
            footer=''
            />

         </>
    )
}
export default Users;