// CreatePolicyForm.js
import React,{useState,useRef,useEffect} from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import './Policy.css'
import { isEmpty } from '../../utils/formValidator';
import { makeGetRequest, makePostRequest } from '../../services/httpHelper';
import { CircularProgress } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { tonameCase } from '../../utils/myFunctions';
import ErrorHandler from '../../services/ErrorHandler';



var validationErrors={};

const CreatePolicyForm = () => {

  const [errors, setErrors] =useState({});
const [error, setError] =useState(null);
const[isSubmitting, setIsSubmitting]=useState(false)
const[isSuccess, setIsSuccess]=useState(false)
const[isLoading, setIsLoading]=useState(false)




  const [values, setValues] = useState({
    name:'',
    description:'',   
    date:''
    
  
  });

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  }
  
   
  
    const handleChange = (e) =>{
      setValues({
          ...values,
         
          [e.target.name]:e.target.value
      })

    
  }


 

  const handleSubmit = async(e) =>{
    e.preventDefault(); //prevent reloading page when submitting form
    setErrors({})
    setError(null)
    setIsSuccess(false)
    setValues({
      ...values,
      ['body']:editorRef.current.getContent()
    })
    //validate user input
    if(isEmpty(values.name)){
      setErrors(validationErrors.name='policy name  is required')
    }
    if(isEmpty(values.date)){
      setErrors(validationErrors.date='policy effective date  is required')
    }
    if(values.description==null){
      setErrors(validationErrors.description='Enter policy content')
    }
   

  
    setErrors(validationErrors)
  
  
  
    if(Object.keys(errors).length !==0 ){
      setIsSubmitting(false)
      return;
    }
  
    setIsSubmitting(true)
   
    const church = JSON.parse(localStorage.getItem('church'));
    const user = JSON.parse(localStorage.getItem('user'))
  
   

   
 
  const data ={
    name:values.name,
    description:editorRef.current.getContent(),
    date:values.date,
    created_by:user.id,
  }
    const res = await makePostRequest(`policy`,data);
    if(res && res.errorType){
      setIsSubmitting(false)
      setError(res.errorMessage);
  
    }else{
      setIsSubmitting(false);
      setIsSuccess(true);
    }
  
  }


 
  return (
    <Container>
       <main className="container-fluid row justify-content-center px-0">
		<div className="col-12 col-sm-9 col-md-5 mt-3 mb-5">
      <form onSubmit={handleSubmit}>
        {isSuccess && <p className='text text-success'> Saved!</p>}
        {error !== null && <p className='text text-danger'>{error}</p>}
        {isSubmitting && <CircularProgress/>}
        {isLoading && <CircularProgress/>}
       

      <fieldset className="border border-dark rounded p-3">
				<div id="reactSignUpForm" className="px-2">

        <div className={"form-group"}>
				<label htmlFor={"name"}>Policy Name: </label>
			
				<input
					type="text"
					tabIndex={"1"}
					autoFocus={'autoFocus'}
					className="form-control"
					id="name"
          name="name"
          value={values.name}
					onChange={handleChange}
				/>

        
      
        {errors.name && <p className='text text-danger'>{errors.name}</p>}
        </div>

       



        <div className={"form-group"}>
        <label htmlFor={"date"}>Effective Date: </label>

         <input
					type="date"
					tabIndex={"2"}
					className="form-control"
					id="date"
          name="date"
          value={values.date}
					onChange={handleChange}
				/>
                {errors.date && <p className='text text-danger'>{errors.date}</p>}

			</div>

              
 
      <div className={"form-group"}>
      <label htmlFor={"body"}>Policy Content: </label>
      <Editor
apiKey='pvit8ug8f2cukxz30ygx2u1hk7iqnv3lzprusslpunu1oemb'
onInit={(evt, editor) => editorRef.current = editor}
initialValue=""
init={{
height: 500,
menubar: true,
plugins: [
'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
],
toolbar: 'undo redo | blocks | ' + 
'bold italic forecolor | alignleft aligncenter ' +
'alignright alignjustify | bullist numlist outdent indent | ' +
'removeformat | table link image  |  help',
content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
}}
onChange={log}
/>
      </div>
    
     

     

      <div className={"form-group"}>
       

         <button
					type="button"
					tabIndex={"3"}
					className="form-btn"
					id="date"
          name="date"
          onClick={handleSubmit}
				>{isSubmitting ? <CircularProgress/>: 'Create Policy'}</button>
			</div>
				</div>
			</fieldset>

      </form>
			
		</div>
	</main>
    </Container>
  );
};

export default CreatePolicyForm;
