import React, { useState } from 'react';
import { Link, Navigate,useParams } from 'react-router-dom';
import {makePostRequest} from '../../services/httpHelper'
import ErrorHandler from '../../services/ErrorHandler'
import { CircularProgress } from '@mui/material';




const SendSMS = ()=>{
   
   const [targetAudience,setTargetAudience] = useState('all')
   const [message,setMessage] = useState(null)
   const [isSubmitting,setIsSubmitting] = useState(false)
   const [error,setError] = useState(null)
   const [messageError,setMessageError] = useState(null)
   const [isSuccess,setIsSuccess] = useState(false)



   const handleChange =(e)=>{
    let name = e.target.name;
    switch(name){
       
        case 'message':
            setMessage(e.target.value);
            setMessageError(null)

            break;
            default:
                setTargetAudience(e.target.value)
                break;
    }
   }



    const handleSubmit = async(e)=>{
        e.preventDefault();
        setError(null)
        
       try{
     
        if(message === null){
            setMessageError('Enter message ')
            return;
        }        

        const user = JSON.parse(localStorage.getItem('user'));
        const church = JSON.parse(localStorage.getItem('church'))


        const data ={
            message:message,
            user_id:user.id,
            church_id:church.id,
            target_audience:targetAudience           
           
        }
        setIsSubmitting(true)
        const post = await makePostRequest('message-church-members',data)
        if (post && post.errorType) {
            setIsSubmitting(false);
            const errorMessage = ErrorHandler(post)
            setError(errorMessage)

        } else {
              
            setIsSubmitting(false);
            setIsSuccess(true);
            setMessage(null)
            setTargetAudience('all')



        }
       }catch(error){
        setIsSubmitting(false);
        console.log(error)
       }

    }
    return (
        <div className="container">           
             <div>
        <form onSubmit={handleSubmit} className="form-group">
            <h3 className="text-center" >Send SMS</h3>
            {isSubmitting && <p className='text text-success'>Sending..</p>}
            {isSuccess && <p className='text text-success'>Message send</p>}
            {error !== null && <p className='text text-danger text-center'>{error}</p> }
        
       <div style={{marginLeft:"20%"}}>
       <div className="row jumbotron text-center">
      

      <div className="col-sm-8 form-group">

          <select name='target_audience' className="form-control"  onChange={handleChange}>
            <option value="">Select Target Receipients</option>
              <option value="all"> All Members</option>
              <option value="women"> Women Members</option>
              <option value="men"> Men Members</option>
              <option value="youths"> Youths</option>
              <option value="leaders"> Only Leaders</option>

          </select>
      

      </div>
      </div>
      
      <div className="row jumbotron text-center">
      <div className="col-sm-8 form-group">
          <textarea name="message" cols="60" rows="5" placeholder="Enter  message" value={message} onChange={handleChange}>
          
          </textarea>
          {messageError !== null && <p className='text text-danger'>{messageError}</p>}
          <button className="btn btn-primary float-right" onClick={handleSubmit}>{isSubmitting ? <CircularProgress/> : "Send"}</button>

      </div>

                                 

    
      
  </div>
       </div>
        </form>
        </div>
    </div>
    )

}
export default SendSMS;