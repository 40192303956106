import React, { useContext,useEffect,useState } from 'react';
import Button from '@material-ui/core/Button';
import { SnackbarContext } from '../context/SnackbarContext';
import { useSetSnackbar } from '../hooks/useSnackbar';


const Content = (props) => {
  const [showButton,setshowButton]=useState(false)
  const setSnackbar = useSetSnackbar();
  const handleClick = (message,type) => {
    setSnackbar(`${message} ${type}`, type);
  };
  console.log('Content re-render');
  //possible snackbar types: success, error, info and warning that can passed as prop to this component

useEffect(()=>{
   handleClick(props.message,props.type)
},[])
  return (
    <div>
     {showButton && <Button onClick={() => handleClick(props.message,props.type)}>{props.btn_label || "Show snackbar"}</Button> } 
    
      
    </div>
  );
};

export default Content;
