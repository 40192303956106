import React, { useState, useEffect} from 'react';
import { Link, Navigate,useParams } from 'react-router-dom';
import {makeDeleteRequest, makeGetRequest} from '../../../services/httpHelper'
import ErrorHandler from '../../../services/ErrorHandler';
import { LoadingState,ErrorState,BackgrounLoadingState } from '../../../components/ui/UiState';
import { CircularProgress } from '@mui/material';
import { toTitleCase } from '../../../utils/myFunctions';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { FaEdit } from 'react-icons/fa';
import CustomModal from '../../../components/ui/CustomModal';
import CreateSampleChartOfAccounts from './CreateChartOfAccounts';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import { Button } from 'react-bootstrap';
import EditAccount from './EditAccount';



const SampleChartOfAccounts = ()=>{
    const [accounts, setAccounts] = useState([]);
    const [accountTypes, setAccountTypes] = useState(null);
    const [accountGroups, setAccountGroups] = useState([]);
    const [filteredAccounts, setFilteredAccounts] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState(null);  
    const[isSubmitting,setIsSubmitting] =useState(false)
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    //Edit Account
    const [showEditModal, setShowEditModal] = useState(false);
    const handleCloseEdit = () => setShowEditModal(false);
    const handleShowEdit = () => setShowEditModal(true);
    const[selectedAccount,setSelectedAccount] = useState(null)



    const user = JSON.parse(localStorage.getItem('user'));
    const church = JSON.parse(localStorage.getItem('church'));      

    

    async function getAccountGroups() {
        setIsLoading(true)
        // You can await here
        const res = await makeGetRequest('sample-account-groups');
        if (ResizeObserver && ResizeObserver.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setError(errorMessage)

        } else {
            setAccountGroups(res.data)                  
            setIsLoading(false);

        }       

    }

    const filterByGroups= (e)=>{
        const v = e.target.value;
                    
        if(v === 'all'){
            setFilteredAccounts([]) 
            return;
        }
        
       const results = accounts.filter(group=>group.account_group_id === v)
       setFilteredAccounts(results);
       console.log('by account groups result',results)

    }
    const filterByTypes= (e)=>{
        const v = e.target.value;
        
        if(v === 'all'){
            setFilteredAccounts([]) 
            return;
        }
        
        const  results = accounts.filter(account=>account.sampleAccountType.type === v)
        setFilteredAccounts(results)

       
        
    }

    async function getAccounts() {
        setIsLoading(true)
        // You can await here
        const res = await makeGetRequest('sample-accounts');
        if (ResizeObserver && ResizeObserver.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setError(errorMessage)

        } else {
                              
            setIsLoading(false);
            setAccounts(res.data)
        }       

    }

    async function getAccountTypes() {
        setIsLoading(true)
        // You can await here
        const res = await makeGetRequest('church-account-types');
        if (ResizeObserver && ResizeObserver.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setError(errorMessage)

        } else {
                              
            setIsLoading(false);
            setAccountTypes(res.data)
        }       

    }
    useEffect(()=>{
      
        getAccounts();
        getAccountGroups();
        getAccountTypes();

    },[])



    const removeAccount = async (id) => {
        setError(null);
        const userConfirmed = window.confirm('Are you sure you want to delete this item?');
        
        if (userConfirmed) {
          setIsSubmitting(true);
          const post = await makeDeleteRequest(`/sample-account/${id}`);
          
          if (post && post.errorType) {
            setIsSubmitting(false);
            const errorMessage = ErrorHandler(post);
            setError(errorMessage);
          } else {
            getAccounts();
            setIsSubmitting(false);           
            setIsSuccess(true);
          }
        } else {
          // Handle the case where the user cancels the deletion
          console.log('Deletion cancelled by user');
        }
      };
      

    const editAccount =(account)=>{
        setSelectedAccount(account);
        console.log('Editing account',selectedAccount)

        handleShowEdit()

    }
    const pageTitle = 'New Account';
    const editPageTitle = 'Edit Account';
    const modalBody = <CreateSampleChartOfAccounts pageTitle={pageTitle}/>
    const editModalBody = <EditAccount pageTitle={editPageTitle} account={selectedAccount}/>

    
    return(
        <div className='container-fluid'>
            <CustomModal 
            show={showModal}
            showModal={showModal}
            handleClose={handleClose}
            header={pageTitle}
            body={modalBody}
            size="lg" // You can change the size as needed
            footer=''
            />

          <CustomModal 
            show={showEditModal}
            showModal={showEditModal}
            handleClose={handleCloseEdit}
            header={editPageTitle}
            body={editModalBody}
            size="lg" // You can change the size as needed
            footer=''
            />
             <div className="card">
             <h3 className="text-center"> Sample Chart of Accountsxx</h3>
             {error !== null && <p>Something wrong happened: <span className="text text-danger">{error}</span></p>}
             {isLoading && <CircularProgress color="primary"/>}
             {isSubmitting && <CircularProgress color="warning"/>}
             <div className="row">
                                                <div className="col-md-4">
                                                    {
                                                        accountGroups?.length ?
                                                        <select name="groups" onChange={filterByGroups}>
                                                            <option value="">Filter By Account Group</option>
                                                            {
                                                               accountGroups && accountGroups.map((group)=>{
                                                                    return <option key={group.id} value={group.id}>{toTitleCase(group.account_group)}</option>
                                                                })
                                                            }
                                                        </select>
                                                        :
                                                        <p>No Account Groups <Link to="/create-account-group" className='link-anchor'> CREATE</Link></p>
                                                    }
                                                    </div>
                                                    <div className="col-md-4">
                                                        {
                                                            accountTypes !== null
                                                            ?
                                                            <select name="types" onChange={(e)=>filterByTypes(e)}>
                                                                                                                                                             <option value="all">Filter By Account Types</option>
                                                                                                                                                             {
                                                                                                                                                             accountTypes &&   accountTypes.map((type)=>{
                                                                                                                                             return <option key={type.id} value={type.type}>{type.type}</option>
                                                                                                                                                                })
                                                                                                                                                             }

                                                                </select>
                                                                :
                                                                <p>No account types</p>

                                                        }
                                                    
                                                       
                                                    
                                                    </div>

                                                    <div className='col-md-4'>
                                                    <Button variant="primary" onClick={handleShow}>
                                            <AddModeratorIcon/> New Account
                                                    </Button>
                                                     
                                                    </div>
                                                </div>

                                                <table className="table table-responsive table-striped">
                                                <thead>
                                                    <tr style={{fontSize:"15px"}}>
                                                        
                                                       <th>Account Group</th>
                                                        <th>Account Name</th>
                                                        <th>Account Number</th>
                                                        <th>Account Type</th>
                                                        <th>Sub Type</th>
                                                        <th>Account Activity</th> 
                                                        <th>Fund</th>                                                           
                                                                                                             
                                                        <th>&nbsp;</th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                           accounts &&  accounts?.length !== 0 ?
                                                             filteredAccounts?.length ?
                                                             filteredAccounts.map((account)=>{
                                                                                                                             
                                                                 return <tr key={account.id} style={{fontSize:"15px"}}>
                                                                     <td>{toTitleCase(account.sampleAccountGroup.account_group)}</td>
                                                                     <td>{toTitleCase(account.account_name)}</td>
                                                                     <td>{account.account_number}</td>
                                                                     <td>{toTitleCase(account.sampleAccountType.type)}</td>
                                                                     <td>{account.account_sub_type &&toTitleCase(account.account_sub_type)}</td>
                                                                     <td>{toTitleCase(account.account_activity)}</td>  
                                                                     <td>{account.fund !== null && toTitleCase(account.fund.fund)}</td>                                                      
                                                                                                                                  

                                                                     <td><Link onClick={()=>editAccount(account)}><FaEdit/> </Link></td>

                                                                     <td><Link onClick={(e)=>removeAccount(account.id)}><DeleteForeverIcon/> </Link></td>
     
     
                                                                 </tr>
     
                                                             })
     
                                                            :
                                                           accounts && accounts.map((account)=>{
                                                                                                                             
                                                             return   <tr key={account.id} style={{fontSize:"15px"}}>
                                                             <td>{toTitleCase(account.sampleAccountGroup.account_group)}</td>
                                                             <td>{toTitleCase(account.account_name)}</td>
                                                             <td>{account.account_number}</td>
                                                             <td>{toTitleCase(account.sampleAccountType.type)}</td>
                                                             <td>{account.account_sub_type &&toTitleCase(account.account_sub_type)}</td>
                                                             <td>{toTitleCase(account.account_activity)}</td>  
                                                             <td>{account.fund !== null && toTitleCase(account.fund.fund)}</td>                                                      
                                                                                                                          

                                                             <td><Link onClick={()=>editAccount(account)}><FaEdit/> </Link></td>

                                                             <td><Link onClick={()=>removeAccount(account.id)}><DeleteForeverIcon/> </Link></td>


                                                         </tr>
     
                                                         })
                                                             :
                                                             <p>No Accounts <span><Link to="/create-chart-of-account">Create Account</Link></span></p>

                                                        }
                                                    </tbody>
                                                    </table>

                </div>
           

        </div>
    )
}
export default SampleChartOfAccounts;