import React, { useState} from 'react'
import {Link} from 'react-router-dom'
import { CircularProgress } from '@mui/material';
import {makePostRequest} from '../../../services/httpHelper'
import ErrorHandler from '../../../services/ErrorHandler'
import { SuccessAlert } from '../../../components/ui/UiState';




const CreateTagGroup =()=>{

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [serverErrorMessage,setServerErrorMessage] = useState(null);
    const [validationError,setValidationError]=useState(null)
    const [isSuccess, setIsSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const[group_name,setGroupName] = useState(null);
    const[description,setDescription]= useState(null)
    const user = JSON.parse(localStorage.getItem('user'));
    const church = JSON.parse(localStorage.getItem('church'));
 
 
    const handleChange =(e)=>{
     let name = e.target.name;
     switch(name){
         case 'group_name':
            setGroupName(e.target.value);
             break;
         
         case 'description':
             setDescription(e.target.value);
             break;
            
     }
    }

    const handleSubmit = async(e)=>{
        e.preventDefault();
        setServerErrorMessage(null)
        setValidationError(null)
        setIsSubmitting(true)
        console.log(group_name)
              
        if(group_name === null){
            setValidationError('Enter  Group Name')
            setIsSubmitting(false)
            return;
        }
        if(description === null){
            setValidationError('Enter Description')
            setIsSubmitting(false)
            return;
        }
        const data ={
            group_name:group_name,
            description:description,            
            church_id:church.id,
            user_id:user.id

        }
       
        const post = await makePostRequest('tag-group',data)
        if (post && post.errorType) {
            setIsSubmitting(false);
            const errorMessage = ErrorHandler(post)
            setServerErrorMessage(errorMessage)

        } else {
              
            setGroupName(null)
            setDescription(null)
            setIsSubmitting(false);
            setIsSuccess(true);
           
             }

    }



    return(
      <div className="container-fluid">
       
      <div className="row justify-content-center">
      <div className="col-md-5">
       <div className="card">
         <h3 className="card-title text-center">Create Tag Group</h3>
         <p className="text text-info">
         Department group could have tags like Youth, Women, Children, music  etc. Projects group have tags like outreaches (church planting), education, empowerment etc
         </p>
          <div className="card-body py-md-4">
           <form _lpchecked="1" onSubmit={handleSubmit} className="form-group">
              {serverErrorMessage !== null && <p className="text text-danger">{serverErrorMessage}</p>}
              {isSubmitting && <CircularProgress/>}
                                
            {isSuccess && <SuccessAlert/>}
           
           {validationError !== null &&  <p className="text text-danger">{validationError}</p>} 
                
                              
                 
             <div className="form-group">
                 <input type="text" className="form-control" id="group_name" name="group_name" placeholder="Tag Group Name" onChange={handleChange}/>
      
            </div>

            <div className="form-group">
                 <input type="text" className="form-control" id="description" name="description"  placeholder="description" onChange={handleChange}/>
                 {errors.description && <p className=" text text-danger">{errors.description}</p>}
      
            </div>
                          
              
       <div className="d-flex flex-row align-items-center justify-content-between">
          <Link to="/">Done</Link>
                <button className="btn btn-primary" onClick={handleSubmit}>Save </button>
              </div>
           </form>
         </div>
      </div>
      </div>
      </div>
      </div>
    )
}
export default CreateTagGroup;