import React,{useState,useEffect} from 'react';
import LoadingIcon from '../../components/ui/LoadingIcon';
import { ErrorState } from '../../components/ui/UiState';
import { makeGetRequest,makePostRequest } from '../../services/httpHelper';
import { toTitleCase,removeFromArray } from '../../utils/myFunctions';
import './assignSkills.css'
import SearchIcon from '@mui/icons-material/Search';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { CircularProgress } from '@mui/material';
import ErrorHandler from '../../services/ErrorHandler';
import { Button } from 'react-bootstrap';
import GroupsIcon from '@mui/icons-material/Groups';
import {FaUserAlt } from 'react-icons/fa';
import { AddChurchMemberForm, CreateSkill, CreateUserRoleForm } from '.';
import CustomModal from '../../components/ui/CustomModal';


let user_skills = []; //to store array of skill ids selected by user
const Skills =()=>{
    const[isLoading,setIsLoading]=useState(false);
    const[skills,setSkills]= useState(null);
    const[filteredSkills,setFilteredSkills]= useState([]);
    const[serverError,setServerError] =useState(null);
    const [searchInput, setSearchInput] = useState('');
    const [isFiltered,setIsFiltered] = useState(false)
    const [isSuccess,setIsSuccess] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
          

       
    

   
    const searchSkills = (e) => {
        const searchValue = e.target.value;
        setSearchInput(searchValue.toLowerCase())
        const result = skills.filter((skill)=>{
            return skill.skill.includes(searchInput);
        })
        setFilteredSkills(result);
        
    }
    const handleClearBtn = () => {
        setSearchInput('');
      }
   
    const filterByCategory =(category)=>{
        setIsFiltered(true)
       const results= skills.filter((skill)=>{
            return skill.category===category;
           
        })
        setFilteredSkills(results)

    }

    const getSkills = async()=>{
        try{
            setIsLoading(true)
            const res = await makeGetRequest('skills')
            if(res && res.errorType){
                setIsLoading(false)
                const errorMessage = ErrorHandler(res)
                setServerError(errorMessage)
                
            }else{
                setServerError(null)
                setSkills(res.data);
                setIsLoading(false);
            }

        }catch(error){
            console.log(error)
            setIsLoading(false)
        }
       

    }
    useEffect(()=>{
        getSkills();
    },[])

    const pageTitle ='Add Skills'
    const modalBody = <CreateSkill pageTitle={pageTitle}/>

    if(isLoading && serverError ===null){
        return <LoadingIcon/>
    }
    if(serverError){
        return <ErrorState/>
    }
    

    return(
        <div className="container">
            <CustomModal 
            show={showModal}
            showModal={showModal}
            handleClose={handleClose}
            header={pageTitle}
            body={modalBody}
            size="lg" // You can change the size as needed
            footer=''
            />
               <div className='row'>
                   
                   <div className='col-md-9'>
                 
                   
                   </div>
                   <div className='col-md-3'>
                   <Button variant="primary" onClick={handleShow}>
        New Skill(s)
     </Button>

                   </div>
               </div>

            <div className="card">
                
            <h3>All Skills</h3>
            <div className="search-box">
            <div className="row">
    <div className="col-md-4 ">
    <form id="filter-form">
		<select id="category-filter" className="video-filter" data-filter-group="category" onChange={(e)=>filterByCategory(e.target.value)}>
	   <option value="" selected="selected">All Categories</option>
       {
                skills !== null ?
                skills.map((skill)=>{
                   return <option key={skill.id} value={skill.category}>{toTitleCase(skill.category)}</option>                      
                       
                   
                })
                :
                null
            } 
																				
			</select>
			
			
				

		</form>
      
      
    </div>
    <div className="col-md-3"></div>
    

        <div className="col-md-5 ">
        <div className='input-wrap'>
            <SearchIcon/>
       
        <label 
          htmlFor="product-search" 
          id="input-label"
        >
          Skill Search
        </label>
        <input 
          onChange={searchSkills}
          value={searchInput}
          type="text" 
          name="skill-search" 
          id="skill-search" 
          placeholder="Search Skills"
        />
        
        <BackspaceIcon className="clear-icon" onClick={handleClearBtn}/>
      </div>
        </div>
  </div> 

            </div>
            {isSuccess && <p className="text text-success">{user_skills.length} Skills added to your profile</p>}
   
  {serverError && <p className="text text-danger">Something went wrong: {serverError}</p>}
            {
                searchInput.length>1 || isFiltered ?

                <ol>
                {
                    filteredSkills.map((skill)=>{
                        return <li key={skill.id}>{toTitleCase(skill.skill)}</li>
                    })
                }
            </ol>

               
                :

                skills !== null ?
                <ol>
                    {
                        skills.map((skill)=>{
                            return <li key={skill.id}>{toTitleCase(skill.skill)}</li>
                        })
                    }
                </ol>
                
                :
                <p>No Skills availabe</p>
            }  


      
    
  </div>
           

           
           
        </div>
    )
}

export default Skills;