import React from 'react';
import { Link } from "react-router-dom"

const AddUserSuccess = ()=>{
    return(
        <div className='container text-center'>
            <h3>User Account Created</h3>
            <p>Login Credentials has been emailed to user Email</p>

            <div style={{display:'flex'}}>
            <Link className="btn tbn-default" to='/users' style={{marginRight:'50px'}}>Done</Link>
            <Link className="btn tbn-primary" to='/register-user'>Add More</Link>
          

            </div>
        </div>
    )
}

export default AddUserSuccess;