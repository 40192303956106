import React, { useState, useEffect} from 'react';
import { Link, Navigate,useParams } from 'react-router-dom';
import {makePostRequest,makeGetRequest} from '../../../services/httpHelper'
import ErrorHandler from '../../../services/ErrorHandler';
import { SuccessAlert } from '../../../components/ui/UiState';
import {CircularProgress} from '@mui/material';

const AddSampleAccountGroups = ()=>{

    const [account_group,setAccountGroup] = useState()  
    const [isSubmitting,setIsSubmitting] = useState(false)
    const [serverError,setServerError] = useState(null)
    const [validationError,setValidationError] = useState(null)
    const [isSuccess,setIsSuccess] = useState(false)

    const user = JSON.parse(localStorage.getItem('user'))
    
 
 
    const handleChange =(e)=>{
        setServerError(null)
        setIsSuccess(false)
     setAccountGroup(e.target.value)
    
    }
 
 
 
     const handleSubmit = async(e)=>{
         e.preventDefault();
         setServerError('')
         setValidationError('')
        
         if(account_group === undefined){
             setValidationError('Enter Account Group')
             return;
         }      
 
         const data ={         
             account_group:account_group,
             created_by:user.id
         }
         setIsSubmitting(true)
         const post = await makePostRequest('sample-account-group',data)
         if (post && post.errorType) {
             setIsSubmitting(false);
             const errorMessage = ErrorHandler(post)
             setServerError(errorMessage)
 
         } else {
               
             setIsSubmitting(false);
             setIsSuccess(true);
 
 
         }
 
     }
     
     return (
         <div className="container">
              <div className="card">
         <form onSubmit={handleSubmit} className="form-group">
             <h3 className="text-center">Create Account Group</h3>
             {isSubmitting && <CircularProgress/>}
             {isSuccess && <SuccessAlert/>}
 
             {serverError !== null && !isSubmitting ? <p className="text text-warning">{serverError}</p>  : null}
          {validationError !== null && !isSubmitting ? <p className='text text-danger'>{validationError}</p> : null}
         
         <div className="row jumbotron">
        
            
             <div className=" form-group">
                 <label htmlFor="description">Account Group Name </label>
                 <input type="text" className="form-control" name="account_group"  placeholder="Enter Group Name" onChange={handleChange}/>
 
             </div>   
 
              <div className="col-sm-12 form-group ">
                 <div className="row">
                     <div className="col-md-6">
                    
                     <Link to="/account-groups"> <button className="cancel-btn float-left">Close</button></Link>
 
                     </div>
                     <div className="col-md-6">
                     <button className="btn btn-primary float-right" type="submit">{isSubmitting ? <CircularProgress/> : "Save"}</button>
 
 
                     </div>
                 </div>
             
 
             </div>               
 
             
             
         </div>
         </form>
         </div>
     </div>
     )
}
export default AddSampleAccountGroups;