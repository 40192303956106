import React, { useState, useEffect} from 'react';
import { Link, Navigate,useParams } from 'react-router-dom';
import {makeGetRequest,makePostRequest} from '../../../services/httpHelper'
import ErrorHandler from '../../../services/ErrorHandler';
import { CircularProgress } from '@mui/material';
import { toTitleCase,removeFromArray } from '../../../utils/myFunctions';
import Content from '../../../components/Content'
import {ModalService,ModalRoot,ModalTemplate} from '../../../components/Modal'

let selected =[];
const ImportLibAccounts = ()=>{
    const [accounts, setAccounts] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false);
    const [isImported, setIsImported] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isImportingAll, setIsImportingAll] = useState(false);
    const [serverError, setServerError] = useState(null);   
    const [validationError,setValidationError] = useState(null)
    const[showImportButton,setShowImportButton] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const church = JSON.parse(localStorage.getItem('church')); 

    const handleCheckboxChange=(e)=>{
        
        if(e.target.checked===true){
          
            //add fund when checked
            selected.push(e.target.value)
            if(selected.length>0){
                setShowImportButton(true);
            }
        }else{
            //remove fund on uncheck
           
            removeFromArray(selected,e.target.value) 
        }
        console.log(selected[0])       

    }

    const importAccounts = async(e)=>{
        e.preventDefault();
        setServerError(null)
        setValidationError(null)
        setIsSubmitting(true)
        
        if(!selected.length){
            setIsSubmitting(false)
            setValidationError('No accounts Selected')
            return;
        }
              

        const data ={
            accounts:selected,
            church_id:church.id,
            user_id:user.id,           
           
        }
        setIsSubmitting(true)
        const post = await makePostRequest('import-accounts-from-library',data)
        if (post && post.errorType) {
            setIsSubmitting(false);
            const errorMessage = ErrorHandler(post)
            setServerError(errorMessage)

        } else {
              
            setServerError(null);
            setIsSubmitting(false);
            setIsImported(true);


        }

    }

    const importAllAccounts = async(e)=>{
        e.preventDefault();
        setServerError(null)
        setValidationError(null)
        setIsImportingAll(true)
        
        if(!selected.length){
            setIsImportingAll(false)
            setValidationError('No accounts Selected')
            return;
        }
              

        const data ={
            accounts:selected,
            church_id:church.id,
            user_id:user.id,           
           
        }
        setIsImportingAll(true)
        const req = await makeGetRequest('import-all-accounts-from-library')
        if (req && req.errorType) {
            setIsImportingAll(false);
            const errorMessage = ErrorHandler(req)
            setServerError(errorMessage)

        } else {
              
            setServerError(null);
            setIsImportingAll(false);
            setIsImported(true);


        }

    }

    async function getAccounts() {
        setIsLoading(true)
        // You can await here
        const res = await makeGetRequest('sample-accounts');
        if (ResizeObserver && ResizeObserver.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setServerError(errorMessage)

        } else {
            setAccounts(res.data)                  
            setIsLoading(false);
            setIsSuccess(true);
            console.log('accounts',res.data)
            return;
        }       

    }

    
    useEffect(()=>{
        getAccounts();

    },[])

    

    return(
        <div className='container'>
            {validationError && <Content type="error" message={validationError}/>}
            {serverError !== null && <p>Something wrong happened: <span className="text text-danger">{serverError}</span></p>}
             {isLoading && <CircularProgress color="primary"/>}
             {isImported && <Navigate to="/chart-of-accounts"/>}
               {
                            isSuccess ?
                                <div className="card">
                                    
                                            <h3 className="text-center"> Import Chart of Accounts</h3>
                                        <div className="row">
                                            <div className="col-md-3">

                                                </div>

                                                <div className="col-md-3">

                                                </div>
                                                <div className="col-md-3">

                                                </div>
                                                <div className="col-md-3">
                                                    {
                                                    showImportButton ?
                                                    <button type="button" className="btn btn-info" onClick={importAccounts}>{isSubmitting ? <CircularProgress/>: "Import"} </button>
                                                        :
                                                        null
                                                    }

                                                </div>
                                            </div>
                                            {
                                                accounts.length>0 ?
                                                <table className="table table-responsive table-striped">
                                                <thead>
                                                    <tr style={{fontSize:"15px"}}>
                                                        
                                                        <th>Account</th>
                                                        <th>Account Type</th>
                                                        <th>Account Group</th>
                                                        <th>Sub Group</th>
                                                        <th>Account Number</th>
                                                        <th>Account Activity</th>
                                                        <th>Select To Import</th>
                                                        
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        
                                                       accounts.map((account)=>{
                                                        return <tr key={account.id} style={{fontSize:"15px"}}>
                                                                     <td>{toTitleCase(account.account_name)}</td>
                                                                     <td>{toTitleCase(account.sample_account_type.type)}</td>
                                                                     <td>{account.sample_account_group.account_group}</td>
                                                                     <td>{account.account_sub_group}</td>
                                                                     <td>{account.account_number}</td>
                                                                     <td>{toTitleCase(account.account_activity)}</td>                                                        
                                                                     <td><input type='checkbox' name="account" value={account.id} onChange={handleCheckboxChange}/> </td>                                                                                                                                 
     
     
                                                                 </tr>
                                                                                                                        
                                                      

                                                    })
                                                    }

                                                    </tbody>
                                                   
                                                
                                            </table>
                                            :
                                            <div>

                                            <p>No accounts to Import <span><Link to="/create-account" className="btn btn-info">Create Account</Link></span></p>
                                                    </div>
                                            }
                                           
                                            

                                      

                                </div>
                                :
                                <div>
                                    {serverError && <p className="text text-danger">{serverError}</p>}



                                </div>
                        }

        </div>
    )
}
export default ImportLibAccounts;