import React, { useState, useEffect} from 'react';
import { Link, Navigate,useParams } from 'react-router-dom';
import {makeGetRequest} from '../../../services/httpHelper'
import ErrorHandler from '../../../services/ErrorHandler';
import { LoadingState,ErrorState,BackgrounLoadingState } from '../../../components/ui/UiState';
import { CircularProgress } from '@mui/material';
import { toTitleCase } from '../../../utils/myFunctions';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CurrencyFormat from 'react-currency-format';

const ManageTagGroups = ()=>{
    const [tagGroups, setTagGroups] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false);
    const [serverErrorMessage, setServerErrorMessage] = useState(null);   


    const church = JSON.parse(localStorage.getItem('church'));
      

    async function getTagGroups() {
        setIsLoading(true)
        // You can await here
        const res = await makeGetRequest('tag-groups');
        if (ResizeObserver && ResizeObserver.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setServerErrorMessage(errorMessage)

        } else {
            const groups = await res.data
            setTagGroups(groups)                  
            setIsLoading(false);
            setIsSuccess(true);
                       
        }       

    }

    
    useEffect(()=>{
        getTagGroups();

    },[])

    

    return(
        <div className='container-fluid'>
            {serverErrorMessage !== null && <p>Something wrong happened: <span className="text text-danger">{serverErrorMessage}</span></p>}
             {isLoading && <CircularProgress color="primary"/>}
               {
                            isSuccess ?
                                <div className="">
                                    
                                            <h3 className="text-center"> Tag Groups</h3>
                                           
                                            {
                                                tagGroups !==null ?
                                                <table className="table table-responsive table-striped">
                                                <thead>
                                                    <tr style={{fontSize:"15px"}}>
                                                        
                                                       <th>Group </th>
                                                        <th>Description</th>
                                                        <th>Tags</th>                                                                                                               
                                                        <th>&nbsp;</th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        tagGroups.map((tag)=>{
                                                                                                                        
                                                            return <tr key={tag.id} style={{fontSize:"15px"}}>
                                                                <td>{tag.group_name!==null && toTitleCase(tag.group_name)}</td>
                                                                <td>{tag.description!==null && toTitleCase(tag.description)}</td>
                                                                <td>({tag.tags.length})</td>

                                                                <td><AccountBoxIcon/></td>
                                                                <td><DeleteForeverIcon/></td>


                                                            </tr>

                                                        })

                                                      
                                                    }

                                                    </tbody>
                                                   
                                                
                                            </table>
                                            :
                                            <p>No tag groups <span><Link to="/create-tag-group" className="btn btn-info">Create Tag Group</Link></span></p>
                                            }
                                           
                                            

                                      

                                </div>
                                :
                                <div>
                                    {serverErrorMessage && <p className="text text-danger">{serverErrorMessage}</p>}



                                </div>
                        }

        </div>
    )
}
export default ManageTagGroups;