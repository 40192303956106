import { useState, useEffect } from 'react';
import { makePostRequest } from '../../../services/httpHelper';

let initial_backend_errors = [];

const useCreateUserRole = (callback, validateCreateRole) => {
    const [values, setValues] = useState({
        name: '',
        description: '',


    });
    let validation_errors = {};
    const [errors, setErrors] = useState(validation_errors);
    const [serverValidationErrors, setServerValidationErrors] = useState(initial_backend_errors);

    const [res, setResp] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);


    const [serverErrorMessage, setServerErrorMessage] = useState(null);
    const handleChange = (e) => {
        setValues({
            ...values,

            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setServerErrorMessage(null);
        setIsSubmitting(true)
        const validate = validateCreateRole(values);
        validation_errors.name = validate.name;
        validation_errors.description = validate.description


        if (Object.keys(errors).length === 0) {
            const res = await makePostRequest('role', { name: values.name, description: values.description });
            if (res && res.errorType) {
                setIsSubmitting(false);
                if (res.errorType === 'validation') {
                    const validationErrors = res.validationErrors;
                    let backend_errors = [];
                    validationErrors.map((error, index) => {
                        backend_errors.push(error[0].msg)
                        initial_backend_errors.push(error.msg)

                    })
                    const newServerErrors = serverValidationErrors.concat(backend_errors);
                    setServerValidationErrors(newServerErrors);


                }
                else if (res.errorType === 'authentication') {
                    setServerErrorMessage(res.errorMessage);

                }

                else if (res.errorType === 'authorization') {
                    setServerErrorMessage(res.errorMessage);

                }
                else if (res.errorType === 'badrequest') {
                    setServerErrorMessage(res.errorMessage);
                } else {
                    setServerErrorMessage(res.errorMessage);
                }
            } else {
                console.log(res)
                setIsSubmitting(false);
                setIsSuccess(true)
                setServerErrorMessage(null)
            }
        } else {
            setIsSubmitting(false);
        }





    }
    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitted) {

            callback();
        }
    }, [errors]);

    return { handleChange, values, handleSubmit, errors, serverValidationErrors, serverErrorMessage, isSubmitting, isSuccess };
}
export default useCreateUserRole;