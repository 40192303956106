import React, { createContext,useState, memo } from 'react';

export const SnackbarContext = createContext({
  setSnackbar: () => {
  },
  snackbar: {}
});

	
	
export const SnackbarValueContext = createContext({});
export const SnackbarSetContext = createContext(() => {});

const SnackbarProvider = memo(({ setSnackbar, children }) => {
    console.log('SnackbarProvider refresh');
   
    const handleSnackbarSet = (message, type = 'default') => {
      setSnackbar({
        message, type
      })
    };
   
    return (
      <SnackbarSetContext.Provider value={handleSnackbarSet}>
        {children}
      </SnackbarSetContext.Provider>
    )
  });
   
  
 
export const SnackbarContainer = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    message: '',
    type: 'default'
  });
 
  const handleSnackbarSet = (message, type = 'default') => {
    setSnackbar({
      message, type
    })
  };
 
  return (
    <SnackbarValueContext.Provider value={snackbar}>
      <SnackbarSetContext.Provider value={handleSnackbarSet}>
        {children}
      </SnackbarSetContext.Provider>
    </SnackbarValueContext.Provider>
  )
};