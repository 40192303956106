import React, { useState, useEffect} from 'react';
import { Link, Navigate,useParams } from 'react-router-dom';
import {makeGetRequest} from '../../../services/httpHelper'
import ErrorHandler from '../../../services/ErrorHandler';
import { CircularProgress } from '@mui/material';
import { toTitleCase } from '../../../utils/myFunctions';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CustomModal from '../../../components/ui/CustomModal';
import { Button } from 'react-bootstrap';
import CreateTag from './CreateTag';

const ManageTags = ()=>{
    const [tags, setTags] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false);
    const [serverErrorMessage, setServerErrorMessage] = useState(null);  
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true); 


   

    
    useEffect(()=>{
        async function getTags() {
            setIsLoading(true)
            // You can await here
            const res = await makeGetRequest('tag-groups');
            if (ResizeObserver && ResizeObserver.errorType) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res)
                setServerErrorMessage(errorMessage)
    
            } else {
                setTags(res.data)  
                console.log(tags)                
                setIsLoading(false);
                setIsSuccess(true);
                
            }       
    
        }
        getTags();

    },[])

    const pageTitle ='Create Tags'
    const modalBody = <CreateTag pageTitle={pageTitle}/>

    

    return(
        <div className='container-fluid'>

<CustomModal 
            show={showModal}
            showModal={showModal}
            handleClose={handleClose}
            header={pageTitle}
            body={modalBody}
            size="md" // You can change the size as needed
            footer=''
            />
                        
                    <div className='row'>
                   
                   <div className='col-md-9'>
                 
                   
                   </div>
                   <div className='col-md-3'>
                   <Button variant="primary" onClick={handleShow}>
        Create Tags
     </Button>
     </div>
     </div>
            {serverErrorMessage !== null && <p>Something wrong happened: <span className="text text-danger">{serverErrorMessage}</span></p>}
             {isLoading && <CircularProgress color="primary"/>}
               {
                            isSuccess ?
                                <div className="card">
                                    
                                            <h3 className="text-center"> Tag Groups</h3>
                                           
                                            {
                                                tags !==null ?
                                                <table className="table table-responsive table-striped">
                                                <thead>
                                                    <tr style={{fontSize:"15px"}}>
                                                        
                                                      
                                                        <th>Tag Group</th>  
                                                        <th>description</th>                                                                                                                 
                                                     
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        tags.map((tag)=>{
                                                                                                                        
                                                            return <tr key={tag.id} style={{fontSize:"15px"}}>
                                                                <td>{tag.group_name!==null && toTitleCase(tag.group_name)}</td>
                                                                <td>{tag.description!==null && toTitleCase(tag.description)}</td>

                                                             

                                                            </tr>

                                                        })

                                                      
                                                    }

                                                    </tbody>
                                                   
                                                
                                            </table>
                                            :
                                            <p>No tags <span><Link to="/create-tag" className="btn btn-info">Create Tag</Link></span></p>
                                            }
                                           
                                            

                                      

                                </div>
                                :
                                <div>
                                    {serverErrorMessage && <p className="text text-danger">{serverErrorMessage}</p>}



                                </div>
                        }

        </div>
    )
}
export default ManageTags;