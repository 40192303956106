import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingIcon from '../../components/ui/LoadingIcon';
import { makeGetRequest } from '../../services/httpHelper';
import ErrorHandler from '../../services/ErrorHandler';
import { Link } from 'react-router-dom';


const BlogCategories = () => {

    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState(null);
          
    
    useEffect(() => {
     
        async function getBlogCategories() {
            //const church = JSON.parse(localStorage.getItem('church'));
            setIsLoading(true)
            // You can await here
            const res = await makeGetRequest(`blog-post-categories`);
            if (res && res.errorType) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res)
                setError(errorMessage)
    
            } else {
                setCategories(res.data)
                setIsLoading(false);
                setIsSuccess(true)
    
            } 

        }
        
        getBlogCategories();      
       


    }, []);
    return (
        <>
                        
                    <div className="container">
                    <Link to='/new-post-category' className='btn' style={{width:'130px', marginLeft:'75%'}}>New Category</Link>

                        {
                            isSuccess ?
                                <div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h4 className="text-center"> Post Categories</h4>
                                            <div>
                                                {
                                                    categories.length === 0 ?
                                                    <p className='text text-info'>No categories</p>
                                                    :
                                                    categories.map((cat) => {
                                                        return (
                                                            <Accordion key={cat.id}>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography>{cat.category.toUpperCase()}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Typography>
                                                                        <div className='row'>
                                                                            <div className="col-md-8">
                                                                                {cat.description}
                                                                            </div>
                                                                           

                                                                        </div>


                                                                    </Typography>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        )
                                                    })

                                                }
                                                   
                                                



                                            </div>

                                        </div>

                                        
                                    </div>

                                </div>
                                :
                                <div>
                                    {isLoading && error === null && <LoadingIcon />}
                                    {error && <p className="text text-danger">{error}</p>}



                                </div>
                        }


                    </div>                
                   
            


        </>


    );
}
export default BlogCategories;