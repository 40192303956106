import React, {useEffect, useState} from 'react';
import './UserProfile.css';
import { CircularProgress } from '@mui/material';
import Moment from 'react-moment';
import 'moment-timezone';
import { IMAGE_BASE_URL } from '../../../constants/paths';
import { makeGetRequest } from '../../../services/httpHelper';
import { useParams } from 'react-router-dom';
import Avatar from '../../../assets/avatar.jpg'


const UserProfile = ()=>{
	const [isOnline, setIsOnline] = useState(false);
	const [user,setUser] = useState(null);
	const [error,setError] =useState(null);
	const[isLoading,setIsLoading] =useState(false)
	const[isSuccess,setIsSuccess] =useState(false)


	const {id} = useParams()

	async function getUser() {
		setIsLoading(true)
		// You can await here
		const res = await makeGetRequest(`user/${id}`);
		if (res && res.errorType) {
			setIsLoading(false);               
	
			 if (res.errorType === 'authorization') {
				setError(res.errorMessage);
	
			}
			else if (res.errorType === 'badrequest') {
				setError(res.errorMessage);
			} else {
				setError(res.errorMessage);
			}
		} else {
			const result = await res.data;
			console.log(result)
			setUser(result);
					
			setIsLoading(false);
			setIsSuccess(true);
	
	
		}
	
	}
	

	useEffect(() => {
     
       
        
		getUser();      
       


    }, [id]);



	if(isLoading){
		return <div className='container text-center'>
			<CircularProgress/>
		</div>
	}
	if(error !== null){
		return <div className='container text-center'>
			<p className='text etxt-danger'>{error}</p>
		</div> 
	}
    return(
        <div id="user-profile-2" className="user-profile">			
				<div className="tabbable">
		
			<div className="tab-content no-border padding-24">
			<div id="home" className="tab-pane in active">
			<div className="row">
						<div className="col-md-3 center">
							<span className="profile-picture">
							{
								/*
								<img width="150" height="100" src={user.avatar===null ? Avatar : IMAGE_BASE_URL+user.avatar}/>
								*/
							}
							</span>
							<div className="space space-4"></div>
							<p>
							<span className="label label-purple arrowed-in-right">
								{
									isOnline ?
									<i className="fa fa-circle smaller-80 align-middle online-icon"></i>
									:
									<i className="fa fa-circle smaller-80 align-middle offline-icon"></i>
								}
									
								
								</span>
							</p>

						
						</div>

						<div className="col-xs-12 col-md-9">
							<div className="profile-header">
							<h4>{user.first_name} {user.last_name}</h4>			
							
							</div>
							
							
							<div className="profile-user-info">							
								
								<div className="profile-info-row">
									<div className="profile-info-name"> User Name </div>

									<div className="profile-info-value">
										<span>{user.email}</span>
									</div>
								</div>
								<div className="profile-info-row">
									<div className="profile-info-name"> Phone </div>

									<div className="profile-info-value">
										<span>{user.mobile_number}</span>
									</div>
								</div>
								<div className="profile-info-row">
									<div className="profile-info-name"> Sex </div>

									<div className="profile-info-value">
										<span>{user.sex}</span>
									</div>
								</div>

								<div className="profile-info-row">
									<div className="profile-info-name"> Email </div>

									<div className="profile-info-value">
										<span>{user.email}</span>
									</div>
								</div>
								<div className="profile-info-row">
									<div className="profile-info-name"> Account Verified?</div>

									<div className="profile-info-value">
										<span>{user.account_verified ? 'Yes':'No'}</span>
									</div>
								</div>
								<div className="profile-info-row">
									<div className="profile-info-name"> Account Blocked </div>

									<div className="profile-info-value">
										<span>{user.blocked ? "Yes":"No"}</span>
									</div>
								</div>
								

								<div className="profile-info-row">
									<div className="profile-info-name"> Location </div>

									<div className="profile-info-value">
										<i className="fa fa-map-marker light-orange bigger-110"></i>
										<span>{user.countryCode}</span>
										<span>{user.gpsLocation}</span>
									</div>
								</div>

								

								<div className="profile-info-row">
									<div className="profile-info-name"> Date Account Created </div>

									<div className="profile-info-value">
										<span> <Moment toNow>{user.createdAt}</Moment></span>
									</div>
								</div>

								<div className="profile-info-row">
									<div className="profile-info-name"> Last Online </div>

									<div className="profile-info-value">
										<span>{user.last_logged_in} </span>
									</div>
								</div>
							</div>

							<div className="hr hr-8 dotted"></div>

							
						</div>
					</div>
			</div>
			</div>
		</div>
		
		
	</div>
    );
}
export default UserProfile;