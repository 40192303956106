import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FaList } from 'react-icons/fa';
import { makeDeleteRequest, makeGetRequest, makePostRequest } from '../../../services/httpHelper';
import { isEmail, isMobile } from '../../../utils/formValidator';
import { useParams } from 'react-router-dom';
import ErrorHandler from '../../../services/ErrorHandler';





let roles_to_assign = [];
let userData = [];
const AssignRole = () => {

    //GET USER ROLES
    const [roles, setRoles] = useState();
    const [userRoles, setUserRoles] = useState();
    const [availableRoles, setAvailableRoles] = useState(roles_to_assign);
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isRevoked, setIsRevoked] = useState(false);

    const [roleId, setRoleId] = useState(null)
    const [error, setError] = useState(null);
    const [user, setUser] = useState(userData)
    const [isAssigning, setIsAssigning] = useState(false);
    const [roleAssigned, setRoleAssigned] = useState(false);
    const [isRevoking, setIsRevoking] = useState(false);
    const [roleRevoked, setRoleRevoked] = useState(false);

    const {userId} = useParams();

    
    
    async function revokeRole(e){
        setIsRevoked(false)
        setError(null)

        if(userRoles.length===1){
            setError('You cannot revoke the only role. User must have more than one role for revocation to work');
            return;
        }
        setRoleId(e.target.value)
        const res = await makeDeleteRequest(`revoke-role/${e.target.value}/${userId}`);
        if(!res.data){
            setIsLoading(false);
            setIsRevoked(false)

            if(typeof res==='object' || typeof res === null || res?.length>50){
              setError('Something went wrong!')
            }else{
              setError(res);
            }
          } else {

            setUserRoles((userRoles) => userRoles.filter(role => role.id !== roleId));
            const unAssignedRoles = roles.filter(role => !userRoles.some(userRole => userRole.id === role.id));
            setAvailableRoles(unAssignedRoles)


            setRoleRevoked(true);
            setIsRevoked(true)


        }

    }

    
    async function  assignRole(id) {
        setIsAssigning(true);
        setError(null);
        setIsSuccess(false);
        setRoleId(id);
       const alreadyAssigned =  userRoles.filter((role)=>role.id===id)
       if(alreadyAssigned){
        setError('Role already assigned')
       }

     
        const res = await makePostRequest('assign-single-role',{roleId:id,userId:userId});
        if(!res.data){
            setIsLoading(false);
            setIsAssigning(false);
            if(typeof res==='object' || typeof res === null || res?.length>50){
              setError('Something went wrong!')
            }else{
              setError(res);
            }
          } else {

            setUserRoles((userRoles) => [...userRoles, alreadyAssigned]);

           
            // Filter out the roles that are not assigned to the user
         
            const unAssignedRoles = roles.filter(role => !userRoles.some(userRole => userRole.id === role.id));
       
            setAvailableRoles(unAssignedRoles)

            setIsAssigning(false);
            setRoleAssigned(true);
            setIsSuccess(true)
           


        }
    }

   

    useEffect(() => {
    
        async function fetchAllRoles() {
         //   if(role_data.length !==0) return;
            setIsLoading(true)
            // You can await here
            const res = await makeGetRequest('roles');
            if(!res.data){
                setIsLoading(false);
                if(typeof res==='object' || typeof res === null || res?.length>50){
                  setError('Something went wrong!')
                }else{
                  setError(res);
                }
              } else {
             
                setRoles(res.data.roles)

                setIsLoading(false);
               



            }

        }

        async function getUserRoles() {
            
            setIsLoading(true)
         
    
            // You can await here
            const res = await makeGetRequest(`user-roles/${userId}`);
            if(!res.data){
                setIsubmitting(false);
                if(typeof res==='object' || typeof res === null || res?.length>50){
                  setError('Something went wrong!')
                }else{
                  setError(res);
                }
              } else {
               
                 setUserRoles(res.data);
                setIsubmitting(false);
    
            }
    
    
    
        }

        const fetchUserProfile = async()=>{
            setIsLoading(true)
            const res = await makeGetRequest('church-user/'+userId)
            if(res && res.errorType){
                setIsLoading(false)
                const errorMessage = ErrorHandler(res)
                setError(errorMessage)
                
            }else{
                setError(null)
                setIsLoading(false);
                setUser(res.data)
    
            }
    
        }
        
         fetchAllRoles();  
         getUserRoles();
         fetchUserProfile();    
         // Filter out the roles that are not assigned to the user
         if(roles !== undefined){
            const unAssignedRoles = roles.filter(role => !userRoles.some(userRole => userRole.id === role.id));
       
            setAvailableRoles(unAssignedRoles)
         }


    }, []);
    return (
        <>

        <div className='container card'>
        <h3 className='text-center'>Manage {user.first_name} {user.last_name} Roles</h3>

        {error !== null && <p className='text text-danger'>{error}</p>}
        {isSuccess  && <p className='text text-success'>Role Assigned</p>}
        {isRevoked  && <p className='text text-success'>Role Revoked</p>}



        <div className='row'>
            <div className='col-md-5'>
            <h4 className="text-center">Available Roles</h4>
            {
                availableRoles.length !==0 ?
                availableRoles.map((role) => {
                    return (
                        <Accordion key={role.id}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>{role.name.toUpperCase()}</Typography>
                            </AccordionSummary>
<AccordionDetails>
<Typography>
<div className='row'>
<div className="col-md-8">
{role.description}
</div>
<div className="col-md-4">
{
!isAssigning ?
                                        


<button type="button" className="btn btn-info btn-sm" value={role.id}   onClick={(id)=>assignRole(role.id)}>Assign</button>
                                                
:
<CircularProgress color='secondary'/>

}

</div>

</div>


</Typography>
</AccordionDetails>
</Accordion>
)
})

                :
                <p className='text text-warning'>No Un-Assigned Roles</p>
            }

            </div>

            <div className='col-md-2'>
                
            </div>
            <div className='col-md-5'>
            <h4 className="text-center">Current Roles</h4>

            {
                    userRoles !== undefined ?
                    <div>
                {
            userRoles.map((role) => {

                return (
                <Accordion key={role.id}>
                <AccordionSummary
             expandIcon={<FaList />}
            aria-controls="panel1a-content"
             id="panel1a-header">
        <Typography>{role.name.toUpperCase()}</Typography>
            </AccordionSummary>
        <AccordionDetails>
    <Typography>
         <div className='row'>
         <div className="col-md-8">
         {role.description}
    </div>
    <div className="col-md-4">
     {
   

    roleRevoked ?
<button type="button" className="btn btn-info btn-sm" value={role.id}   onClick={(e)=>assignRole(e)}>Assign</button>
    :

 <button type="button" className="btn btn-danger btn-sm" value={role.id} onClick={(e)=>revokeRole(e)}>Revoke</button>
  

     }
     </div>
 </div>


</Typography>
 </AccordionDetails>
 </Accordion>
     )
 })}
</div>
    :
    <p>No Roles {user.id}</p>
         }
                
            </div>
        </div>
        </div>
    

                  
          


        </>


    );
}
export default AssignRole;