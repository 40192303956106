import {useState,useEffect} from 'react';
import {makeGetRequest, makePostRequest} from '../../services/httpHelper';
import ErrorHandler from '../../services/ErrorHandler';


const useSubscribe = (callback,validateSubscriptionForm,id) =>{
    const [values, setValues] = useState({
        country_code:'',
        first_name:'',
        last_name:'',
        email:'',
        password:'',
        password2:'',
        address_line_1:'',
        address_line_2:'',
        phone:'',
        church_name:'',
        postal_code:'',
        city:''
       
        

    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [features, setFeatures] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [products,setProducts] = useState(null);
    const [countries, setCountries] = useState(null);
    const [plan,setPlan]=useState(null);





    const handleChange = (e) =>{
      
        setValues({
            ...values,
           
            [e.target.name]:e.target.value
        })
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        setIsSubmitting(true)
        setIsSuccess(false)
        setErrors(validateSubscriptionForm(values));

       /* if(Object.keys(errors).length!==0){
            return;

        }*/


        const user = JSON.parse(localStorage.getItem('user'))
        const data ={
            plan:plan,
            country_code:values.country_code,
            first_name:values.first_name,
            last_name:values.last_name,
            email:values.email,
            phone:values.phone,
            church_name:values.church_name,
            address_line_1:values.address_line_1,
            address_line_2:values.address_line_2,
            password:values.password,
            postal_code:values.postal_code,
            city:values.city,
            created_by:user.first_name+' '+user.last_name,
            user_id:user.id

        }
        const res = await makePostRequest(`paypal-subscribe/${plan.id}`,data);
        console.log('ERROR...',res)
        if(res.errorMessage.length){
            setIsSubmitting(false);
            setError(res.errorMessage);
            
       
        }else{     

          

            setIsSubmitting(false);
            setIsSuccess(true);
            setIsSubmitted(true);
          }
            
        
    }

    const getPlan = async()=>{
        setIsLoading(true);
        const res = await makeGetRequest('subscription-plan/'+id);
        if (res && res.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setError(errorMessage)
    
        } else {
            const result = await res.data;
            let assignedFeatures =[];
            let currentPlan = null;
            result.forEach((result)=>{
              assignedFeatures.push(result);
                result.features.map((p)=>{
                    if(currentPlan===null){
                        currentPlan = p;
                        setPlan(p)
                    }
                })
                
    
            })
            setFeatures(assignedFeatures)        
            setIsLoading(false);
            setError(null)           
    
        }
       
    }

    const getCountries = async()=>{
        setIsLoading(true);
        const res = await makeGetRequest('countries');
        if (res && res.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setError(errorMessage)
    
        } else {
            const result = await res.data;
            setCountries(result);   
            setIsLoading(false);
            setError(null)           
    
        }
       
    }
    useEffect(()=>{
        getPlan();
        getCountries();
        if(Object.keys(errors).length===0 && isSubmitted){
           callback();
        }
    },[errors]);
    return {handleChange,values,handleSubmit,errors,error,isSubmitting,isSuccess,features,isLoading,countries,plan};
}
export default useSubscribe;