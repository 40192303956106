import React from 'react';
import {  FaArrowUp, FaArrowDown } from 'react-icons/fa';

const DashboardSmallCard =({title,subtitle,value,currency=null,footer=null,droped=false})=>{
    return(
        <div className='dashboard-card-small'>
            <div className='row'>
                    <div className='col-md-12'>
                    <h3 className='dashb-small-card-title'> {title} </h3>
                    <p className='dashb-small-card-value'>{currency!==null && <span className='dashb-small-card-currency'>{currency}</span>}{value}</p>
                    <p className='dashb-small-card-subtitle'>{droped ? <FaArrowDown/> : <FaArrowUp/>} {subtitle}</p>
                    {footer!==null && <p className='dashb-small-card-footer-text'>{footer}</p>}
                      
                    </div>
                    
                     
                  </div>

        </div>
    )

}
export default DashboardSmallCard;