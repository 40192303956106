import {useState,useEffect} from 'react';
import {makePostRequest} from '../../../../services/httpHelper';

let initial_backend_errors =[];
const useVerifyChurchUserMobileNmber = (callback,validateVerifyMobileNmber,mobile_number) =>{
    const [values, setValues] = useState({
        otp:'',
        first:'',
        second:'',
        third:'',
        fourth:'',
        fifth:'',
        sixth:''
    });
    const [errors, setErrors] = useState({});
    const [isSubmitted, setisSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [serverError,setServerError] = useState();
    const [isSuccess,setIsSuccess] = useState(false);
    const [serverValidationErrors, setServerValidationErrors] = useState(initial_backend_errors);
    const [serverErrorMessage,setServerErrorMessage] = useState(null);

    const handleChange = (e) =>{
        setValues({
            ...values,
            [e.target.name]:e.target.value
           
        })  
        
        const allowedIds =[
          'input-1',
          'input-2',
          'input-3',
          'input-4',
          'input-5',
          'input-6',
        ]
        let inputid = e.target.id;
        const id = inputid.split('-')[0];
        let idIndex = parseInt(inputid.split('-')[1])
        const nextInputIndex = idIndex+1
        let nextInputId = id+'-'+nextInputIndex.toString()
        const nextSibiling = document.getElementById(nextInputId);
        
        if(allowedIds.includes(nextInputId)){
          nextSibiling.focus();
        }
       
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        setIsSubmitting(true);
        setErrors(validateVerifyMobileNmber(values));
        const verificationCode = values.first+values.second+values.third+values.fourth+values.fifth+values.sixth;
        console.log('verificationCode',verificationCode)
        const data ={
            verificationCode:verificationCode,
            mobile_number:mobile_number
        }
        const res = await makePostRequest('verify-church-user-mobile-number',data)
      
        if(res && res.errorType){
            setIsSubmitting(false);
          if(res.errorType === 'validation'){
            const validationErrors = res.validationErrors;
            let backend_errors = [];  
            validationErrors.map((error,index)=>{
              backend_errors.push(error[0].msg)
              initial_backend_errors.push(error.msg)           
    
            })
            const newServerErrors = serverValidationErrors.concat(backend_errors);
            setServerValidationErrors(newServerErrors);
  
  
          }
          else if(res.errorType === 'authentication'){
            setServerErrorMessage(res.errorMessage);
           
          }
         
       else if(res.errorType==='authorization'){
        setServerErrorMessage(res.errorMessage);        
          
        }
        else if(res.errorType === 'badrequest'){
          setServerErrorMessage(res.errorMessage);
        }else{
          setServerErrorMessage(res.errorMessage);
        }
          }else{          
           localStorage.setItem('newUserId',res.data)
            setIsSubmitting(false);
            setIsSuccess(true)
          }        
        
    }
    useEffect(()=>{
        if(Object.keys(errors).length===0 && isSubmitted){
           callback();
        }
    },[errors]);
    return {handleChange,values,handleSubmit,errors,isSubmitting,serverError,isSuccess,serverValidationErrors,serverErrorMessage};
}
export default useVerifyChurchUserMobileNmber;