import {useState,useEffect} from 'react';
import {makeGetRequest, makeMultipartFormPostRequest, makePostRequest} from '../../services/httpHelper';
import ErrorHandler from '../../services/ErrorHandler';


let initial_backend_errors =[];
const useCreateBlogPost = (callback,validateCreatePostForm,editor) =>{
    const [values, setValues] = useState({
        title:'',
        content:'',
        tags:'',
        category_id:'',
        featuredImage:null
        

    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [serverValidationErrors, setServerValidationErrors] = useState(initial_backend_errors);
    const [error,setError] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const[categories,setCategories] = useState(null);
    const [isLoading,setIsLoading] = useState(false);
    const [featuredImage,setFeaturedImage] = useState(null);



    const handleChange = (e) =>{
        setValues({
            ...values,
            
           
            [e.target.name]: e.target.value
        })
    }
let selectedFile = null;
    const handleFileChange = (e) => {
     selectedFile = e.target.files[0];
     setFeaturedImage(selectedFile)
  
     
    };
    const handleSubmit = async(e) =>{
        e.preventDefault();
        setIsSubmitting(true)
        setValues({
          ...values,
          ['content']:editor.current.getContent()
        })

      
        setErrors(validateCreatePostForm(values));

        if(!editor.current.getContent()){
          setError('Enter post contents')
        }
        const church = JSON.parse(localStorage.getItem('church'));
        const user = JSON.parse(localStorage.getItem('user'));
        // Create a FormData object
    const formDataToSend = new FormData();

       /* const data ={
            category_id:values.category_id,
            title:values.title,
            tags:values.tags,
            content:editor.current.getContent(),
            featuredImage:values.featuredImage,
            church_id:church.id,
            user_id:user.id

        }*/
         // Append text input value to FormData
    formDataToSend.append('title', values.title);
    formDataToSend.append('category_id', values.category_id);
    formDataToSend.append('tags', values.tags);
    formDataToSend.append('content', editor.current.getContent()),
    //formDataToSend.append('church_id', church.id);
    formDataToSend.append('user_id', user.id);
    formDataToSend.append('featuredImage', featuredImage);
        const res = await makeMultipartFormPostRequest('blog-post',formDataToSend);
        if(res && res.errorType){
            setIsSubmitting(false);
          if(res.errorType === 'validation'){
            const validationErrors = res.validationErrors.errors;
            let backend_errors = [];  
            validationErrors.map((error,index)=>{
              backend_errors.push(error.msg)
              return initial_backend_errors.push(error.msg)           
    
            })
            const newServerErrors = serverValidationErrors.concat(backend_errors);
            setServerValidationErrors(newServerErrors);
  
          }
          else if(res.errorType === 'authentication'){
            setError(res.errorMessage);
           
          }
          else if(res.errorType === 'validation'){
            setError(res.errorMessage);
           
          }
         
       else if(res.errorType==='authorization'){
        setError(res.errorMessage);        
          
        }
        else if(res.errorType === 'badrequest'){
          setError(res.errorMessage);
        }else{
          setError(res.errorMessage);
        }
          }else{     

          

            setIsSubmitting(false);
            setIsSuccess(true);
            setIsSubmitted(true);
          }
            
        
    }

    const getPostCategries = async()=>{
        setIsLoading(true);
        const res = await makeGetRequest('blog-post-categories');
        if (res && res.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setError(errorMessage)

        } else {
            setCategories(res.data)        
            setIsLoading(false);
            setError(null)

        }
       
    }
    useEffect(()=>{
        getPostCategries();
        if(Object.keys(errors).length===0 && isSubmitted){
           callback();
        }
    },[errors]);
    return {handleChange,values,handleSubmit,errors,error,isSubmitting,isSuccess,categories, isLoading,handleFileChange};
}
export default useCreateBlogPost;