import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { isEmail, isEmpty, isMobile } from '../../../utils/formValidator';
import { CircularProgress } from '@mui/material';
import { makeGetRequest, makePostRequest } from '../../../services/httpHelper';
import ErrorHandler from '../../../services/ErrorHandler';

const ResetPassword = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState(null);
  const [isSubmitting, setisSubmitting] = useState(false);
  const [resending, setResending] = useState(false);

  const [codeResend, setCodeResend] = useState(false);



  const navigate = useNavigate();

  const handleResetRequest = async () => {
    try {
        setError(null);
        if(isEmpty(email)){
            setError('Enter your email address');
            return;

        }
        if(!isEmail(email)){
            setError('Invalid email');
            return;
        }


        //show loading indicator
        setisSubmitting(true);
              // Send mobileOrEmail to backend for reset request processing
     
        const res = await makePostRequest('auth/forgot-password',{email:email});
        if (res && res.errorType) {
            setisSubmitting(false);
            const errorMessage = ErrorHandler(res)
            setError(errorMessage)

        } else {

            setisSubmitting(false);
            setError(null);
            setStep(2);


        }

   
    } catch (error) {
      // Handle error from reset request
      console.error('Error:', error);
    }
  };

  const handleVerificationCode = async () => {
    try {
        //validate input
        if(isEmpty(verificationCode)){
            setError('Enter Verification code');
            return;
        }
      // Send verificationCode to backend for verification processing
       //show loading indicator
       setisSubmitting(true);

 const res = await makePostRequest('auth/forgot-password-verify',{reset_password_code:verificationCode,email:email});
 if (res && res.errorType) {
     setisSubmitting(false);
     const errorMessage = ErrorHandler(res)
     setError(errorMessage)

 } else {

     setisSubmitting(false);
     setError(null);
     localStorage.setItem('mobileNumber',res.data)
     navigate('/set-new-password')

 }
   
   
    } catch (error) {
      // Handle error from verification code
      console.error('Error:', error);
    }
  };

  const handleResendVericationCode = async () => {
    try {
   
       setResending(true);

 const res = await makeGetRequest('auth/resend-forgot-password-code/'+email,{verificationCode:verificationCode});
 if (res && res.errorType) {
     setResending(false);
     const errorMessage = ErrorHandler(res)
     setError(errorMessage)

 } else {

     setResending(false);
     setError(null);
     setCodeResend(true)

 }
   
   
    } catch (error) {
      // Handle error from verification code
      console.error('Error:', error);
    }
  };


  

  return (
    <Container>
      {step === 1 ? (
        <Row className="justify-content-md-center">
          <Col md={6}>
            <h3 className='text text-center'>Reset Password</h3>
            {error !== null && <p className=' text text-danger'>{error}</p>}
            {codeResend === true && <p className=' text text-success'>Password reset code send to {email}</p>}
            <Form>
              <Form.Group>
                <Form.Control
                  type="email"
                  placeholder="Enter account email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Button variant="primary" onClick={handleResetRequest} disabled={email===''?true:false}>
                {isSubmitting ? <CircularProgress/>:'Request Reset'}
              </Button>
            </Form>
          </Col>
        </Row>
      ) : (
        <Row className="justify-content-md-center">
          <Col md={6}>
            <h3>Enter Verification Code</h3>
            {error !== null && <p className=' text text-danger'>{error}</p>}
            <Form>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Enter verification code"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                />
              </Form.Group>
              <Button variant="primary" onClick={handleVerificationCode} disabled={verificationCode===''?true:false}>
                
                {isSubmitting ? <CircularProgress/>:'Verify Code'}
              </Button>
              <br/>
              <br/>
              <p>Did not receive code? {resending ? <CircularProgress/>: <Link onClick={handleResendVericationCode}>Resend</Link>} </p>
            </Form>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ResetPassword;

/*const ResetPassword = () => {
    return (
        <div className="container">
            <div className=" ">
                <div className="card-body">
                    <h3 className="card-title text-center">Reset password</h3>

                    <div className="card-text">
                        <form>
                            <div className="form-group">
                                <label>Enter your email address and we will send you a link to reset your password.</label>
                                <input type="email" className="form-control form-control-sm" placeholder="Enter your email address" />
                            </div>

                            <button type="submit" className="btn">Get Reset Code</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ResetPassword;*/