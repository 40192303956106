import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import SubscriptionFeatureCard from '../../components/ui/SubscriptionFeatureCard';
import { Faqs } from '../../pages';
import {makeGetRequest,makePutRequest} from '../../services/httpHelper'
import ErrorHandler from '../../services/ErrorHandler'
import { toTitleCase } from '../../utils/myFunctions';
import { FaCheck, FaWindowClose } from 'react-icons/fa';


const addOns ={
  starter:[
    "free profesional website",
    "unlimited members",
    "unlimited users",         
    "weekly order of service",
    "announcements",
    "church leadership tracking and management",    
    "email notification",
    "in App notification",
    "church groups"
    
  


  ],
  standard:[
    "export to excel",
    "export to CSV",
    "download PDFs",
    "bank reconciliation",
    "print reports"

  ],
  premium:[
    "SMS notification",
    "import from excel",
    "payroll",
    "SMS notifications",
    "contribution statements",
    "projects and campaigns",
    "skill profile"


  ]
}



const Pricing = () => {
  const [email, setEmail] = useState('');
  const [plan, setPlan] = useState('free');
  const [plans,setPlans] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [serverError,setServerError] = useState(null);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePlanChange = (e) => {
    setPlan(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle subscription logic here, e.g., sending data to your server
    console.log(`Subscribed with email: ${email} and plan: ${plan}`);
  };

  const getPlans = async()=>{
    setIsLoading(true);
    const res = await makeGetRequest('subscription-plans');
    if (res && res.errorType) {
        setIsLoading(false);
        const errorMessage = ErrorHandler(res)
        setServerError(errorMessage)

    } else {
      const results = await res.data;
      
        setPlans(results)        
        setIsLoading(false);
        setServerError(null)
        

    }
   
}



  useEffect(()=>{
    getPlans();

  },[])

  return (
    <Container fluid className="subscription-page">
     
      <div className='row'>
        {
           plans.length !== 0 ?
          plans.map((plan)=>{
            return <div className='col-md-4' key={plan.id}>
            <SubscriptionFeatureCard data={{plan:plan,features:[]}} showExcludedFeatures={plan.plan.toLowerCase()==='basic'?false:true}/>
    
            </div>
          })
          :
          <p className='text text-info'>No Subcription plans found</p>
        }
        
      <div style={{marginTop:'15%'}}>
        <table className='table'>
          <thead>
            <tr style={{backgroundColor:'#008080', color:"#fff", fontSize:'24px', fontWeight:'bold' ,textAlign:"left",padding:'15px'}}> 
              <th></th>
              {
                
                plans && plans.length !==0 ?
                plans.map((plan)=>{
                  return <th key={plan.id}>{toTitleCase(plan.plan)}</th>
                })
                :
                <span>No Plans so far</span>
                
              }
            
            </tr>
            </thead>
            <tbody>
              {/*  Start*/}

            {
              addOns.starter.map((feature,index)=>{
                return<tr key={index}>
                  <td>{toTitleCase(feature)}
                  
                  </td>
                  
                
                  <td>
                  <FaCheck className='text text-success'/>
                  </td>
                  <td>
                  <FaCheck className='text text-success'/>
                  </td>
                  <td>
                  <FaCheck className='text text-success'/>
                  </td>
            
                 
                  
                

                </tr>

              })
            }

{
              addOns.standard.map((feature,index)=>{
                return<tr key={index}>
                  <td>{toTitleCase(feature)}
                  
                  </td>
                  
                
                  <td>
                  <FaWindowClose className='text text-danger'/>
                  </td>
                  <td>
                  <FaCheck className='text text-success'/>
                  </td>
                  <td>
                  <FaCheck className='text text-success'/>
                  </td>
            
                 
                  
                

                </tr>

              })
            }

{
              addOns.premium.map((feature,index)=>{
                return<tr key={index}>
                  <td>{toTitleCase(feature)}
                  
                  </td>
                  
                
                  <td>
                  <FaWindowClose className='text text-danger'/>
                  </td>
                  <td>
                  <FaWindowClose className='text text-danger'/>
                  </td>
                  <td>
                  <FaCheck className='text text-success'/>
                  </td>
            
                 
                  
                

                </tr>

              })
            }
        
          

             
            </tbody>
         
        </table>
      </div>
      
      </div>
      <br/>
      <Faqs/>
   
    </Container>
  );
};

export default Pricing;
