import {useState,useEffect} from 'react';
import {makePutRequest} from '../../services/httpHelper';


let initial_backend_errors =[];
const useEditFaq = (callback,validateCreateFaqForm,faq) =>{
    const [values, setValues] = useState({
        question:faq.question,
        answer:faq.answer,
        

    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [serverValidationErrors, setServerValidationErrors] = useState(initial_backend_errors);
    const [serverErrorMessage,setServerErrorMessage] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const handleChange = (e) =>{
        setValues({
            ...values,
           
            [e.target.name]:e.target.value
        })
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        setIsSubmitting(true)
        setErrors(validateCreateFaqForm(values));
        const data ={
            questionCategoryId:values.questionCategoryId,
            question:values.question,
            answer:values.answer

        }
        const res = await makePutRequest(`update-faq/${faq.id}`,data);
        if(res && res.errorType){
            setIsSubmitting(false);
          if(res.errorType === 'validation'){
            const validationErrors = res.validationErrors.errors;
            let backend_errors = [];  
            validationErrors.map((error,index)=>{
              backend_errors.push(error.msg)
              return initial_backend_errors.push(error.msg)           
    
            })
            const newServerErrors = serverValidationErrors.concat(backend_errors);
            setServerValidationErrors(newServerErrors);
  
          }
          else if(res.errorType === 'authentication'){
            setServerErrorMessage(res.errorMessage);
           
          }
         
       else if(res.errorType==='authorization'){
        setServerErrorMessage(res.errorMessage);        
          
        }
        else if(res.errorType === 'badrequest'){
          setServerErrorMessage(res.errorMessage);
        }else{
          setServerErrorMessage(res.errorMessage);
        }
          }else{     

          

            setIsSubmitting(false);
            setIsSuccess(true);
            setIsSubmitted(true);
          }
            
        
    }
    useEffect(()=>{
        if(Object.keys(errors).length===0 && isSubmitted){
           callback();
        }
    },[errors]);
    return {handleChange,values,handleSubmit,errors,serverErrorMessage,isSubmitting,isSuccess};
}
export default useEditFaq;