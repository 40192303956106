import React, { useState } from 'react';
import { Link, Navigate,useParams } from 'react-router-dom';
import {makePostRequest} from '../../services/httpHelper'
import ErrorHandler from '../../services/ErrorHandler'
import { CircularProgress } from '@mui/material';

const skill_categories =[
    'hard skills',
    'soft skills',
    'technical skills',
    'music skills',
    'management skills',
    'sales skills',
    'design skills',
    'accounting and bookkeeping skills',
    'nursing and health care skills',
    'teaching skills',
    'marketing skills',
    'software development skills',
    'business analytic skills'
]

const CreateMultipleSkills = ()=>{
   
    const [categories,setCategories] = useState(skill_categories)
   const [skills,setSkills] = useState()
   const [category,setCategory] = useState()
   const [isSubmitting,setIsSubmitting] = useState(false)
   const [serverError,setServerError] = useState(null)
   const [validationError,setValidationError] = useState(null)
   const [isSuccess,setIsSuccess] = useState(false)


   const handleChange =(e)=>{
    let name = e.target.name;
    switch(name){
        case 'skills':
            setSkills(e.target.value);
            break;
       
        case 'category':
            setCategory(e.target.value);
            break;
            default:
                alert('Invalid Input');
                break;
    }
   }



    const handleSubmit = async(e)=>{
        e.preventDefault();
        setServerError(null)
        setValidationError(null)
        
        if(skills === undefined){
            setValidationError('Type in skills separating each skill with a comma ')
            return;
        }
        if(category === undefined){
            setValidationError('Enter category ')
            return;
        }        

        const user = JSON.parse(localStorage.getItem('user'))

        const data ={
            skills:skills,
            category:category,
            user_id:user.id,           
           
        }
        setIsSubmitting(true)
        const post = await makePostRequest('add-multitple-skills',data)
        if (post && post.errorType) {
            setIsSubmitting(false);
            const errorMessage = ErrorHandler(post)
            setServerError(errorMessage)

        } else {
              
            setIsSubmitting(false);
            setIsSuccess(true);


        }
        

    }
    return (
        <div className="container">
             <div className="card">
        <form onSubmit={handleSubmit} className="form-group">
            <h3 className="text-center" style={{color:'white'}}>Create Multiple Skills</h3>
            <p className='helper'>Add skills separating each skill with a comma.</p>
            {isSubmitting && <p className='text text-success'>adding..</p>}
            {isSuccess && <p className='text text-success'>Skills successfully added</p>}
            {serverError !== null && !isSubmitting ? <p className='text text-danger'>{serverError}</p> : null}
         {validationError !== null && !isSubmitting ? <p className='text text-danger'>{validationError}</p> : null}
        
        <div className="row jumbotron">

        <div className="col-sm-6 form-group">
                <label htmlFor="category">Skill Category</label>
                <select className="form-control browser-default custom-select" name="category" value={category} onChange={handleChange}>
                <option value="">Select Category</option>
                {
                    categories.map((cat)=>{
                       return <option key={cat} value={cat}>{cat.toUpperCase()}</option>

                    })
                }
               
                

            </select>

            </div>
        
          
            <div className="col-sm-6 form-group">
                <label htmlFor="additional_notes">Skills</label>
                <textarea name="skills" className="form-control" placeholder="Enter skills separating each by a comma. e.g. skill,skill2,skill3.." onChange={handleChange} rows="5" cols="5">
              
                </textarea>
            </div>

                                       

            <div className="col-sm-12 form-group mb-0">
               <button className="btn btn-primary float-right">{isSubmitting ? <CircularProgress/> : "Add"}</button>
            </div>
            
        </div>
        </form>
        </div>
    </div>
    )

}
export default CreateMultipleSkills;