import React, {useState,useEffect} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import {BrowserRouter as Router, Routes, Route,useNavigate, useLocation} from 'react-router-dom';
import {NotFound,NoInternet,UnauthorizedAccess} from './http-response';
import axios from 'axios';
import ProtectedRoute from './utils/ProtectedRoute'
import {LoginForm,ResetPassword,SetNewPassword} from './features/auth/login'
import {SignupForm,VerifyEmail,VerifyMobileNumber} from './features/auth/signup'
import {AssignRole,CreateUserRoleForm,GetUserWithRoles,Roles} from './features/auth/roles'
import {
  Churches,
  AdminChurchProfile,

} from './features/church'

import {
  PrintableChartOfAccounts,
  CreateAccountType,
  AccountTypes,

  ImportableFunds,
  AddLibFund,
  AddSampleAccountGroups,
 Funds,
 SetOpeningBalances,
 SampleAccountGroups,
 SampleChartOfAccounts,
 ImportLibAccounts,
} from './features/finance'


import {Dashboard,AboutUs,ContactUs} from './pages'

import {CreateSkill,CreateMultipleSkills,Skills} from './features/skills'
import {SnackbarContainer} from './context/SnackbarContext'	
import SimpleSnackbar from './components/Snackbar';

import WatchVideo from './pages/WatchVideo'
import {CreateDonationPurpose, ManagePurposes} from './features/finance/purpose'
import {CreateTag, CreateTagGroup,ManageTagGroups,ManageTags} from './features/finance/tags'

import { AddChurchUserForm,  VerifyChurchUserMobileNumber } from './features/auth/users/church';
import AccountGroups from './features/finance/accounts/AccountGroups';

import { Inbox, SendEmail, SendSMS } from './features/messaging';
import SendNotification from './features/messaging/SendNotification';
import AdminNavbar from './components/ui/AdminNavbar';

import { CreateProduct, CreateProductFeature, CreateSubscriptionPlan, EditSubscriptionPlan, ManagePlanOptions, Pricing, ProductFeatures, Products, Subscribe, Subscription, SubscriptionPlan, SubscriptionPlans } from './features/subscription';
import { BlogCategories, CreateBlogPost, CreateBlogPostCategory, CreatePostComment, Post, Posts } from './features/blog';
import WebBuilder from './features/webbuilder/builder';
import CreateSampleChartOfAccounts from './features/finance/accounts/CreateChartOfAccounts';
import AddUserSuccess from './features/auth/users/church/AddUserSuccess';
import Users from './features/auth/users/Users';
import { CreateFaq,EditFaq,Faqs, ManageFaqs, ReorderFaqs } from './features/faqs';
import UserProfile from './features/auth/users/UserProfile';
import SampleAccountSubtypes from './features/finance/accounts/SampleAccountSubtypes';
import CreateSampleAccountSubtypeForm from './features/finance/accounts/CreateSampleAccountSubtypeForm';


const user = JSON.parse(localStorage.getItem('user'))
const roles = localStorage.getItem('roles')!== null && (localStorage.getItem('roles')).split(',')

function App() {

  const [showNavBar,setShowNavBar]=useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  const hideNavbarScreens = [
    '/login',
    '/reset-password'
  ]
  const pathName = location.pathname;
  const hideTopNavbar = hideNavbarScreens.includes(pathName)

  // This interceptor runs on every request
  axios.interceptors.request.use(
    (config) => {
      // Perform token expiry check here
      const token = sessionStorage.getItem('token');
      if (token) {
        // Check if the token is expired 
        const isTokenExpired = checkIfTokenIsExpired(token);

        if (isTokenExpired) {
          //get current url
          const currentUrl = location.pathname;
          //save current url in the local storage
         // localStorage.setItem('lastVisitedUrl',currentUrl);
          //logout user
          localStorage.removeItem('user');
        localStorage.removeItem('church');
        localStorage.removeItem('roles');
        localStorage.removeItem('users');
        sessionStorage.removeItem('token');
          // Redirect to login page if token is expired
         navigate('/login');
          return Promise.reject('Token is expired');
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Function to check token expiry 
  const checkIfTokenIsExpired = (token) => {
    
    const tokenData = token.split('.')[1]; // Assuming the token has 3 parts separated by '.'
     /*new Buffer.from(tokenData,"base64");*/
   const decodedToken =JSON.parse(atob(tokenData)); // Decode base64 data
    const currentTime = Math.floor(Date.now() / 1000);

    return decodedToken.exp < currentTime; // Comparing expiration time with current time
  };

  const checkToken =()=>{
    const userToken = sessionStorage.getItem('token');
    if(userToken){
      if(checkIfTokenIsExpired(userToken)){
        setShowNavBar(false)
      }
   setShowNavBar(true);
    }
  }

  useEffect(()=>{
    checkToken()
  },[])


  return(
    <NoInternet>

{!hideTopNavbar &&  showNavBar && <AdminNavbar/>}

        <>
        <Routes>

          {/*Auth */}
    <Route exact path="/signup" element={<SignupForm/>}/>
    <Route exact path="/login" element={<LoginForm/>}/>
    <Route exact path="/reset-password" element={<ResetPassword/>}/>
    <Route exact path="/set-new-password" element={<SetNewPassword/>}/>
    <Route exact path="/access-denied" element={<UnauthorizedAccess/>}/>
    <Route exact path="/watch-get-started-video" element={<WatchVideo/>}/>

    <Route exact path="/about-us" element={<AboutUs/>}/>
    <Route exact path="/contact-us" element={<ContactUs/>}/>
    <Route exact path="/faqs" element={<Faqs/>}/>
    <Route exact path="/manage-faqs" element={<ManageFaqs/>}/>
    <Route exact path="/re-order-faqs" element={<ReorderFaqs/>}/>
    <Route exact path="/create-faq" element={<CreateFaq/>}/>
    <Route exact path="/edit-faq" element={<EditFaq/>}/>


        <Route exact path="/" element={
          <ProtectedRoute
          isAllowed={!!user}
        redirectPath="/login"

        >
        <Dashboard/>
        </ProtectedRoute>
        }/>
       
       {/* Church */}
    

        <Route exact path="/admin-church-profile/:id" element={
          <ProtectedRoute
          redirectPath="/access-denied"
          isAllowed={!!user && roles.includes('admin')}
        >
        <AdminChurchProfile/>
        </ProtectedRoute>
        }/>

        <Route exact path="/subscribers" element={
          <ProtectedRoute
          redirectPath="/access-denied"
          isAllowed={!!user && roles.includes('admin')}
        >
        <Churches/>
        </ProtectedRoute>
        }/>


         {/* Users */}

    <Route exact path="/user-roles" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin')}
>
<Roles/>
</ProtectedRoute>
}/>
<Route exact path="/user-with-roles" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin')}
>
<GetUserWithRoles/>
</ProtectedRoute>
}/>
<Route exact path="/manage-church-user-roles/:userId" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin')}
>
<AssignRole/>
</ProtectedRoute>
}/>
<Route
          path="create-user-role"
          element={
            <ProtectedRoute
              redirectPath="/access-denied"
              isAllowed={!!user && roles.includes('superadmin')}
            >
              <CreateUserRoleForm />
            </ProtectedRoute>
          }
        />
<Route exact path="/users" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin','superadmin')}
>
<Users/>
</ProtectedRoute>
}/>

<Route exact path="/register-user" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('superadmin')}
>
<AddChurchUserForm/>
</ProtectedRoute>
}/>

<Route exact path="/add-user-success" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('superadmin','admin')}
>
<AddUserSuccess/>
</ProtectedRoute>
}/>

<Route exact path="/user-profile/:id" element={
  <ProtectedRoute
  redirectPath="/access-denied"
>
<UserProfile/>
</ProtectedRoute>
}/>

<Route exact path="/verify-church-user-mobile-number" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin','superadmin')}
>
<VerifyChurchUserMobileNumber/>
</ProtectedRoute>
}/>


{/* Member Skills - to be transfered to company admin dashboard */}
<Route exact path="/create-single-skill" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin','superadmin')}
>
<CreateSkill/>
</ProtectedRoute>
}/>

<Route exact path="/create-multiple-skills" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin','superadmin')}
>
<CreateMultipleSkills/>
</ProtectedRoute>
}/>

<Route exact path="/skills" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user}
>
<Skills/>
</ProtectedRoute>
}/>



{/* Accounts */}


<Route exact path="/set-account-opening-balance" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin','accountant','auditor','superadmin')}
>
<SetOpeningBalances/>
</ProtectedRoute>
}/>


<Route exact path="/account-types" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin')}
>
<AccountTypes/>
</ProtectedRoute>
}/>

<Route exact path="/account-sub-types" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin')}
>
<SampleAccountSubtypes/>
</ProtectedRoute>
}/>

<Route exact path="/create-account-sub-type" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin')}
>
<CreateSampleAccountSubtypeForm/>
</ProtectedRoute>
}/>

<Route exact path="/create-account" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin','accountant')}
>
<CreateSampleChartOfAccounts/>
</ProtectedRoute>
}/>
<Route exact path="/chart-of-accounts" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user}
>
<SampleChartOfAccounts/>
</ProtectedRoute>
}/>
<Route exact path="/import-library-chart-of-accounts" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin','accountant')}
>
<ImportLibAccounts/>
</ProtectedRoute>
}/>
<Route exact path="/create-chart-of-account" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin','accountant')}
>
<CreateSampleChartOfAccounts/>
</ProtectedRoute>
}/>
<Route exact path="/printable-chart-of-accounts" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user}
>
<PrintableChartOfAccounts/>
</ProtectedRoute>
}/>
<Route exact path="/create-account-type" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin')}
>
<CreateAccountType/>
</ProtectedRoute>
}/>




{/*Account Groups */}
<Route exact path="/create-account-group" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin')}
>
<AddSampleAccountGroups/>
</ProtectedRoute>
}/>


<Route exact path="/account-groups" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin','accountant')}
>
<SampleAccountGroups/>
</ProtectedRoute>
}/>



{/* Funds */}


<Route exact path="/add-fund" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin')}
>
<AddLibFund/>
</ProtectedRoute>
}/>

<Route exact path="/library-funds" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin','accountant')}
>
<Funds/>
</ProtectedRoute>
}/>
<Route exact path="/import-funds-from-lib" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin','accountant')}
>
<ImportableFunds/>
</ProtectedRoute>
}/>



{/** Donation Purposes */}
<Route exact path="/create-donation-purpose" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin','accountant')}
>
<CreateDonationPurpose/>
</ProtectedRoute>
}/>
<Route exact path="/manage-donation-purpose" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin','accountant')}
>
<ManagePurposes/>
</ProtectedRoute>
}/>

{/** Tags */}
<Route exact path="/create-tag" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin','accountant')}
>
<CreateTag/>
</ProtectedRoute>
}/>
<Route exact path="/create-tag-group" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin','accountant')}
>
<CreateTagGroup/>
</ProtectedRoute>
}/>
<Route exact path="/manage-tags" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin','accountant')}
>
<ManageTags/>
</ProtectedRoute>
}/>
<Route exact path="/manage-tag-groups" element={<ManageTagGroups/>}/>
<Route exact path="/manage-tag-groups" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin','accountant')}
>
<ManageTagGroups/>
</ProtectedRoute>
}/>



{/* Messaging */}

<Route exact path="/inbox" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user}
>
<Inbox/>
</ProtectedRoute>
}/>

<Route exact path="/send-sms" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin')}
>
<SendSMS/>
</ProtectedRoute>
}/>
<Route exact path="/send-email" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin')}
>
<SendEmail/>
</ProtectedRoute>
}/>
<Route exact path="/send-notification" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin')}
>
<SendNotification/>
</ProtectedRoute>
}/>


{/** Blog */}
<Route exact path="/blog" element={<Posts/>}/>
<Route exact path="/post/:id" element={<Post/>}/>
<Route exact path="/new-post" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin')}
>
<CreateBlogPost/>
</ProtectedRoute>
}/>
<Route exact path="/new-post-category" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin')}
>
<CreateBlogPostCategory/>
</ProtectedRoute>
}/>
<Route exact path="/new-post-comment" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user}
>
<CreatePostComment/>
</ProtectedRoute>
}/>
<Route exact path="/post-categories" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user}
>
<BlogCategories/>
</ProtectedRoute>
}/>
<Route exact path="/website-builder" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user}
>
<WebBuilder/>
</ProtectedRoute>
}/>

{/*Products*/}

<Route exact path="/products" element={<Products/>}/>
<Route exact path="/create-product" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin','superadmin')}
>
<CreateProduct/>
</ProtectedRoute>
}/>

{/** Subscription */}
<Route exact path="/subscription" element={<Subscription/>}/>
<Route exact path="/create-subscription-plan" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin')}
>
<CreateSubscriptionPlan/>
</ProtectedRoute>
}/>

<Route exact path="/add-product-feature" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin')}
>
<CreateProductFeature/>
</ProtectedRoute>
}/>
<Route exact path="/features" element={<ProductFeatures/>}/>
<Route exact path="/manage-plan-options/:id" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user}
>
<ManagePlanOptions/>
</ProtectedRoute>
}/>
<Route exact path="/subscribe/:id" element={<Subscribe/>}/>
<Route exact path="/plans" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user}
>
<SubscriptionPlans/>
</ProtectedRoute>
}/>
<Route exact path="/plan/:id" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user}
>
<SubscriptionPlan/>
</ProtectedRoute>
}/>
<Route exact path="/edit-plan/:id" element={
  <ProtectedRoute
  redirectPath="/access-denied"
  isAllowed={!!user && roles.includes('admin')}
>
<EditSubscriptionPlan/>
</ProtectedRoute>
}/>
<Route exact path="/pricing" element={<Pricing/>}/>

       
       
        </Routes>
    
    </>

    </NoInternet>
  
  )


}

export default App;