import React, { useState, useEffect,useRef } from 'react';
import { Link, Navigate,useNavigate } from 'react-router-dom';
import './signupForm.css';
import validateSignupForm from './validateSignupForm';
import useSignupForm from './useSignupForm';
import { ErrorState } from '../../../components/ui/UiState';
import LoadingIcon  from '../../../components/ui/LoadingIcon';
import VerifyMobileNumber from './VerifyMobileNumber'
import {makeGetRequest} from '../../../services/httpHelper'
import { CircularProgress } from '@mui/material';
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from '../../../config/env';


const grecaptchaObject = window.grecaptcha // You must provide access to the google grecaptcha object.

const SignupForm = () => {
  function submitForm() {
   setIsSuccess(true);
    alert(isSuccess)
  
  }
    const { handleChange, values, handleSubmit,  isSubmitting, isSubmitted, errors, serverErrorMessage, serverValidationErrors } = useSignupForm(submitForm, validateSignupForm);
    const [countries,setCountries] = useState(null)
    const [serverError,setServerErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

  const navigate = useNavigate();

  const[isLoginButtonDisabled,setIsLoginButtonDisabled]=useState(false)
	const recaptchaRef = useRef();


	const handleRecaptcha = (value)=>{
		console.log("Captcha value:", value);
		if(value){
			setIsLoginButtonDisabled(false)
		}

	}
  

    useEffect(() => {

      navigate('/login')
     
      async function getCountries() {
          setIsLoading(true)
          // You can await here
          const res = await makeGetRequest('auth/countries');
          if (ResizeObserver && ResizeObserver.errorType) {
              setIsLoading(false);
              const errorMessage = ErrorHandler(res)
              setServerErrorMessage(errorMessage)
  
          } else {
            
              setCountries(res.data)                  
              setIsLoading(false);
          }
  
  
         

      }
      
      getCountries(); 
    },[])

    if(isLoading){
      return <LoadingIcon/>
    }
    if(serverError !== null){
      return <ErrorState/>
    }
   
    return (
		<div className="container-fluid">
      {isSubmitted && navigate('/verify-mobile-number',{state:values.mobile_number})}
        
        <div className="row justify-content-center">
          {isSuccess}
  <div className="col-md-5">
   <div className="card">
     <h3 className="card-title text-center" style={{color:"white"}}>SignUp</h3>
      <div className="card-body py-md-4">
       <form _lpchecked="1" onSubmit={handleSubmit} className="form-group">
	   {isSubmitting && <CircularProgress color='inherit'/>}
      {serverErrorMessage  && <p className='text text-danger'>{serverErrorMessage}</p> }

      <div className="form-group">
             <input type="text" className="form-control" name="first_name" placeholder="First Name"  onChange={handleChange} />
			 {errors.first_name !== '' && <p className=" text text-danger">{errors.first_name}</p>}

                            </div>
                            <div className="form-group">
             <input type="text" className="form-control" name="last_name" placeholder="Last Name"  onChange={handleChange} />
			 {errors.last_name !== '' && <p className=" text text-danger">{errors.last_name}</p>}

                            </div>
							<div className="form-group">
                <label htmlFor="Country">Country</label>
                <select className="form-control custom-select browser-default" name="country" onChange={handleChange}>
                <option value="">Select Country</option>
                    {
                      countries !== null ?
                      countries.map((country)=>{
                        return <option value={country.country} key={country.id}>{country.country}</option>
                      })
                      :
                      <option value="">No Countries</option>
                      
                    }
                  
                </select>
				{errors.country && <p className=" text text-danger">{errors.country}</p>}
            </div>
          <div className="form-group">
             <input type="email" className="form-control" name="email" placeholder="Email"  onChange={handleChange} />
			 {errors.email !== '' && <p className=" text text-danger">{errors.email}</p>}

        </div>
        <div className="form-group">
             <input type="text" className="form-control" name="mobile_number" placeholder="Mobile Number"  onChange={handleChange} />
			 {errors.mobile_number !== '' && <p className=" text text-danger">{errors.mobile_number}</p>}

                            </div>
                            
                          
   <div className="form-group">
     <input type="password" className="form-control" name="password" placeholder="Password"  onChange={handleChange}/>
	 {errors.password && <p className=" text text-danger">{errors.password}</p>}

   </div>
   <div className="form-group">
      <input type="password" className="form-control" name="password_confirm" placeholder="confirm-password"  onChange={handleChange}/>
	  {errors.password_confirm && <p className=" text text-danger">{errors.password_confirm}</p>}

   </div>
   {
							
							<ReCAPTCHA
					ref={recaptchaRef }
					sitekey={RECAPTCHA_SITE_KEY}
					grecaptcha={grecaptchaObject}
					onChange={handleRecaptcha}
				/>
						
					}
   <div className="d-flex flex-row align-items-center justify-content-between">
      <Link to="/login">Login</Link>
            <button className="btn btn-primary">{isSubmitting ? <CircularProgress/> : "Register"}</button>
          </div>
       </form>
     </div>
  </div>
</div>
</div>

      
  
</div>

        
        
      
	    
      

    );
}




export default SignupForm;