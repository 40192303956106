
//Validate mobile phone number both in national and international formats
export  function isMobile(phone){  
   
    const regix = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    if(regix.test(phone)===false){
        return false;
    }
    else {
        return true;
    }



}
export  function isEmail(email){  
    
    const regix = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/
    if(!regix.test(email)){
        return false;
    }
    else {
        return true;
    }

}

export  function isEmpty(value){
    if(value === null || value ===''){
        return true;
    }else{
        return false;
    }
}

export  function minStingLen(str,len=6){
    if(!str.length.trim()<len){
        return `Value should be at least ${len} long`;
    }
}

export  function maxStingLen(str,len=6){
    if(!str.length.trim()>len){
        return `Value should exist  ${len} characters long`;
    }
    
}
export  function isNumber(value){
    if(isNaN(value)){
        return true;
    }else{
        return false;
    }
}

export  function isText(value){
    const reg = /^[A-Za-z]+$/;
    if(!reg.test(value)){
        return true;  
    }else{
        return false;
    }
    
}

export  function isImageFile(fileInput){
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    if(!allowedExtensions.exec(fileInput.value)) {
       
        fileInput.value = '';
        return false;
    }else{
        return true;
    }
    

}

export  function isAllowedFile(fileInput){
    const allowedExtensions = /(\.doc|\.docx|\.odt|\.pdf|\.tex|\.txt|\.rtf|\.wps|\.wks|\.wpd)$/i;
    if(!allowedExtensions.exec(fileInput.value)) {
       
        fileInput.value = '';
        return false;
    }else{
        return true;
    }
    

}

export  function isValidVerificationCode(code){
    if(!isNumber(code) || code.string().length !== 6){
        return false
    }else{
        return true;
    }
}

export function formatCurrency(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}