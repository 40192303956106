import {useState,useEffect} from 'react';
import {makePostRequest} from '../../../services/httpHelper'


let initial_backend_errors =[];
const useLoginForm = (callback,validateLoginForm) =>{
    const [values, setValues] = useState({
        user_name:'',
        password:'',
        

    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [serverValidationErrors, setServerValidationErrors] = useState(initial_backend_errors);
    const [serverErrorMessage,setServerErrorMessage] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false)
    const [user, setUser] = useState()


    const handleChange = (e) =>{
        setValues({
            ...values,
           
            [e.target.name]:e.target.value
        })
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        setServerErrorMessage(null)
        setIsSubmitting(true)
        setErrors(validateLoginForm(values));
        const data ={
            password:values.password,
            user_name:values.user_name,
        }
        const res = await makePostRequest('auth/admin-login',data);
        
        if(res && res.errorType){
            setIsSubmitting(false);
          if(res.errorType === 'validation'){
            const validationErrors = res.validationErrors.errors;
            let backend_errors = [];  
            validationErrors.map((error,index)=>{
              backend_errors.push(error.msg)
              initial_backend_errors.push(error.msg)           
    
            })
            const newServerErrors = serverValidationErrors.concat(backend_errors);
            setServerValidationErrors(newServerErrors);
  
          }else{
            setServerErrorMessage(res.errorMessage);

          }
         
          }else{
             
           
           localStorage.setItem('user',JSON.stringify(res.data));
           sessionStorage.setItem('token',res.data.token);
           setUser(res.data)
          let roles = ['admin'];
          res.data.roles.map((role)=>{
            roles.push(role.name)
          })
          localStorage.setItem('roles',roles)
          setIsSuccess(true)
          setIsSubmitted(true)         
          setIsSubmitting(false);
           
         
          }         
        
    }
    useEffect(()=>{
      if (Object.keys(errors).length===0 && isSubmitted) {
        callback(); 
    }
    
     


      
    },[errors, isSubmitted]);
    return {handleChange,values,handleSubmit,errors,serverErrorMessage,serverValidationErrors,isSubmitting,user,isSuccess, isSubmitted,user};
}
export default useLoginForm;