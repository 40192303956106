import React, { useState, useEffect } from 'react';
import { Link, useParams,  } from 'react-router-dom';
import { makeDeleteRequest, makeGetRequest, makePostRequest } from '../../services/httpHelper';
import ErrorHandler from '../../services/ErrorHandler';

const ManagePlanOptions = ()=>{

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState();
    const [isSuccess, setIsSuccess] = useState(false);
    const [features, setFeatures] = useState([]);
    const [currentFeatureIds, setCureentFeatureIds] = useState([]);

    const [allFeatures, setAllFeatures] = useState([]);    
    const [allFeatureIds, setAllFeatureIds] = useState([]);   
    const [plan, setPlan] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    const {id}=useParams();


    const getPlan = async()=>{
        setIsLoading(true);
        const res = await makeGetRequest('subscription-plan/'+id);
        if (res && res.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setError(errorMessage)
    
        } else {
            const result = await res.data;
            let features =[];
            let currentPlan = null;
            result.forEach((result)=>{
                features.push(result);
                result.features.map((p)=>{
                    if(currentPlan===null){
                        currentPlan = p;
                        setPlan(p)
                    }
                })
                
    
            })
            setFeatures(features)       
                    
            setIsLoading(false);
            setError(null)

                   
    
        }
       
    }

    const getFeatures = async()=>{
        try{
            setIsLoading(true)
            const res = await makeGetRequest('features')
            if(res && res.errorType){
                setIsLoading(false)
                const errorMessage = ErrorHandler(res)
                setError(errorMessage)
                
            }else{
                
                setAllFeatures(res.data);
                let planFeatures =[];
                let AvailableFeatures =[];
                features.map((feature)=>{
                    if(!planFeatures.includes(feature.id)){
                        planFeatures.push(feature.id)
                    }
                })
        
                allFeatures.map((feature)=>{
                    if(!AvailableFeatures.includes(feature.id)){
                        AvailableFeatures.push(feature.id)
                    }
                    setCureentFeatureIds(planFeatures);
                    setAllFeatureIds(AvailableFeatures)
                    setError(null)
                    setIsLoading(false);

                })
            }

        }catch(error){
            console.log(error)
            setIsLoading(false)
        }
       

    }

    const unAssignedFeature =(id)=>{
        if(!currentFeatureIds.includes(id)){
            return true
        }else{
            return false
        }
      

    }

    const addFeature =(feature)=>{
     
        const current_features = features;
        current_features.push(feature);
        setFeatures(current_features);
        const current_all_features = allFeatures

        const filteredAllFeatures = current_all_features.filter((f)=>f.id !== feature.id);
        setAllFeatures(filteredAllFeatures)


    }

    const removeFeature =(feature)=>{
        const current_features = features;
        const filtered = current_features.filter((f)=>f.id !== feature.id);
        setFeatures(filtered);
        const current_all_features = allFeatures;
        current_all_features.push(feature);

        setAllFeatureIds(current_all_features)


    }

    const addFeaturesubmit = async(feature,e) =>{
        e.preventDefault();
        setIsSubmitting(true)
        setIsSuccess(false)

        const user = JSON.parse(localStorage.getItem('user'))
        const data ={
            user_id:user.id,
            created_by:user.first_name+' '+user.last_name,
            plan_id:id,
            feature_id:feature.id,         



        }
        const res = await makePostRequest('add-feature-to-plan',data);
        if(res && res.errorType){
            setIsSubmitting(false);
          if(res.errorType === 'validation'){
            const validationErrors = res.validationErrors.errors;
            let backend_errors = [];  
            validationErrors.map((error,index)=>{
              backend_errors.push(error.msg)
              return initial_backend_errors.push(error.msg)           
    
            })
            const newServerErrors = error.concat(backend_errors);
            setError(newServerErrors);
  
          }
          else if(res.errorType === 'authentication'){
            setError(res.errorMessage);
           
          }
         
       else if(res.errorType==='authorization'){
        setError(res.errorMessage);        
          
        }
        else if(res.errorType === 'badrequest'){
          setError(res.errorMessage);
        }else{
          setError(res.errorMessage);
        }
          }else{     

          
            addFeature(feature)
            setIsSubmitting(false);
            setIsSuccess(true);
            setIsSubmitted(true);
          }
            
        
    }

    const removefeatureSubmit = async(feature,e) =>{
        e.preventDefault();
        setIsSubmitting(true)
        setIsSuccess(false)

    
        const res = await makeDeleteRequest(`remove-feature-from-plan/${feature.id}/${plan.id}`);
        if(res && res.errorType){
            setIsSubmitting(false);
          if(res.errorType === 'validation'){
            const validationErrors = res.validationErrors.errors;
            let backend_errors = [];  
            validationErrors.map((error,index)=>{
              backend_errors.push(error.msg)
              return initial_backend_errors.push(error.msg)           
    
            })
            const newServerErrors = error.concat(backend_errors);
            setError(newServerErrors);
  
          }
          else if(res.errorType === 'authentication'){
            setError(res.errorMessage);
           
          }
         
       else if(res.errorType==='authorization'){
        setError(res.errorMessage);        
          
        }
        else if(res.errorType === 'badrequest'){
          setError(res.errorMessage);
        }else{
          setError(res.errorMessage);
        }
          }else{     

          
            removeFeature(feature)
            setIsSubmitting(false);
            setIsSuccess(true);
            setIsSubmitted(true);
          }
            
        
    }

   
    useEffect(()=>{
        getPlan();
        getFeatures();
     
    },[]);

    return(
        <div className='container'>
             <h3>Edit {plan.plan} Plan Features</h3>
            {error !== null &&  <p className='text text-danger'>{error}</p>}
            {isSuccess && <p className="text text-success">Success</p>}
            <table className='table'>
                <thead>
                    <tr>
                        <th>Added Features</th>
                        <th>Available Features</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                        {
                            features.length !== 0 ?
                            features.map((feature,index)=>{
                                return <p key={feature.id}><span>{index+1}. </span> {feature.feature} <Link className='link-anchor text text-danger' onClick={(e)=>removefeatureSubmit(feature,e)}>Remove</Link></p>
                            })
                            :
                            <p className='text text-info'>No Feature added to this plan</p>
                        }
                        </td>

                        <td>
                        {
                            allFeatures.length !== 0 ?
                            allFeatures.map((feature,index)=>{
                                return <p key={feature.id}> {unAssignedFeature(feature.id)===true && index+1} {unAssignedFeature(feature.id)===true && feature.feature} {unAssignedFeature(feature.id)===true && <Link className='link-anchor' onClick={(e)=>addFeaturesubmit(feature,e)}>Add</Link>}</p>
                            })
                            :
                            <p className='text text-info'>No Features</p>
                        }
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ManagePlanOptions;