import React, { useState } from 'react';
import { Link, Navigate,useParams } from 'react-router-dom';
import {makePostRequest} from '../../services/httpHelper'
import ErrorHandler from '../../services/ErrorHandler'
import { CircularProgress } from '@mui/material';




const SendEmail = ()=>{
   
   const [subject,setSubject] = useState(null)
   const [targetAudience,setTargetAudience] = useState('all')
   const [message,setMessage] = useState(null)
   const [isSubmitting,setIsSubmitting] = useState(false)
   const [error,setError] = useState(null)
   const [isSuccess,setIsSuccess] = useState(false)



   const handleChange =(e)=>{
    let name = e.target.name;
    switch(name){
        case 'subject':
            setSubject(e.target.value);
            break;
       
        case 'message':
            setMessage(e.target.value);
            break;
            default:
                setTargetAudience(e.target.value)
                break;
    }
   }



    const handleSubmit = async(e)=>{
        e.preventDefault();
        setError(null)
        
       try{
        if(subject === null){
            setError('Enter Subject ')
            return;
        }
        if(message === null){
            setError('Enter message ')
            return;
        }        

        const user = JSON.parse(localStorage.getItem('user'));
        const church = JSON.parse(localStorage.getItem('church'))


        const data ={
            subject:subject,
            message:message,
            user_id:user.id,
            church_id:church.id,
            target_audience:targetAudience           
           
        }
        setIsSubmitting(true)
        const post = await makePostRequest('email-church-members',data)
        if (post && post.errorType) {
            setIsSubmitting(false);
            const errorMessage = ErrorHandler(post)
            setError(errorMessage)

        } else {
              
            setIsSubmitting(false);
            setIsSuccess(true);
            setSubject(null)
            setMessage(null)
            setTargetAudience('all')



        }
       }catch(error){
        setIsSubmitting(false);
        console.log(error)
       }

    }
    return (
        <div className="container">           
             <div>
        <form onSubmit={handleSubmit} className="form-group text-center" >
            <h3 className="text-center" >Send EMail</h3>
            {isSubmitting && <p className='text text-success'>Sending..</p>}
            {isSuccess && <p className='text text-success'>Email send</p>}
            {error !== null &&  <p className='text text-danger'>{error}</p> }
        
        <div style={{marginLeft:"20%"}}>
        <div className="row jumbotron">
        <div className="col-sm-8 form-group">
                <input type="text" className="form-control" name="subject" value={subject} placeholder="Subject" onChange={handleChange}/>

            </div>
            </div>

            <div className="row jumbotron text-center">

<div className="col-sm-8 form-group">

    <select name='target_audience' className="form-control"  onChange={handleChange}>
    <option value=""> Select target Receipients</option>
        <option value="all"> All Members</option>
        <option value="women"> Women Members</option>
        <option value="men"> Men Members</option>
        <option value="youths"> Youths</option>
        <option value="leaders"> Only Leaders</option>

    </select>


</div>
<div className="row jumbotron text-center">        
            <div className="col-sm-8 form-group">
                <textarea name="message" cols="60" rows="10" placeholder="Enter  message" value={message} onChange={handleChange}>

                </textarea>

                <button className="btn btn-primary float-right">{isSubmitting ? <CircularProgress/> : "Send"}</button> 

            </div>
            </div>


</div>


        </div>
      
            
          
           
        </form>
        </div>
    </div>
    )

}
export default SendEmail;