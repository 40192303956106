import React from 'react'
import './landingpage/landingPage.css'
import Navbar from '../components/ui/Navbar';
import Hero from './landingpage/Hero';
import SubscribeSection from './landingpage/SubscribeSection';
import CoreFeatures from './landingpage/CoreFeatures';
import Footer from '../components/Footer'
import Whycs from './landingpage/Whycs'
import OurServices from './landingpage/OurServices'
import Service from './landingpage/Service'
import StatsCounter from './landingpage/stats/StatsCounter'
//import Testimonials from './testimonials/Testimonials'
import ErrorBoundary from '../services/ErrorBoundary'




const Home =()=>{
    return(
        <div className="home-page-wrapper">
            <Navbar/>
            <Hero/>
            <SubscribeSection/>  
            <ErrorBoundary>
            <CoreFeatures/>  
             
            </ErrorBoundary>
                    
                     
            <Whycs/>
            <OurServices/>
            <Service/>
            <StatsCounter/>
            
            <Footer/>
           

        </div>
    )
}
export default Home;