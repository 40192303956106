import {useState,useEffect} from 'react';
import {makeGetRequest, makePutRequest} from '../../services/httpHelper';


const useEditSubscriptionPlan = (callback,validateCreateSubscriptionPlanForm,id) =>{
   
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState();
    const [isSuccess, setIsSuccess] = useState(false);
    const [features, setFeatures] = useState([]);
    const [plan, setPlan] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    const [values, setValues] = useState({
      plan: '',
      description:'',
      monthly_fee:'',
      annual_fee:'',
     
      

  });


    const handleChange = (e) =>{
      
        setValues({
            ...values,
           
            [e.target.name]:e.target.value
        })
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        setIsSubmitting(true)
        setIsSuccess(false)
        setErrors(validateCreateSubscriptionPlanForm(values));

        const user = JSON.parse(localStorage.getItem('user'))
        const data ={
            plan:values.plan,
            description:values.description,
            monthly_fee:values.monthly_fee,
            annual_fee:values.annual_fee,
            updated_by:user.first_name+' '+user.last_name,
            user_id:user.id

        }
        const res = await makePutRequest(`subscription-plan/${plan.id}`,data);
        if(res && res.errorType){
            setIsSubmitting(false);
          if(res.errorType === 'validation'){
            const validationErrors = res.validationErrors.errors;
            let backend_errors = [];  
            validationErrors.map((error,index)=>{
              backend_errors.push(error.msg)
              return initial_backend_errors.push(error.msg)           
    
            })
            const newServerErrors = error.concat(backend_errors);
            setError(newServerErrors);
  
          }
          else if(res.errorType === 'authentication'){
            setError(res.errorMessage);
           
          }
         
       else if(res.errorType==='authorization'){
        setError(res.errorMessage);        
          
        }
        else if(res.errorType === 'badrequest'){
          setError(res.errorMessage);
        }else{
          setError(res.errorMessage);
        }
          }else{     

          

            setIsSubmitting(false);
            setIsSuccess(true);
            setIsSubmitted(true);
          }
            
        
    }
    const getPlan = async()=>{
        setIsLoading(true);
        const res = await makeGetRequest('subscription-plan/'+id);
        if (res && res.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setError(errorMessage)
    
        } else {
            const result = await res.data;
            let features =[];
            let currentPlan = null;
            result.forEach((result)=>{
                features.push(result);
                result.features.map((p)=>{
                    if(currentPlan===null){
                        currentPlan = p;
                        setPlan(p)
                    }
                })
                
    
            })
            setFeatures(features)
            const oldPlan ={
              
                plan: plan.plan,
                description:plan.description,
                monthly_fee:plan.monthly_fee,
                annual_fee:plan.annual_fee,
               
                
          
            }
            setValues(oldPlan)
                    
            setIsLoading(false);
            setError(null)
        
    
            
    
        }
       
    }

   
    useEffect(()=>{
        getPlan();
        if(Object.keys(errors).length===0 && isSubmitted){
           callback();
        }
    },[errors]);
    return {handleChange,values,handleSubmit,errors,error,isSubmitting,isSuccess,features,isLoading,plan,};
}
export default useEditSubscriptionPlan;