import React, { useState, useEffect} from 'react';
import { Link, Navigate,useNavigate,useParams } from 'react-router-dom';
import { CardHeader, CircularProgress } from '@mui/material';
import { toTitleCase } from '../../utils/myFunctions';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CurrencyFormat from 'react-currency-format';
import {Card, CardBody, CardFooter } from '@chakra-ui/react';
import { FaHeart } from 'react-icons/fa';
import { makeGetRequest } from '../../services/httpHelper';
import ErrorHandler from '../../services/ErrorHandler';
import SanitizeHtmlComponent from './SanitizeHtmlComponent';
import { IMAGE_BASE_URL } from '../../constants/paths';
import Moment from 'react-moment';




const Posts =()=>{

    const[blogPosts, setBlogPosts] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);




    const navigate = useNavigate();

    const getPosts = async()=>{
        setIsLoading(true);
        const res = await makeGetRequest('blog-posts');
        if (res && res.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setError(errorMessage)

        } else {
            setBlogPosts(res.data)        
            setIsLoading(false);
            setError(null)
            console.log('Posts',res.data)

        }
       
    }


    const showPost=(post)=>{
        return navigate('/post',{state:post})

    }

    useEffect(()=>{
        getPosts()

    },[])

    const truncateContent = (content) => {
        const maxContentLength = 200; // You can adjust this value based on your preference

        if (content.length <= maxContentLength) {
          return content;
        }
        const truncatedContent = content.substr(0, maxContentLength);
        const lastSpaceIndex = truncatedContent.lastIndexOf(' ');
        return truncatedContent.substr(0, lastSpaceIndex) + '...';
      };
    
      const toggleExpand = () => {
        setIsExpanded(!isExpanded);
      };

    useEffect(()=>{

    },[])
    return(
        <div className='container text text-center'>
            <Link to='/new-post' className='btn' style={{width:'130px', marginLeft:'75%'}}>New Post</Link>
        <h2>Blog</h2>
        {error!==null && <p className='text text-danger'>{error}</p>}
        {
            blogPosts.length !==0?
            blogPosts.map((post)=>{
                return <div className='row' key={post.id} style={{marginBottom:"25px"}}>
                    <div className='col-md-5'>
                        <Link to={`/post/${post.id}`}>
                        <img src={IMAGE_BASE_URL+post.featuredImage} alt={IMAGE_BASE_URL+post.featuredImage} style={{ width: '100%', height: '400px' }}/>
                        </Link>
                        </div>
                    <div className='col-md-5' style={{ border:"1px solid grey",borderLeft:"none"}}>
                        <Card>
                           
                            <CardBody style={{margin:"30px"}}>
                                <div style={{fontSize:"12px", alignItems:"left", display:"flex", marginBottom:"25px"}}>
                                    <div style={{marginRight:"22px"}}>
                                        <img src={post.author.avatar} alt='avatar' width={50} height={50} className="rounded-circle"/>

                                    </div>
                                    <div>
                                    <p style={{marginBottom:"0px"}}>{post.author.first_name} {post.author.last_name}</p>
                                    <p><Moment fromNow>{post.createdAt}</Moment></p>

                                    </div>
                                  

                                </div>
                            <Link to={`/post/${post.id}`}>
                            <h4>{post.title}</h4>
                            </Link>
                                <Link to={`/post/${post.id}`}>
                                <div style={{fontFamily:"sans-serif times new roman", fontSize:"20px"}}>{<SanitizeHtmlComponent htmlContent={post.content} maxContentLength={230}/>}</div>
                                </Link>
                                
                                <hr/>
                            </CardBody>
                            <CardFooter>
                                <div style={{display:"flex"}}>
                                    
                                       <div style={{marginRight:"130px"}}> {post.comments.length} comments</div>
                                       <div style={{marginLeft:"130px"}}>{2} <FaHeart/></div>
                                        

                                    
                                   
                                </div>
                            </CardFooter>

                        </Card>
                        </div>

                        <div className='col-md-4'>
                        </div>
                </div>
            })
            :
            <p className='text text-info'>No Posts</p>
        }
    </div>
    )



}
export default Posts;