import React, { useState, useEffect} from 'react';
import { Link, Navigate,useParams } from 'react-router-dom';
import {makeDeleteRequest, makeGetRequest} from '../../../services/httpHelper'
import ErrorHandler from '../../../services/ErrorHandler';
import { CircularProgress } from '@mui/material';
import { toTitleCase } from '../../../utils/myFunctions';
import ToastAlert from '../../../components/ui/ToastAlert'
import { Badge } from 'react-bootstrap';
import DeleteForever from '@mui/icons-material/DeleteForever';


const SampleAccountGroups = ()=>{
    const [accountGroups, setAccountGrops] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState(null);
    
    const user = JSON.parse(localStorage.getItem('user'));

    async function getAccountGroups() {
        
        setIsLoading(true)
        // You can await here
        const res = await makeGetRequest('sample-account-groups');
        if (ResizeObserver && ResizeObserver.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setError(errorMessage)

        } else {
            setAccountGrops(res.data)                  
            setIsLoading(false);
            setIsSuccess(true);
        }       

    }

    
    useEffect(()=>{
        getAccountGroups();

    },[])

    const removeAccountGroup =async(e,id)=>{
        confirm(`Remove  permanently?`)
        e.preventDefault();
        setError(null)
       
        
        const post = await makeDeleteRequest(`/sample-account-group/${id}`)
        if (post && post.errorType) {
            setIsSubmitting(false);
            const errorMessage = ErrorHandler(post)
            setError(errorMessage)

        } else {
          
            getAccountGroups();
            setIsSuccess(true);

        }
    }

    

    return(
        <div className='container'>
            {error !== null &&  <ToastAlert type="error" message={error} />}
             {isLoading && <CircularProgress color="primary"/>}
               {
                            isSuccess ?
                                <div className="card">
                                    
                                            <div className='row'>
                                                <div className='col-sm-10'>
                                                <h3 className="text-center"> Sample Account Groups</h3>
                                                    </div>

                                                    <div className='col-sm-2'>
                                                        <Link to="/create-account-group">Add New</Link>
                                                    </div>
                                                </div>
                                            <div className="well" style={{display:"flex",flexDirection:"wrap",margin:"25px",fontStyle:"italic"}}>
                                               <div className='row' >
                                               {
                                                 accountGroups?.length >0 ?
                                                 accountGroups.map((group)=>{
                                                    return <div className='col-sm-2' key={group.id} style={{marginLeft:"10px", marginRight:"10px", marginBottom:"20px"}}>
                                                    <Badge>
                                                    {toTitleCase(group.account_group)}
                                                       <span style={{marginLeft:"20px", cursor:"pointer"}} onClick={(e)=>removeAccountGroup(e,group.id)}> <DeleteForever className='text text-warning'/></span>
                                                    </Badge>
                                                    </div>
                                                 })
                                                 :
                                                 <p className="text text-warning">No Sample Account Groups</p>
                                               }
                                               </div>
                                                </div>                            
                                            
                                           
                                            

                                      

                                </div>
                                :
                                <div>
                                    {error && <p className="text text-danger">{error}</p>}



                                </div>
                        }

        </div>
    )
}
export default SampleAccountGroups;