import React, { useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {makeGetRequest,makePutRequest} from '../../../services/httpHelper'
import ErrorHandler from '../../../services/ErrorHandler';
import Content from '../../../components/Content';
import { CircularProgress } from '@mui/material';
import {toTitleCase} from '../../../utils/myFunctions'



let account_groups =[];
let balances = [];
//let opening_balances =[];
const SetOpeningBalances = ()=>{     
    const [assetAccounts, setAssetAccounts] = useState(null);
    const [liabilityAccounts,setLiabilityAccounts] = useState(null);
    const [accounts, setAccounts] = useState(null);
    const[isSubmitting,setIsSubmitting] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState(null);  
    const [amount, setAmount] = useState(0);  
    const [isSaving, setIsSaving] = useState(false);  
    const [opening_balances, setOpBal] = useState();  
    
    
    const user = JSON.parse(localStorage.getItem('user')); //get user session data
    const church = JSON.parse(localStorage.getItem('church')); //get church session  
    

   const handleChange = async(e)=>{
    const data ={
        account_id:e.target.name,
        amount:e.target.value,
        church_id:church.id,
        user_id:user.id
    }
    setOpBal(data)
   
     
      
    

   }
   
 
  const handleSubmit = async()=>{
    setIsSaving(true)
         
    const save = await makePutRequest('opening-balances',opening_balances)
    if (save.errorType) {
        setIsLoading(false);
        const errorMessage = ErrorHandler(save)
        setError(errorMessage)

    } else {
        setIsSaving(false);       
        setIsSuccess(true);

    }

  }

     

     const getAccounts = async()=>{
        setError(null)
         const res = await makeGetRequest('accounts-with-opening-balances/'+church.id);
         if (res.errorType) {
             setIsLoading(false);
             const errorMessage = ErrorHandler(res)
             setError(errorMessage)
 
         } else {
             setIsLoading(false);
             setAccounts(res.data);   
             setAssetAccounts(res.data.assets)
             setLiabilityAccounts(res.data.liabilities)
             setError(null)
             

         }
     }
     const filterAssetAccounts = ()=>{
       const assets = accounts.filter(a =>a.type==='assets');
        setAssetAccounts(assets.accounts)
     }
     const filterLiabilityAccounts = ()=>{
        const assets = accounts.filter(a =>a.type==='liabilities');
         setAssetAccounts(assets.accounts)
      }

  
    
    useEffect(()=>{
        
        getAccounts()
            

       

    },[])







if(isLoading){
        return(
            <div className='container'>
            <CircularProgress/>
            </div>
        )
    }
//for autosaving currently not being used
    /*const callback = useCallback(() => {

        console.log('Lets autosave now!');
       
        console.log(data)
        handleSubmit()
        
      }, []);*/
    

    
    return(
        <div id="bill-and-invoices">
            <section id='page-header'>
                <h1>Set Account Balances</h1>
               <nav>                    
                    
                    <button type="button" className='cancel-btn'>Exit</button>
                    <button type="button" className='action-btn'onClick={handleSubmit}>Save</button>
                </nav>
            </section>
            <section id="page-body">
            <div className="container">
            {error !== null && <p className="text text-warning">Something went wrong: {error}</p>}
                    {error !== null && <Content type="error" message={"Failed"+ error}/>}
                    {isSuccess && <Content type="success" message="Changes saved"/>}
                    {isSubmitting  && <CircularProgress/>}
                    {
                       /* <AutoSave
                        isAutosaving={isSaving}
                        enableAutosaveLoop
                        onAutosaveTriggered={callback}
                        />*/
                    }
                                   

               <table className="table table-striped table-responsive">
                <thead>
                    <tr>
                    <th>Account</th>
                    <th>{isSaving && <h3>Saving..</h3>}</th>
                    <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan="3"><h4>Asset Accounts</h4></td>
                    </tr>
                    {
                        assetAccounts !== null ?
                        assetAccounts.map((account)=>{
                        return <tr key={account.id} style={{fontSize:"22", textAlign:"left"}}>
                        <td>{toTitleCase(account.account_name)}</td>
                        <td></td>
                        <td><span>{church.currency_symbol}</span><input type="number" className="form-control" name={account.id} placeholder={account.balance}  onChange={handleChange} onBlur={handleSubmit}/>{isSaving && <span className="text text-success">Saving..</span>}</td>
                    </tr>
                        })
                        :
                        null
                    }
                  
                   

                    <tr>
                        <td colSpan="3"><h4>Liability Accounts</h4></td>
                        
                    </tr>
                    {
                        liabilityAccounts !== null ?
                        liabilityAccounts.map((account)=>{
                        return <tr key={account.id} style={{fontSize:"22", textAlign:"left"}}>
                        <td>{toTitleCase(account.account_name)}</td>
                        <td>
                            

                        </td>
                        <td><span>{church.currency_symbol}</span><input type="number" className="form-control" name={account.id} placeholder={account.balance}   onChange={handleChange}/>{isSaving && <span className="text text-success">Saving..</span>}</td>
                    </tr>
                        })
                        :
                        null
                    }
               

                    
                </tbody>
               </table>
                </div>
              

            </section>
            

        </div>
    )
}
export default SetOpeningBalances;