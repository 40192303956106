import {isMobile,isEmpty,isEmail,isNumber} from '../../../utils/formValidator';

export default function validateSignupForm(values){
    let errors = {};
    if(isEmpty(values.email.trim())){
        errors.email = 'Enter Email';
    }
    if(isEmpty(values.first_name.trim())){
        errors.first_name = 'Enter First Name';
    }
    if(isEmpty(values.last_name.trim())){
        errors.last_name = 'Enter Last Name';
    }
    if(!isEmail(values.email.trim())){
        errors.email = 'Enter a valid email';
    }
    if(isEmpty(values.mobile_number.trim())){
        errors.mobile_number = 'Enter Mobile Number';
    }
    if(!isMobile(values.mobile_number.trim())){
        errors.mobile_number = 'Enter a valid mobile number';
    }
    if(isEmpty(values.password_confirm.trim())){
        errors.password_confirm = 'Confirm Password';
    }
    if(isEmpty(values.password.trim())){
        errors.password = 'Choose Password';
    }else if(values.password.trim() !== values.password_confirm.trim()){
        errors.msisdn = 'Your password do not match';
    }
    if(isEmpty(values.country.trim())){
        errors.country = 'Select Country';
    }

    if(isEmpty(values.role)){
        errors.role = 'Assign Role';
    }

   
    
   
   return errors;
   
}