import { isEmpty } from "../../../utils/formValidator";
export default function validateVerifyMobileNmber(values){
    let errors = {};
   

    if(isEmpty(values.name.trim())){
        errors.name = 'Enter Role Name';
    }
    if(isEmpty(values.description.trim())){
        errors.description = 'Enter description';
    }
        
   
   return errors;
}