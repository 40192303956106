import React, { useState } from 'react';
import { Link, Navigate,useParams } from 'react-router-dom';
import {makePostRequest} from '../../services/httpHelper'
import ErrorHandler from '../../services/ErrorHandler'
import { CircularProgress } from '@mui/material';




const CreateProduct = ({pageTitle=null})=>{
   
   const [name,setName] = useState(null);
   const [description,setDescription] = useState(null)
   const [category,setCategory] = useState(null)
   const [isSubmitting,setIsSubmitting] = useState(false)
   const [error,setError] = useState(null)
   const [validationError,setValidationError] = useState(null)
   const [isSuccess,setIsSuccess] = useState(false)

   const user = JSON.parse(localStorage.getItem('user'));


   const handleChange =(e)=>{
    let name = e.target.name;
    switch(name){
        case 'name':
            setName(e.target.value);
            break;
            case 'description':
                setDescription(e.target.value);
                break;
                case 'category':
                    setCategory(e.target.value);
                    break;
  
            default:
                alert('Invalid Input');
                break;
    }
   }



    const handleSubmit = async(e)=>{
        e.preventDefault();
        setError(null)
        setValidationError(null)
        
       try{
        if(name === null){
            setValidationError('Enter product name ')
            return;
        }
        if(description === null){
            setValidationError('Enter product description ')
            return;
        }
        if(category === undefined){
            setValidationError('Enter product category ')
            return;
        }
           


        const data ={
            name:name,
            description:description,
            category:category,
            type:'service',
            created_by:user.first_name+" "+user.last_name
                     
           
        }
        setIsSubmitting(true)
        const post = await makePostRequest('paypal-product',data)
        if (post && post.errorType) {
            setIsSubmitting(false);
            const errorMessage = ErrorHandler(post)
            setError(errorMessage)

        } else {
              
            setIsSubmitting(false);
            setIsSuccess(true);


        }
       }catch(error){
        setIsSubmitting(false);
        console.log(error)
       }

    }
    return (
        <div className="container">           
             <div className="">
        <form onSubmit={handleSubmit} className="form-group">
            {pageTitle===null && <h3 className="text-center" >Create Product</h3>}
            {isSubmitting && <p className='text text-success'>adding..</p>}
            {isSuccess && <p className='text text-success'>Success </p>}
            {error !== null && !isSubmitting ? <p className='text text-danger'>{error}</p> : null}
         {validationError !== null && !isSubmitting ? <p className='text text-danger'>{validationError}</p> : null}
        
        <div className="row jumbotron">

               
            <div className="col-sm-6 form-group">
                <span >Product Name</span>
                <input type="text" className="form-control" name="name" value={name} placeholder="Enter Product Name" onChange={handleChange}/>

            </div>

            <div className="col-sm-6 form-group">
                <span >Product Category</span>
                <input type="text" className="form-control" name="category" value={category} placeholder="Enter Product Category" onChange={handleChange}/>

            </div>

            <div className="col-sm-6 form-group">
                <span >Description</span>
                <input type="text" className="form-control" name="description" value={description} placeholder="Enter description" onChange={handleChange}/>

            </div>

                                       

            <div className="col-sm-12 form-group mb-0">
               <button className="btn btn-primary float-right">{isSubmitting ? <CircularProgress/> : "Add Product"}</button>
            </div>
            
        </div>
        </form>
        </div>
    </div>
    )

}
export default CreateProduct;