import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import LoadingIcon from '../../components/ui/LoadingIcon';
import { ErrorState } from '../../components/ui/UiState';
import {makeDeleteRequest, makeGetRequest} from '../../services/httpHelper'
import ErrorHandler from '../../services/ErrorHandler';
import { toTitleCase } from '../../utils/myFunctions';
import { Button } from 'react-bootstrap';
import {FaChurch, FaTrash, FaUserAlt } from 'react-icons/fa';
import Moment from 'react-moment';
import DataTable from 'react-data-table-component';




const Churches = ()=>{
    const [churches,setChurches] =useState([])
    const [isLoading,setIsLoading] = useState(true)
    const [serverError,setServerError] = useState(null)
    const[data,setData]=useState([])


    const getChurches = async()=>{
        setIsLoading(true)
        const res = await makeGetRequest('churches')
        if(res && res.errorType){
            setIsLoading(false)
            const errorMessage = ErrorHandler(res)
            setServerError(errorMessage)
            
        }else{
            setServerError(null)
            setChurches(res.data);
            const newData = prepareData(res.data);
            setData(newData)
            setIsLoading(false);
        }

    }

    const columns = [
        {
            name: 'Customer Name',
            selector: row => row.church_name,
            sortable: true,
        },
     
     
      
        {
            name: 'Number',
            selector: row => row.church_number,
            sortable: true,
          
        },
        {
            name: 'Date Joined',
            selector: row => row.date_joined,
        },

        {
            name: 'Number of Members',
            selector: row => row.members,
        },
        {
            name: 'Subscription Plan',
            selector: row => row.subscription_plan,
            sortable: true,
        },

        {
            name: 'Subscription Status',
            selector: row => row.subscription_status,
            sortable: true,
        },
        {
            name: 'Country',
            selector: row => row.country,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => (
              <div style={{display:"flex", justifyContent:"space-between"}}>
                  
               
              
                                     <FaTrash onClick={() => handleDeleteChurch(row.id)} className='text text-danger' style={{cursor:'pointer', marginLeft:"25px"}} />
              </div>
            ),
          },
    
       
       
     
    ];

    const prepareData =(customers)=>{
    
        const newData = customers.map((customer)=>({
            id:customer.id,
            church_name:customer.church_name,
            church_number:customer.church_number,
            date_joined:<Moment date={customer.createdAt} format="DD/MM/YYYY"/>,
            country:customer.country,
            subscription_plan:toTitleCase(customer.subscription_type),
            subscription_status:toTitleCase(customer.subscription_status),
            members:customer.churchMembers.length

          
          
        }));
    
        return newData;
    
    
    }

    
    const handleDeleteChurch =async(id)=>{

        const confirmed = window.confirm('Are sure you want to permanently remove this church?')
        if(confirmed){
            const res = await makeDeleteRequest('church/'+id);
            if (res && res.errorType) {
                setServerError(res.errorMessage)
    
            } else {
    
                const filterChurches = churches.filter((church)=>church.id !== id)
                setChurches(filterChurches)
                setServerError(null)
    
    
        }

        }
       
}
    useEffect(()=>{
        getChurches();
    },[])



    if(isLoading && serverError ===null){
        return <LoadingIcon/>
    }
    if(serverError){
        return <ErrorState/>
    }
    return(
        <div className="container">
            <div className="">
               
                {serverError !== null && alert(serverError)}

                <DataTable
                            title="Subscribers"
                               columns={columns}
                               data={data}
                               pagination
                               paginationPerPage={10} // Number of rows per page
                               paginationRowsPerPageOptions={[10, 20, 30,50,70,100]} // Options for rows per page dropdown
                               paginationComponentOptions={{ // Additional options for pagination component
                                 rowsPerPageText: 'Rows per page:',
                                 rangeSeparatorText: 'of',
                               }}
                               onRowClicked={(e)=>showProfile(e)}
                               className="hoverCursor" 
                             
                           />
           
            
            </div>
          

           

           
           

        </div>
    )
}

export default Churches;
