import React, { useState, useEffect} from 'react';
import { Link} from 'react-router-dom';
import {makePostRequest,makeGetRequest} from '../../../services/httpHelper'
import ErrorHandler from '../../../services/ErrorHandler';
import { CircularProgress } from '@mui/material';
import Content from '../../../components/Content'
import {SuccessAlert} from '../../../components/ui/UiState'
import HelpIcon from '@mui/icons-material/Help';
import ReactTooltip from 'react-tooltip';
import { toTitleCase } from '../../../utils/myFunctions';


const CreateDonationPurpose = ()=>{
   
   const [purpose,setPurpose] = useState()
   const [description,setDescription] = useState()
   const [account_id,setAccountId] = useState()
   const [fund_id,setFundId] = useState()
   const [tag_id,setTagId] = useState()
   const [isSubmitting,setIsSubmitting] = useState(false)
   const [error,setError] = useState(null)
   const [isSuccess,setIsSuccess] = useState(false)
   const [isLoading,setIsLoading] = useState(false)
   const [isLoaded,setIsLoaded] = useState(false)

   const [funds, setFunds] = useState(null)
   const [tags, setTags] = useState(null)
   const[incomeAccounts,setIncomeAccounts] = useState(null);
   const[showSelectFund,setShowSelectedFund] = useState(false)
   const[showSelectTag,setShowSelectedTag] = useState(false)
   const[showIncomeAccounts,setShowIncomeAccounts] = useState()

   
   const user = JSON.parse(localStorage.getItem('user'));
   const church = JSON.parse(localStorage.getItem('church'));


   const handleChange =(e)=>{
    let name = e.target.name;
    switch(name){
        case 'purpose':
            setPurpose(e.target.value);
            break;
        case 'account_id':
            setAccountId(e.target.value);
            break;
        case 'description':
            setDescription(e.target.value);
            break;
            case 'fund_id':
                setFundId(e.target.value);
            break;
            case 'tag_id':
                setTagId(e.target.value);
                break;
               
            default:
                console.log('Invalid Input');
                break;
    }
   }



    const handleSubmit = async(e)=>{
        e.preventDefault();
        setError(null)
        setError(null)
              
        if(purpose === undefined){
            setError('Enter  purpose')
            return;
        }
        if(description === undefined){
            setError('Enter Description')
            return;
        }
        const data ={
            purpose:purpose,
            account_id:account_id,
            fund_id:fund_id,
            description:description,
            tag_id:tag_id,
            church_id:church.id,
            user_id:user.id

        }
        setIsSubmitting(true)
        const post = await makePostRequest('donation-purpose',data)
        if (post && post.errorType) {
            setIsSubmitting(false);
            const errorMessage = ErrorHandler(post)
            setError(errorMessage)

        } else {
              
            
            setIsSubmitting(false);
            setIsSuccess(true);
            setPurpose(null)
            setDescription(null)
             }

    }
    const getFunds = async()=>{
        setError(null)
        setIsLoading(true)
        const res = await makeGetRequest('list-church-funds/'+church.id);
        if (res && res.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setError(errorMessage)

        } else {
              setFunds(res.data)
            setError(null);
            setIsLoading(false);
            console.log('funds',funds);
               
        }

    }
    const getIncomeAccounts = async()=>{
        setIsLoading(true)
       
        const res = await makeGetRequest('income-accounts/'+church.id);
        if (res.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setError(errorMessage)

        } else {
            
                    
            setIncomeAccounts(res.data)                                  
            setIsLoading(false);            
            console.log('income accounts',incomeAccounts)
        }
    }
    const getTags = async()=>{
        setIsLoading(true)
       
        const res = await makeGetRequest('tags/'+church.id);
        if (res.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setError(errorMessage)

        } else {
                       
           
            setTags(res.data)                                  
            setIsLoading(false);
            setIsLoaded(true);
            console.log('tags',tags)
        }
    }
    const linkToFund = ()=>{
        setFundId(null)
        if(showSelectFund){
            setShowSelectedFund(false)
        }else{
            setShowSelectedFund(true)
        }
    }
    const linkToTag = ()=>{
        setTagId(null)
        if(showSelectTag){
            setShowSelectedTag(false)
        }else{
            setShowSelectedTag(true)
        }
    }
    const showIncomeAccount = ()=>{
        setAccountId(null)
        if(showIncomeAccounts){
            setShowIncomeAccounts(false)
        }else{
            setShowIncomeAccounts(true)
        }
    }
   

    useEffect(()=>{
        getFunds();
        getIncomeAccounts();
        getTags();
       
    },[])
    
    return (
        <div className="container">
             <ReactTooltip type='info' multiline="true"/>
             <div className="card">
        <form onSubmit={handleSubmit} className="form-group">
            <h3 className="text-center" >Create Contribution Purpose</h3>
            {isSubmitting && <CircularProgress/>}
            {isSuccess && <SuccessAlert/>}
            {error && <Content type="error" message={error}/>}
            {error && <Content type="error" message={error}/>}
            {isSuccess && <Content type="success" message="Compaign Created!"/>}

         {error !== null && !isSubmitting ? <p className='text text-danger'>{error}</p> : null}
        
        <div className="row jumbotron">
       
           
            <div className="col-sm-6 form-group">
            <div className="form-group">
                    <span>Purpose</span>
                    <div className="col-sm-6 form-group">
               
                <input type="text" className="form-control" name="purpose"  placeholder="Enter Purpose" onChange={handleChange}/>

            </div>
                   
                    
                </div>

            </div>
            <div className="col-sm-6 form-group">
                <span>Description </span>
                <input type="text" className="form-control" name="description"  placeholder="Enter Account Description" onChange={handleChange}/>

            </div>

            <div className="row">
                <div className="col-md-4">
                <div className="form-group">
                <span><input type="checkbox" name="income-account" onChange={showIncomeAccount}/><span>Link to Income Account</span></span>              
            </div>
            {
                showIncomeAccounts &&
                <div>
                    {
                         incomeAccounts !== null ?
                     
                         <select id="income_account" name="account_id" className="form-control" onChange={handleChange}>
                         <option value="">Choose...</option>
                         {
                           incomeAccounts.map((account)=>{
                               return <option key={account.id} value={account.id}>{toTitleCase(account.account_name)}</option>
                           })
                         }
                       
                       </select>
                       :
                       <p className="text text-warning">No Income Accounts created <Link to="/create-account">Create Accocunt</Link></p>


                    }       

                     
                
                
               
                    </div>

               
           
            }

                </div>
                <div className="col-md-4">
                <div className="form-group">
                <span ><input type="checkbox" name="link_to_fund" value="1" onChange={linkToFund} />
                Link to a Fund
                <span className="text text-info" data-tip='Associate the account being created to a fund. <br/> For example building material expense account can be linked to building fund'><HelpIcon/></span>
                </span>
              {
              showSelectFund &&
              <div>
                 {
                funds !== null ?
                <select name="fund_id" className="form-control" id="fund_id" onChange={handleChange}>
                    <option value="">Select Fund</option>
                    {
                        funds.map((fund)=>{
                           return <option key={fund.id} value={fund.id}>{toTitleCase(fund.fund)}</option>
                        })
                    }

                </select>
                :
                <p className="text text-warning">No Funds, <Link to="/create-fund">Create Fund</Link></p>
               }

                </div>
              
              }
              
            </div>
                  
                  </div>

                  <div className="col-md-4">
                <div className="form-group">
                <span><input type="checkbox" name="tag_id" value="1" onChange={linkToTag} />
                Link Account to Tag
                <span className="text text-info" data-tip='Associate the account being created to a tag. <br/> A tag could be something that will group certain transactionc together <br/>. For example youth department tag can be used to link up a transaction to youth department'><HelpIcon/></span>
                </span>
              {
              showSelectTag &&
              <div>
                 {
                tags !== null ?
                <select name="tag_id" className="form-control" id="tag_id" onChange={handleChange}>
                    <option value="">Select Tag</option>
                    {
                        tags.map((tag)=>{
                           return <option key={tag.id} value={tag.id}>{toTitleCase(tag.tag)}</option>
                        })
                    }

                </select>
                :
                <p className="text text-warning">No Tags <Link to="/create-tag">Create Tag</Link></p>
               }

                </div>
              
              }
              
            </div>
                  
                  </div>
               </div>

                        

            <div className="row">
              
               <div className="col-sm-6 form-group ">
               <Link to="/compaigns">Close</Link>
            </div>
               <div className="col-sm-6 form-group">
               <button className="btn btn-primary float-right">Save</button>
            </div>
            

            </div>
                     

            
            
        </div>
        </form>
        </div>
    </div>
    )

}
export default CreateDonationPurpose;