import React, { useState, useEffect } from 'react';
import { Link, Navigate,useNavigate } from 'react-router-dom';
import '../../signup/signupForm.css';
import useAddChurchUserForm from './useAddChurchUserForm';
import { ErrorState } from '../../../../components/ui/UiState';
import LoadingIcon  from '../../../../components/ui/LoadingIcon';
import { CircularProgress } from '@mui/material';
import { makeGetRequest } from '../../../../services/httpHelper';
import ErrorHandler from '../../../../services/ErrorHandler';
import validateAddUserForm from '../validateAddUserForm';


const AddChurchUserForm = ({pageTitle=null}) => {
  function submitForm() {
   setIsSuccess(true);
    alert(isSuccess)
  
  }
    const { handleChange, values, handleSubmit,  isSubmitting, isSubmitted, errors, serverErrorMessage, serverValidationErrors } = useAddChurchUserForm(submitForm, validateAddUserForm);
    const [countries,setCountries] = useState(null)
    const [serverError,setServerErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [roles, setRoles] = useState();


  const navigate = useNavigate();
  

    useEffect(() => {
     
      async function getCountries() {
          setIsLoading(true)
          // You can await here
          const res = await makeGetRequest('auth/countries');
          if (ResizeObserver && ResizeObserver.errorType) {
              setIsLoading(false);
              const errorMessage = ErrorHandler(res)
              setServerErrorMessage(errorMessage)
  
          } else {
            
              setCountries(res.data)                  
              setIsLoading(false);
          }
  
  
         

      }

      async function getRoles() {
        setIsLoading(true)
        // You can await here
        const res = await makeGetRequest('roles');
        if (ResizeObserver && ResizeObserver.errorType) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res)
            setServerErrorMessage(errorMessage)

        } else {
          
            setRoles(res.data.roles)                  
            setIsLoading(false);


        }

       

    }
      
      getCountries(); 
      getRoles();
    },[])

    if(isLoading){
      return <LoadingIcon/>
    }
    if(serverError !== null){
      return <ErrorState/>
    }
   
    return (
		<div className="container-fluid">
      {isSubmitted && navigate('/add-user-success',{state:values.mobile_number})}
        
        <div className="row justify-content-center">
          {isSuccess}
  <div className="col-md-8">
   <div >
    {pageTitle===null &&  <h3 className="card-title text-center" >Add User</h3>}
      <div className="card-body py-md-4">
       <form _lpchecked="1" onSubmit={handleSubmit} className="form-group">
	   {isSubmitting && <CircularProgress color='inherit'/>}
      {serverErrorMessage  && <p className='text text-danger'>{serverErrorMessage}</p> }

      <div className="form-group">
             <input type="text" className="form-control" name="first_name" placeholder="First Name"  onChange={handleChange} />
			 {errors.first_name !== '' && <p className=" text text-danger">{errors.first_name}</p>}

                            </div>
                            <div className="form-group">
             <input type="text" className="form-control" name="last_name" placeholder="Last Name"  onChange={handleChange} />
			 {errors.last_name !== '' && <p className=" text text-danger">{errors.last_name}</p>}

                            </div>
							<div className="form-group">
                <span>Country</span><select className="form-control custom-select browser-default" name="country" onChange={handleChange}>
                <option value="">Select Country</option>
                    {
                      countries !== null ?
                      countries.map((country)=>{
                        return <option value={country.country} key={country.id}>{country.country}</option>
                      })
                      :
                      <option value="">No Countries</option>
                      
                    }
                  
                </select>
				{errors.country && <p className=" text text-danger">{errors.country}</p>}
            </div>
          <div className="form-group">
             <input type="email" className="form-control" name="email" placeholder="Email"  onChange={handleChange} />
			 {errors.email !== '' && <p className=" text text-danger">{errors.email}</p>}

        </div>
        <div className="form-group">
             <input type="text" className="form-control" name="mobile_number" placeholder="Mobile Number"  onChange={handleChange} />
			 {errors.mobile_number !== '' && <p className=" text text-danger">{errors.mobile_number}</p>}

                            </div>
  

   <div className="form-group">
                <span>Role</span><select className="form-control custom-select browser-default" name="role" onChange={handleChange}>
                <option value="">Assign Role</option>
                    {
                      roles !== undefined ?
                      roles.map((role)=>{
                        return <option value={role.name} key={role.id}>{role.name.toUpperCase()}</option>
                      })
                      :
                      <option value="">No Roles</option>
                      
                    }
                  
                </select>
				{errors.role && <p className=" text text-danger">{errors.role}</p>}
            </div>
    
                            
                          
  {/*} <div className="form-group">
     <input type="password" className="form-control" name="password" placeholder="Password"  onChange={handleChange}/>
	 {errors.password && <p className=" text text-danger">{errors.password}</p>}

   </div>
   <div className="form-group">
      <input type="password" className="form-control" name="password_confirm" placeholder="confirm-password"  onChange={handleChange}/>
	  {errors.password_confirm && <p className=" text text-danger">{errors.password_confirm}</p>}

   </div>*/}
   <div className="d-flex flex-row align-items-center justify-content-between">
   
            <button className="btn btn-primary">{isSubmitting ? <CircularProgress/> : "Register"}</button>
          </div>
       </form>
     </div>
  </div>
</div>
</div>

      
  
</div>

        
        
      
	    
      

    );
}




export default AddChurchUserForm;