import React, { useState, useEffect,useRef } from 'react';
import { Link, Navigate,useNavigate } from 'react-router-dom';
import './loginForm.css';
import validateLoginForm from './validateLoginForm';
import useLoginForm from './useLoginForm';
import { CircularProgress } from '@mui/material';
import Content from '../../../components/Content'
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from '../../../config/env';
import Logo from '../../../assets/logo.png'

const grecaptchaObject = window.grecaptcha // You must provide access to the google grecaptcha object.

const LoginForm = () => {

	function submitForm() {
		//return navigate('/')
		window.location.href ='/'
    }


    const { handleChange, values, handleSubmit,  isSubmitting, errors, serverErrorMessage, serverValidationErrors, isSuccess } = useLoginForm(submitForm, validateLoginForm);
	const navigate = useNavigate();
	const[isLoginButtonDisabled,setIsLoginButtonDisabled]=useState(false)
	const recaptchaRef = useRef();


	const handleRecaptcha = (value)=>{
		setIsLoginButtonDisabled(true)
	}
	

	const redirect = ()=>{
		return navigate('/')

	}
	
   
    return (
                
      
	<div className="container-fluid" style={{marginLeft:"100px",marginRight:"100px",marginTop:"100px",marginBottom:"100px"}}>
		{serverErrorMessage !==null && <Content btn_label="error" type="error" message={serverErrorMessage}/>}
		{/*isSuccess  && redirect()*/}


		

		
		<div className="row main-content bg-success text-center">
			<div className="col-md-4 text-center company__info">
				<span className="company__logo"><img src={Logo} alt="logo" width="70" height="60"/></span>
				<h4 className="company_title" style={{color:"#fffccc"}}>CS Church Management and Accounting System</h4>
			</div>
			<div className="col-md-8 col-xs-12 col-sm-12 login_form ">
				<div className="container-fluid">
					<div className="row">
						<h3 style={{color:'white'}}>Log In</h3>
					</div>
					<form onSubmit={handleSubmit} className="form-group">

					<div className="row">
						
							
                        {isSubmitting && <CircularProgress/>}
                            {serverErrorMessage !== null &&  <p className='text text-danger'>{serverErrorMessage}</p>}
							
							<div className="row">
								<input type="text" name="user_name"  className="form__input" placeholder="Username" onChange={handleChange}/>
                                {errors.user_name && <p className=" text text-danger">{errors.user_name}</p>}

							</div>
							<div className="row">
								<input type="password" name="password" id="password" className="form__input" placeholder="Password" onChange={handleChange}/>
                                {errors.password && <p className=" text text-danger">{errors.password}</p>}

							</div>

							{
							
							<ReCAPTCHA
					ref={recaptchaRef }
					sitekey={RECAPTCHA_SITE_KEY}
					grecaptcha={grecaptchaObject}
					onChange={handleRecaptcha}
				/>
						
					}
							<div className="row">
							<span>Remember Me!</span>
								<input type="checkbox" name="remember_me" id="remember_me" />
								
							</div>
							<div className="row">
								{
									!isLoginButtonDisabled ?

									<button type="submit" className="btn btn-primary" disabled>{isSubmitting===true ?<CircularProgress/> :"Login"}</button>
									:
									<button type="submit" className="btn btn-primary" >{isSubmitting===true ?<CircularProgress/> :"Login"}</button>
								}
							
							</div>
						
					</div>
					</form>
                    <div className="row">
                    <Link to="/reset-password" className='link-anchor'>Forgot your password</Link>
					</div>
					<div className="row">
						{/*<p>Don't have an account? <Link to="/signup" className='link-anchor'>Register Here</Link></p>*/}
					</div>
				</div>
			</div>
		</div>
	</div>       
      

    );
}




export default LoginForm;