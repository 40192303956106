import {isEmpty} from '../../utils/formValidator';

export default function validateCreateFaqForm(values){
    let errors = {};
    if(isEmpty(values.categoryId)){
        errors.categoryId = 'Select Quetion Category';
    }
  
    if(isEmpty(values.question)){
        errors.question = 'Enter Question';
    }
    if(isEmpty(values.answer)){
        errors.answer = 'Enter Answer';
    }
     

    
   
   return errors;
   
}