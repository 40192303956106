import {useState,useEffect} from 'react';
import {makePostRequest} from '../../../../services/httpHelper'

let initial_backend_errors =[];

let user = {};
const useAddChurchUserForm = (callback,validateSignupForm) =>{
    const [values, setValues] = useState({
        email:'',
        mobile_number:'',
        password:'',
        password_confirm:'',
        country:'',
        first_name:'',
        last_name:''
        

    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [serverValidationErrors, setServerValidationErrors] = useState(initial_backend_errors);
    const [serverErrorMessage,setServerErrorMessage] = useState(null);


    let serverError ='';
    const handleChange = (e) =>{
        setValues({
            ...values,
           
            [e.target.name]:e.target.value
        })
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        setServerErrorMessage(null)
        setIsSubmitting(true)
        setErrors(validateSignupForm(values));

        if(Object.keys(errors).length !== 0){
          setIsSubmitting(false);
          return;
        }
        const user = JSON.parse(localStorage.getItem('user'));
        const dataOb ={
            mobile_number:values.mobile_number,
            email:values.email,
            country:values.country,
            first_name:values.first_name,
            last_name:values.last_name,
            role:values.role,
            created_by:user.id
        }
        console.log(dataOb)
        const res = await makePostRequest('create-user-account',dataOb);
        if(res && res.errorType){
            setIsSubmitting(false);
          if(res.errorType === 'validation'){
            const validationErrors = res.validationErrors.errors;
            console.log(validationErrors)
            let backend_errors = [];  
            validationErrors.map((error,index)=>{
              backend_errors.push(error.msg)
              initial_backend_errors.push(error.msg)           
    
            })
            const newServerErrors = serverValidationErrors.concat(backend_errors);
            setServerValidationErrors(newServerErrors);
  
          }
          else if(res.errorType === 'authentication'){
            setServerErrorMessage(res.errorMessage);
           
          }
         
       else if(res.errorType==='authorization'){
        setServerErrorMessage(res.errorMessage);        
          
        }
        else if(res.errorType === 'badrequest'){
          setServerErrorMessage(res.errorMessage);
        }else{
          setServerErrorMessage(res.errorMessage);
        }
          }else{
             
          
            setIsSubmitted(true)
            setIsSubmitting(false);
            
          }

            
        
    }
    useEffect(()=>{
      console.log('is submitted',isSubmitted)
        if(Object.keys(errors).length===0 && isSubmitted){
           callback();
        }
    },[errors]);
    return {handleChange,values,handleSubmit,errors,serverError,isSubmitted, serverErrorMessage,serverValidationErrors,isSubmitting,user};
}
export default useAddChurchUserForm;