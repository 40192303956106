import React, { useState ,useLocation,useEffect} from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import SubscriptionFeatureCard from '../../components/ui/SubscriptionFeatureCard';
import { Faqs } from '../../pages';
import { useNavigate, useParams } from 'react-router-dom';
import {makeGetRequest,makePostRequest,makePutRequest} from '../../services/httpHelper'
import ErrorHandler from '../../services/ErrorHandler'

const SubscriptionPlan = () => {
    const [plan,setPlan]=useState(null);
    const [features,setFeatures]=useState(null);
    const [isLoading,setIsLoading] = useState(false);
    const [serverError,setServerError] = useState(null);
 
const {id} = useParams();
const navigate = useNavigate();



  const getPlan = async()=>{
    setIsLoading(true);
    const res = await makeGetRequest('subscription-plan/'+id);
    if (res && res.errorType) {
        setIsLoading(false);
        const errorMessage = ErrorHandler(res)
        setServerError(errorMessage)

    } else {
        const result = await res.data;
        let assignedFeatures =[];
        let currentPlan = null;
        result.forEach((result)=>{
          assignedFeatures.push(result);
            result.features.map((p)=>{
                if(currentPlan===null){
                    currentPlan = p;
                    setPlan(p)
                }
            })
            

        })
        setFeatures(assignedFeatures)        
        setIsLoading(false);
        setServerError(null)
    

        

    }
   
}


const showEditForm =()=>{
   navigate(`/edit-plan/${id}`)
}

const showManageOptions =()=>{
  navigate(`/manage-plan-options/${id}`)
}

const deletePlan = async(e) =>{
    e.preventDefault();
    setIsSubmitting(true)
    setIsSuccess(false)
    setErrors(validateCreateSubscriptionPlanForm(values));

    const user = JSON.parse(localStorage.getItem('user'))
    const data ={
        plan:values.plan,
        description:values.description,
        monthly_fee:values.monthly_fee,
        annual_fee:values.annual_fee,
        features:selectedFeatures,
        created_by:user.first_name+' '+user.last_name,
        user_id:user.id

    }
    const res = await makePostRequest('subscription-plan',data);
    if(res && res.errorType){
        setIsSubmitting(false);
      if(res.errorType === 'validation'){
        const validationErrors = res.validationErrors.errors;
        let backend_errors = [];  
        validationErrors.map((error,index)=>{
          backend_errors.push(error.msg)
          return initial_backend_errors.push(error.msg)           

        })
        const newServerErrors = error.concat(backend_errors);
        setError(newServerErrors);

      }
      else if(res.errorType === 'authentication'){
        setError(res.errorMessage);
       
      }
     
   else if(res.errorType==='authorization'){
    setError(res.errorMessage);        
      
    }
    else if(res.errorType === 'badrequest'){
      setError(res.errorMessage);
    }else{
      setError(res.errorMessage);
    }
      }else{     

      

        setIsSubmitting(false);
        setIsSuccess(true);
        setIsSubmitted(true);
      }
        
    
}

useEffect(()=>{
   
    getPlan();
    },[])
 





  return (
    <Container fluid className="subscription-page">
        {
            plan !==null ?
            <div className='row'>
            <div className='col-md-7'>
            <SubscriptionFeatureCard data={{plan:plan,features:features}} showButton={false}/>
    
            </div>

            <div className='col-md-3'>
                <br/><br/><br/>
                <h5>Manage this plan</h5>
                <Button variant="success" type="button" onClick={()=>showEditForm()}>
                  Edit Plan
                </Button>
                <Button variant="secondary" type="submit" onClick={()=>showManageOptions()}>
                  Add Or Remove Features
                </Button>

                <Button variant="primary" type="submit">
                  Remove Features
                </Button>
                <br/><br/><br/><br/>
                <Button variant="danger" type="submit">
                  Delete Plan
                </Button>
            </div>
            
          
          </div>
          :
          <p className='text text-info'>No Plan</p>
        }
     
     
      <br/>
      <Faqs/>
   
    </Container>
  );
};

export default SubscriptionPlan;
