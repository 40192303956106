import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { isEmpty } from '../../../utils/formValidator';
import { CircularProgress } from '@mui/material';
import ErrorHandler from '../../../services/ErrorHandler';
import { makePostRequest } from '../../../services/httpHelper';

const SetNewPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const[error, setError]= useState(null);
  const [isSubmitting, setisSubmitting] = useState(false);


  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
   
    setConfirmPassword(e.target.value);
    setPasswordMismatch(false);
  };

  const handlePasswordSubmit = async () => {
    setError(null)
    if(isEmpty(password)){
      setError('Enter Password');
      return;
    }

    if(isEmpty(confirmPassword)){
      setError('Confirm password');
      return;
    }
    if (password !== confirmPassword) {
      setPasswordMismatch(true);
      return;
    }
    // Password validation (at least 8 characters, 1 digit, 1 uppercase, 1 lowercase)
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!password.match(passwordRegex)) {
      setError('Your password must be at least 8 characters long, and should contain a digit, lowercase letter and uppercase letter');
      return;
    }
    try {
    
      const res = await makePostRequest('auth/reset-password',{password:password,email:localStorage.getItem('mobileNumber')});
      if (res && res.errorType) {
          setisSubmitting(false);
          const errorMessage = ErrorHandler(res)
          setError(errorMessage)

      } else {

          setisSubmitting(false);
          setError(null);
          localStorage.removeItem('mobileNumber')
          navigate('/login');


      }
     
    } catch (error) {
      // Handle error from sending new password
      console.error('Error:', error);
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={6}>
          <h3>Set New Password</h3>
          {error !== null && <p className=' text text-danger'>{error}</p>}

          <Form>
            <Form.Group>
              <Form.Control
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={handlePasswordChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="password"
                placeholder="Confirm password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
              {passwordMismatch && (
                <Alert variant="danger">Passwords do not match.</Alert>
              )}
            </Form.Group>
            <Button variant="primary" onClick={handlePasswordSubmit} disabled={password !== '' && confirmPassword !==''? false:true}>
            {isSubmitting ? <CircularProgress/>: 'Submit'}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default SetNewPassword;
