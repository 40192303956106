import React, { useState,useEffect,useRef } from 'react'
import { Tab, Tabs } from 'react-bootstrap'

import LoadingIcon from '../components/ui/LoadingIcon'
import { makeGetRequest } from '../services/httpHelper'
import {ModalRoot} from '../components/Modal'
import CurrencyFormat from 'react-currency-format';

import DashboardCard from '../components/ui/DashboardCard'
import GroupsIcon from '@mui/icons-material/Groups';

import People from '@mui/icons-material/People';

import { FaChurch, FaDonate, FaEvernote, FaMoneyBill, FaPallet, FaSkull, FaUser, FaUserCog } from 'react-icons/fa'
import DashboardBannerCard from '../components/ui/DashboardBannerCard'
import { Link, useNavigate } from 'react-router-dom'
import DashboardSmallCard from '../components/ui/DashboardSmallCard'
import DashboardBannerPlain from '../components/ui/DashboardBannerPlain'
import ErrorHandler from '../services/ErrorHandler'
import AuditLog from '../features/finance/statements/AuditLog'
import {PolicyPage} from '../features/policy'





 const Dashboard = () =>{
  const [tabKey, initTabKey] = useState('one')
  const[isLoading,setIsLoading] = useState(false);
  const [churches,setChurches] =useState([])
  const [error,setError] = useState(null)
  const[skills,setSkills]= useState([]);
  const [roles, setRoles] = useState(null);
  const [plans,setPlans] = useState([]);
  const[features,setFeatures]= useState([]);
  const[users,setUsers]= useState([]);
  const[allIsLoaded,setAllIsLoaded]=useState(false)





 const user = JSON.parse(localStorage.getItem('user'))


const getSkills = async()=>{
  try{
      setIsLoading(true)
      const res = await makeGetRequest('skills')
      if(res && res.errorType){
          setIsLoading(false)
          const errorMessage = ErrorHandler(res)
          setError(errorMessage)
          
      }else{
          setError(null)
          setSkills(res.data);
          setIsLoading(false);
      }

  }catch(error){
      console.log(error)
      setIsLoading(false)
  }
 

}
const getPlans = async()=>{
  setIsLoading(true);
  const res = await makeGetRequest('subscription-plans');
  if (res && res.errorType) {
      setIsLoading(false);
      const errorMessage = ErrorHandler(res)
      setError(errorMessage)

  } else {
      setPlans(res.data)        
      setIsLoading(false);
      setError(null)
      

  }
 
}

/* Fetch All Churches */
async function getChurches() {

  try{
      setIsLoading(true)
      // You can await here
      const res = await makeGetRequest('churches');
      if (ResizeObserver && ResizeObserver.errorType) {
          setIsLoading(false);
          const errorMessage = ErrorHandler(res)
          setError(errorMessage)

      } else {
          setChurches(res.data)                  
          setIsLoading(false);


      }
  } catch (error) {
      console.error('Error fetching churches:', error);
      // Handle errors
    }



 

}

const getFeatures = async()=>{
  try{
      setIsLoading(true)
      const res = await makeGetRequest('features')
      if(res && res.errorType){
          setIsLoading(false)
          const errorMessage = ErrorHandler(res)
          setError(errorMessage)
          
      }else{
          setError(null)
          setFeatures(res.data);
          setIsLoading(false);
      }

  }catch(error){
      console.log(error)
      setIsLoading(false)
  }
 

}

async function fetchRoles() {
  setIsLoading(true)
  // You can await here
  const res = await makeGetRequest('roles');
  if (res && res.errorType) {
      setIsLoading(false);

      if (res.errorType === 'authentication') {
          setError(res.errorMessage);

      }

      else if (res.errorType === 'authorization') {
          setError(res.errorMessage);

      }
      else if (res.errorType === 'badrequest') {
          setError(res.errorMessage);
      } else {
          setError(res.errorMessage);
      }
  } else {
      
      setRoles(res.data.roles)
      setIsLoading(false);

  }

}

async function fetchUsers() {
  setIsLoading(true)
  // You can await here
  const res = await makeGetRequest('users');
  if (res && res.errorType) {
      setIsLoading(false);               

       if (res.errorType === 'authorization') {
          setError(res.errorMessage);

      }
      else if (res.errorType === 'badrequest') {
          setError(res.errorMessage);
      } else {
          setError(res.errorMessage);
      }
  } else {
    const result = await res.data;
    
      setUsers(result);

setIsLoading(false);

  }

}
const navigate = useNavigate();

// Function to check token expiry 
const checkIfTokenIsExpired = (token) => {
    
  const tokenData = token.split('.')[1]; // Assuming the token has 3 parts separated by '.'
   /*new Buffer.from(tokenData,"base64");*/
  const decodedToken =JSON.parse(atob(tokenData)); // Decode base64 data
  const currentTime = Math.floor(Date.now() / 1000);

  return decodedToken.exp < currentTime; // Comparing expiration time with current time
};

const checkToken =()=>{
  const userToken = sessionStorage.getItem('token');
  if(!userToken){
    navigate('/login')

  }
  if(userToken){
    if(checkIfTokenIsExpired(userToken)){
      navigate('/login')
    }
  }
}

const reloadCount = Number(sessionStorage.getItem('reloadCount')) || 0;
const reloadPage =()=>{
  if(reloadCount < 2) {
    sessionStorage.setItem('reloadCount', String(reloadCount + 1));
    window.location.reload();
  } else {
    sessionStorage.removeItem('reloadCount');
  }
}
useEffect(()=>{
  checkToken();
  reloadPage();
  getChurches();
  getPlans();
  getSkills();
  getFeatures();
  fetchRoles();
  fetchUsers();
  setAllIsLoaded(true)
 

},[])

if(!allIsLoaded){
  return <LoadingIcon/>
}



const filterUsers =(type)=>{
  let results;
  if(type==='company'){
    results = users.filter((user)=>user.is_church_user===false);

  }else{
    results = users.filter((user)=>user.is_church_user===true);

  }
  return results;
}




if(isLoading){
  return <LoadingIcon/>
}

  return (
    <>
 
   <div className='row'>
 
    <div className="col-md-10 col-lg-12">
    <div className="container-fluid ">
    <Tabs activeKey={tabKey} onSelect={(e) => initTabKey(e)} style={{backgroundColor:"#eee",}}>
    <Tab eventKey="one" title="Overview">
    <ModalRoot />
    <div className='row'>
      <div className='col-md-6'>
      <DashboardBannerCard title="Admin Dashboard" subtitle="Manage Customers and Data" icon={FaChurch}/>

      </div>

      <div className='col-md-6'>
      <DashboardBannerPlain title="Outstanding Customer Payments" subtitle={<CurrencyFormat value={0} displayType={'text'} thousandSeparator={true} />} icon={FaMoneyBill}/>
        </div>

    </div>

    <div className="bill-stats">
         
         
      <div className='row'>
        <div className='col-md-3'>
        <DashboardCard title='Churches' subtitle='Total Churches' value={churches? churches.length:0} icon={FaChurch} />

        </div>

        <div className='col-md-3'>
        <DashboardCard title='Plans' subtitle='Number of Plans' value={plans !== null ? plans.length :0} icon={FaPallet} />
          
          </div>

          <div className='col-md-3'>
          <DashboardCard title='Features' subtitle='App Features' value={features !== null ? features?.length:0} icon={FaEvernote} />
          
          </div>
          <div className='col-md-3'>
          <DashboardCard title='Skills' subtitle='Supported Skills' value={skills !== null ? skills?.length:0} icon={FaSkull} />
          
          </div>
      </div>
      </div>

      <section>
              <h5 className='section-label'>Financials</h5>
              <div className="row">
                <div className="col-md-3">
                <DashboardSmallCard title='Income' subtitle='4%' value={0} footer="vs Previous 30 days" currency='Kshs.' />

                  

                </div>
                <div className="col-md-3">
                <DashboardSmallCard title='Expenses' subtitle='3.1%' value={0} footer="vs Previous 30 days" currency='Kshs.' />
                  
                </div>
                <div className="col-md-3">
                <DashboardSmallCard title='Net Income' subtitle='6%' value={0} footer="vs Previous 30 days" currency='Kshs.' />
                  
                </div>
                <div className="col-md-3">
                <DashboardSmallCard title='Others' subtitle='2.6%' value={0} footer="vs Previous 30 days" currency='Kshs.' droped={true} />
                  
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                {/*  <CashBalanceChart/>*/} 
                </div>
                <div className='col-md-6'>
                  {/* <IncomeByRevenueStream/>*/}
                </div>
              </div>

              

            </section>

  
    </Tab>

    <Tab eventKey="three" title="Users & Roles">
    <h5 className='section-label'>Stats</h5>

    <div className='row'>
      <div className='col-md-3'>
      <DashboardCard title='All Users' subtitle='Total Users' value={users.length !== 0 ? users.length :0} icon={GroupsIcon} />

      </div>
      <div className='col-md-3'>
      <DashboardCard title='Church Users' subtitle='Total Users' value={users !==null ? filterUsers('church').length :0} icon={FaUser} />
        
      </div>
      <div className='col-md-3'>
      <DashboardCard title='Company Users' subtitle='Total Users' value={users !==null ? filterUsers('company').length :0} icon={FaUserCog} />
        
      </div>
      <div className='col-md-3'>
      <DashboardCard title='All Roles' subtitle='Total Roles' value={roles !== null && roles.length} icon={People} />
        
      </div>
    </div>

  

          <section>
            <h5 className='section-label'>Monthly Income</h5> 
            <div className='row'>
              <div className='col-md-12'>
              {/*<MonthlyContributionsChart/>*/}

              </div>
              
            </div>
            
            </section>

    </Tab>
    <Tab eventKey="five" title="Messages & Notifications">
        <p>Messages & Notification </p>
        </Tab>

        <Tab eventKey="six" title="Customer Inquiries">
        <p>Customer Inquiries</p>
        </Tab>
      
        <Tab eventKey="seven" title="Legal">
          <PolicyPage/>
        
      
        </Tab>
        <Tab eventKey="eight" title="Audit Trail">
          <AuditLog/>
        
        </Tab>
        <Tab eventKey="nine" title="Error Log">
          <p>Error Log</p>
         
        </Tab>

    </Tabs>
    </div>
    </div>
   </div>
   

  
    
    
    </>
    
  )
}
export default Dashboard;