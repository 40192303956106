import {isEmpty} from '../../utils/formValidator';

export default function validateCreateBlogPostCategoryForm(values){
    let errors = {};
    if(isEmpty(values.category.trim())){
        errors.category = 'Select Quetion Category';
    }
    if(isEmpty(values.description.trim())){
        errors.description = 'Enter brief description for the category';
    }
  
    
     

    
   
   return errors;
   
}