import React, { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import validateCreateFaqForm from './validateCreateFaqForm';
import useEditFaq from './useEditFaq';
import { CircularProgress } from '@material-ui/core';


const EditFaq = ({faq}) => {
    const { handleChange, values, handleSubmit,  isSubmitting, errors, serverErrorMessage, isSuccess} = useEditFaq(submitForm, validateCreateFaqForm,faq);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const navigate = useNavigate();


    function submitForm() {
        navigate('/faqs')
    }

 
   
  
    return (
        <div className="container">
           
            <div className="container">
                <div className="form-box">
                  
                    <div className="body-form">
                        <h3 className='text text-center'>Edit FAQ</h3>
                      
                        <form onSubmit={handleSubmit}>
                            {isSubmitting && <p className='text text-warning'>Processing..</p>}
                            {serverErrorMessage !== null && !isSubmitting ? <p className='text text-danger'>{serverErrorMessage}</p> : null}
                            
                        
                            <div className="input-group mb-3">
                           
                           
                            <input type="text" className="form-control has-error"  placeholder="Enter Question" name="question" value={values.question} onChange={handleChange} /><br />
                            {errors.question && <p className=" text text-danger .error-message">{errors.question}</p>}
                        </div>
                            <div className="input-group mb-3">
                            
                                <textarea name="answer" cols="30" rows="4" placeholder="Enter Answer" value={values.answer} onChange={handleChange}>

                                </textarea>
                                {errors.answer && <p className=" text text-danger .error-message">{errors.answer}</p>}
                            </div>
                            
                            {
                                isSubmitting ?
                                <button type="submit" className="elevator-btn btn-block login-btn" disabled="disabled"><CircularProgress/></button>
                                :
                                <button type="submit" className="btn btn-success">Save</button>

                            }

                           
                        </form>

                    </div>
                </div>
            </div>
    


        </div>

    );
}




export default EditFaq;