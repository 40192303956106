import React, { useState, useEffect } from 'react';
import { Link,  } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import useCreateSubscriptionPlan from './useCreatesubscriptionPlan';
import validateCreateSubscriptionPlanForm from './validateCreateSubscriptionPlanForm';
import { toTitleCase } from '../../utils/myFunctions';


const CreateSubscriptionPlan = () => {
 
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [serverError,setServerError] = useState(null);
    const [addedFeatures,setAddedFeatures] = useState([]);
    const { handleChange, values, handleSubmit,  isSubmitting, errors, error, isSuccess, features,isLoading,products } = useCreateSubscriptionPlan(submitForm, validateCreateSubscriptionPlanForm,addedFeatures);



    function submitForm() {
        setIsSubmitted(true);
    }
    const addFeature =(feature)=>{
        let currentFeatures = addedFeatures;
        if(currentFeatures.includes(feature)){
            //remove feature
            currentFeatures = addedFeatures.filter((f)=>f !== feature)
            setAddedFeatures(currentFeatures)
        }else{
            //add feature
            currentFeatures.push(feature);
            setAddedFeatures(currentFeatures)

        }


    }

    const isAdded =(feature)=>{
        const currentFeatures = addedFeatures;
        if(currentFeatures.includes(feature)){
            return true;
        }else{
            return false;
        }

    }
   
  
    return (
        <div className="container">
            {
            !isLoading ?
            <div className="container">
                <div className="form-box">
                  
                    <div className="body-form">
                        <h3>Create Subscription Plan</h3>
                        {error !== null &&  <p className='text text-danger'>{error}</p>}
                        {isSuccess && <p className="text text-success">Success</p>}


                        {isSubmitted}
                        <form onSubmit={handleSubmit}>
                            {isSubmitting && <p className='text text-warning'>Processing..</p>}

                           
                            
                            <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Product</span>
                            </div>
                            {
                                products !== null ?
                                <select name="product_id" value={values.product_id}  onChange={handleChange}>
                                    {
                                        products.map((product)=>{
                                            return <option key={product.id} value={product.product_id}>{product.name}</option>
                                            

                                        })
                                    }

                                </select>
                                :
                                <p className='text text-info'>No Products</p>
                            }
                           
                           
                            {errors.product_id && <p className=" text text-danger .error-message">{errors.product_id}</p>}
                        </div>
                          

                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Plan</span>
                            </div>
                           
                            <input type="text" className="form-control has-error"  placeholder="subscription Plan" name="plan" value={values.plan} onChange={handleChange} /><br />
                            {errors.plan && <p className=" text text-danger .error-message">{errors.plan}</p>}
                        </div>

                       

                        
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">Description</span>
                                </div>
                                <textarea name="description" cols="30" rows="4" placeholder="Enter description" value={values.description} onChange={handleChange}>

                                </textarea>
                                {errors.description && <p className=" text text-danger .error-message">{errors.description}</p>}

                             
                            </div>
                            <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Monthly Price</span>
                            </div>
                           
                            <input type="text" className="form-control has-error"  placeholder="Monthly Price" name="monthly_fee" value={values.monthly_fee} onChange={handleChange} /><br />
                            {errors.monthly_fee && <p className=" text text-danger .error-message">{errors.monthly_fee}</p>}
                        </div>

                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Annual Price (Optional)</span>
                            </div>
                           
                            <input type="text" className="form-control has-error"  placeholder="Annual Price" name="annual_fee" value={values.annual_fee} onChange={handleChange} /><br />
                        
                        </div>
                        <div className="mb-3">
                            <p className='text text-info'>Select features to add to plan </p>
                            <br/>
                           {
                            features.length === 0 ?
                            <p className='text text-info'>No Product features added <Link to="/add-product-feature" className='link-anchor'>Add Features</Link></p>
                            :
                       
                                
                                    features.map((feature)=>{
                                      return <div key={feature.id} style={{display:"inline-block"}}>
                                        <button type="button" className={isAdded() ? "btn btn-sm btn-success" : "btn btn-sm btn-secondary" }  onClick={()=>addFeature(feature.id)}>{feature.feature} </button>
                                        </div>
                                        
                                    })
                                
                            
                           }
                        </div>


                        
                        <div>
                       

                        </div>

                         

                            
                            {
                                isSubmitting ?
                                <button type="submit" className="elevator-btn btn-block login-btn" disabled="disabled"><CircularProgress/></button>
                                :
                                <button type="submit" className="btn elevator-btn btn-block login-btn">Create</button>

                            }

                        </form>

                    </div>
                </div>
            </div>
            :
            <div>
                {isLoading && <p className='text text-success'><CircularProgress color='secondary'/></p>}
                {serverError !== null && !isLoading ? <p className='text text-danger'>{serverError}</p> : null}

            </div>
}


        </div>

    );
}




export default CreateSubscriptionPlan;