import {isEmail, isEmpty, isMobile} from '../../utils/formValidator';

export default function validateSubscriptionForm(values){
    let errors = {};
    if(isEmpty(values.country_code)){
        errors.country_code = 'Select Country';
    }

    if(isEmpty(values.first_name)){
        errors.first_name = 'Enter your first name';
    }

    if(isEmpty(values.last_name)){
        errors.last_name = 'Enter your last name';
    }
    if(isEmpty(values.email)){
        errors.email = 'Enter email address';
    }
    if(!isEmail(values.email)){
        errors.email = 'Invalid email address';
    }
    if(isEmpty(values.phone)){
        errors.phone = 'Enter your mobile number';
    }
    if(!isMobile(values.phone)){
        errors.phone = 'Invalid mobile number';
    }
  
    if(isEmpty(values.address_line_1)){
        errors.address_line_1 = 'Enter address 1';
    }
    if(isEmpty(values.address_line_2)){
        errors.address_line_2 = 'Enter address 2';
    }
    if(isEmpty(values.postal_code)){
        errors.postal_code = 'Postal code is required';
    }
    if(isEmpty(values.city)){
        errors.city = 'City is required';
    }

    if(isEmpty(values.church_name)){
        errors.church_name = 'Church name is required';
    }
    if(isEmpty(values.password)){
        errors.password = 'Enter Password';
    }
    if(isEmpty(values.password2)){
        errors.password2 = 'Confirm Password';
    }
    if(values.password !== values.password2){
        errors.password = 'The password does not match'; 
    }
     
     
     

    
   
   return errors;
   
}