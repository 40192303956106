import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import LoadingIcon from '../../../components/ui/LoadingIcon'
import { makeGetRequest,  } from '../../../services/httpHelper';
import { Link, } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomModal from '../../../components/ui/CustomModal';
import { Button } from 'react-bootstrap';
import GroupsIcon from '@mui/icons-material/Groups';
import {FaUserAlt } from 'react-icons/fa';
import { CreateUserRoleForm } from '.';


const Roles = () => {

    const [roles, setRoles] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false);
    const [serverErrorMessage, setServerErrorMessage] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
          
    
    useEffect(() => {
     
        async function fetchAllRoles() {
            setIsLoading(true)
            // You can await here
            const res = await makeGetRequest('roles');
            if (res && res.errorType) {
                setIsLoading(false);

                if (res.errorType === 'authentication') {
                    setServerErrorMessage(res.errorMessage);

                }

                else if (res.errorType === 'authorization') {
                    setServerErrorMessage(res.errorMessage);

                }
                else if (res.errorType === 'badrequest') {
                    setServerErrorMessage(res.errorMessage);
                } else {
                    setServerErrorMessage(res.errorMessage);
                }
            } else {
                
                setRoles(res.data.roles)
                setIsLoading(false);
                setIsSuccess(true)

            }

        }
        
         fetchAllRoles();      
       


    }, []);

    const pageTitle ='Create Role'
    const modalBody = <CreateUserRoleForm pageTitle={pageTitle}/>
    return (
        <>
           <CustomModal 
            show={showModal}
            showModal={showModal}
            handleClose={handleClose}
            header={pageTitle}
            body={modalBody}
            size="md" // You can change the size as needed
            footer=''
            />
                        
                    <div className="container">
                    <div className='row'>
                   
                    <div className='col-md-9'>
                  
                    
                    </div>
                    <div className='col-md-3'>
                    <Button variant="primary" onClick={handleShow}>
        <FaUserAlt/>  New Role
      </Button>

                    </div>
                </div>

                        {
                            isSuccess ?
                                <div>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <h4 className="text-center"> Roles</h4>
                                            <div>
                                                {
                                                    roles.length === undefined ?
                                                    <p className='text text-info'>No roles that have been added, contact customer care for assistance</p>
                                                    :
                                                    roles.map((role) => {
                                                        return (
                                                            <Accordion key={role.id}>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography>{role.name.toUpperCase()}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Typography>
                                                                        <div className='row'>
                                                                            <div className="col-md-8">
                                                                                {role.description}
                                                                            </div>
                                                                           

                                                                        </div>


                                                                    </Typography>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        )
                                                    })

                                                }
                                                   
                                                



                                            </div>

                                        </div>

                                        
                                    </div>

                                </div>
                                :
                                <div>
                                    {isLoading && serverErrorMessage === null && <LoadingIcon />}
                                    {serverErrorMessage && <p className="text text-danger">{serverErrorMessage}</p>}



                                </div>
                        }


                    </div>                
                   
            


        </>


    );
}
export default Roles;