import React,{useState,useEffect} from 'react';
import LoadingIcon from '../../components/ui/LoadingIcon';
import { ErrorState } from '../../components/ui/UiState';
import { makeDeleteRequest, makeGetRequest,makePostRequest } from '../../services/httpHelper';
import { toTitleCase,removeFromArray } from '../../utils/myFunctions';
import '../skills/assignSkills.css'

import { CircularProgress } from '@mui/material';
import ErrorHandler from '../../services/ErrorHandler';
import { Button } from 'react-bootstrap';

import CustomModal from '../../components/ui/CustomModal';
import CreateProductFeature from './CreateProductFeature';
import { FaTrash } from 'react-icons/fa';


const ProductFeatures =()=>{
    const[isLoading,setIsLoading]=useState(false);
    const[features,setFeatures]= useState([]);
    const[serverError,setServerError] =useState(null);
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
          

       const handleDelete =async(id)=>{

        const confirmed = window.confirm('Are sure you to remove the feater? click OK to confirm.')
        if(confirmed){
            const res = await makeDeleteRequest(`/feature/${id}`)
            if(res && res.errorType){
                setIsLoading(false)
                const errorMessage = ErrorHandler(res)
                setServerError(errorMessage)
                
            }else{
                const resetFeatures = features.filter((feature)=>feature.id !== id)
                setFeatures(resetFeatures);
            }
        }

       }
    

   
    

    const getFeatures = async()=>{
        try{
            setIsLoading(true)
            const res = await makeGetRequest('features')
            if(res && res.errorType){
                setIsLoading(false)
                const errorMessage = ErrorHandler(res)
                setServerError(errorMessage)
                
            }else{
                setServerError(null)
                setFeatures(res.data);
                setIsLoading(false);
            }

        }catch(error){
            console.log(error)
            setIsLoading(false)
        }
       

    }
    useEffect(()=>{
        getFeatures();
    },[])

    const pageTitle ='Add Feature'
    const modalBody = <CreateProductFeature pageTitle={pageTitle}/>

    if(isLoading && serverError ===null){
        return <LoadingIcon/>
    }
    if(serverError){
        return <ErrorState/>
    }
    

    return(
        <div className="container">
            <CustomModal 
            show={showModal}
            showModal={showModal}
            handleClose={handleClose}
            header={pageTitle}
            body={modalBody}
            size="lg" // You can change the size as needed
            footer=''
            />
               <div className='row'>
                   
                   <div className='col-md-9'>
                 
                   
                   </div>
                   <div className='col-md-3'>
                   <Button variant="primary" onClick={handleShow}>
        New Feature(s)
     </Button>

                   </div>
               </div>

            <div className="card">
                
            <h3>Features</h3>
           
          
   
  {serverError && <p className="text text-danger">Error: {serverError}</p>}
           {
            !isLoading ?
        
                 features.length !== 0?
                 <table>
                    <body>
                    
                {
                    features.map((feature)=>{
                        return <tr key={feature.id}>
                            <td>
                            {toTitleCase(feature.feature)} {feature.description !== null && <span> -{feature.description}</span>} 
                                </td>
                                <td>
                                <span style={{marginLeft:"30%", cursor:"pointer"}} className='text text-danger' onClick={()=>handleDelete(feature.id)}><FaTrash/></span>
                                    </td></tr>
                    })
                }

                    </body>
                 </table>

           

               
                :

             
                <div>
                    <p className='text text-info'>No Features added yet!</p>
               
                    </div>
             
            :
            <CircularProgress/>

           }


      
    
  </div>
           

           
           
        </div>
    )
}

export default ProductFeatures;